import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.scss';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from './store';
import { AuthProvider } from './contexts/AuthContext';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider theme={{ cssVar: true, hashed: false }}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ConfigProvider>
    </PersistGate>
    </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
