import React, { useEffect, useState } from 'react';
import InputBox from '../../components/InputBox';
import { Typography } from 'antd';
import freelancer from "../../assets/freelancer.svg";
import logo from "../../assets/logo.svg";
import './Login.scss'
import CustomButton from '../../components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import CONSTANTS from './constans';
import { loginUser } from '../../services/authService';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { logout, setCredentials } from '../../slice/authSlice';
import { useAuth } from '../../contexts/AuthContext';
import { initializeAgoraChat } from '../../utils/agoraChat';
import { clearAgoraCredentials, setAgoraCredentials } from '../../slice/agoraSlice';

const { Title } = Typography;

const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { saveToken,saveChatToken, saveLoggedInUser } = useAuth();

    const [userCredentials, setUserCredentials] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    const handleUserLogin = async () => {
        setLoading(() => true);
        const response = await loginUser(userCredentials);
        if(response?.data?.statusCode === 201) {
            const chatToken = response?.data?.data?.chatToken;
            const agoraUserName = response?.data?.data?.user?.agoraUserName;
            setLoading(() => false);
            saveToken(response?.data?.data?.accessToken);
            saveChatToken(response?.data?.data?.chatToken)
            dispatch(setCredentials({user: {...response?.data?.data?.user, ...response?.data?.data?.freelancer, ...response?.data?.data?.client}}));
            saveLoggedInUser({...response?.data?.data?.user, ...response?.data?.data?.freelancer, ...response?.data?.data?.client});
            dispatch(setAgoraCredentials({ chatToken, agoraUserName }));
            navigate('/dashboard', { replace: true});
            initializeAgoraChat(agoraUserName, chatToken);

        } else {
            setLoading(() => false);
            saveToken('');
            saveLoggedInUser({});;
            dispatch(logout());
            dispatch(clearAgoraCredentials());
        }
    }

    return (
        <div className='Login'>
            <div style={{ width: '1408px', height: '750px' }} className='Login__Card'>
                <div className='Login__Card__LeftSection'>
                    <div className='Login__Card__LeftSection__FirstRow'>
                        <img src={logo} alt='logo' />
                    </div>
                    <div className='Login__Card__LeftSection__SecondRow'>
                        <Title level={3}>{CONSTANTS.SIGN_IN}</Title>
                        <div className='Login__Card__LeftSection__SecondRow__InputFields'>
                            <InputBox className='Email-container' label={CONSTANTS.EMAIL_LABEL} variant='filled' value={userCredentials?.email} placeholder={CONSTANTS.EMAIL_PLACEHOLDER} onChange={(event) => setUserCredentials({...userCredentials, email: event?.target?.value})} />
                            <InputBox className='Password-Container' type='password' label={CONSTANTS.PASSWORD_LABEL} variant='filled' value={userCredentials?.password} placeholder={CONSTANTS.PASSWORD_PLACEHOLDER} onChange={(event) => setUserCredentials({...userCredentials, password: event?.target?.value})} />
                        </div>
                        <div className='Login__Card__LeftSection__SecondRow__Actions'>
                            <button onClick={()=>navigate('/forgot-password')} className='Login__Card__LeftSection__SecondRow__Actions__ForgotPwd'>{CONSTANTS.FORGOT_PASSWORD}</button>
                            <CustomButton className='Login__Card__LeftSection__SecondRow__Actions__SignIn' onClick={handleUserLogin} buttonText={CONSTANTS.SIGN_IN} loading={loading} />
                        </div>
                    </div>
                    <div className='Login__Card__LeftSection__ThirdRow'>
                        {CONSTANTS.NO_ACCOUNT} <Link to='/registration' className='Login__Card__LeftSection__ThirdRow__GetStarted'>{CONSTANTS.STARTED_WITH_MERCURY}</Link>
                    </div>
                </div>
                <div className='Login__Card__RightSection'>
                    <img className='Login__Card__RightSection__Image' src={freelancer} alt='freelancer' />
                </div>
            </div>
        </div>
    )
}

export default Login;