import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import "./contract.scss"
import { Button, Skeleton } from 'antd';
import { CircleDashed, Loader, Minus, Plus, SendHorizontal } from 'lucide-react';
import InputBox from '../../components/InputBox';
import { generateContract } from '../../services/openAi';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useAppSelector } from '../../hooks/reduxHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleMdeReact from 'react-simplemde-editor';
import "easymde/dist/easymde.min.css";
import { addUpdateContract, getContract, getJobApplication, signContract } from '../../services/jobService';
import { GetContractResponse, JobApplicationResponse } from '../../utils/types';
import { sendRoomMessage } from '../../utils/agoraChat';
import SendContractModal from './components/sendContractModal';

interface JobDetails {
    jobTitle: string;
    jobDescription: string;
    jobType: string;
    jobDuration: number | string;
    jobDurationType: string;
    minBudget: number | string;
    maxBudget: number | string;
    maxHours?: number | string | null; // Optional field
}

const simpleMdeConfig = {
    spellChecker: false,
    autofocus: true,
    forceSync: true,
    toolbar: [
        "bold",
        "italic",
        "heading",
        "|",
        "quote",
        "unordered-list",
        "ordered-list",
        "|",
        "preview",
        "undo"
    ] as const,
};
const SendContract = () => {
    const [inputValue, setInputValue] = useState<string>('')
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [contractContent, setContractContent] = useState<string>("");
    const [isEditing, setIsEditing] = useState(false);
    const [jobDetail, setJobDetail] = useState<JobApplicationResponse | null>(null)
    const [sendContractLoading, setSendContractLoading] = useState<boolean>(false)
    const [contractDetails, setContractDetails] = useState<GetContractResponse | null>(null)
    const [sendContactVerificationModal, setSendContractVerificationModal] = useState<boolean>(false)
    const pageRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<any>(null);
    const location = useLocation();
    const { state } = location;
    const user = useAppSelector((state: any) => state?.auth?.loggedInUser);
    const agora = useAppSelector((state: any) => state?.agora)
    const selectedChatRoom = state?.projectDetail?.chatRoomId || '';
    const contractId = state?.contractId || ''
    const userId = user?.userId;
    const jobId = state?.projectDetail?.jobId
    const navigate = useNavigate();
    useEffect(() => {
        if (pageRef.current) {
            pageRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [currentPage]);

    useEffect(() => {
        if (contractId) {
            setLoading(true)
            getContract(contractId)
                .then((res) => {
                    setContractDetails(res?.data.data)
                    const content = res?.data.data?.content || "";
                    const paginatedContent = paginateContent(content);
                    setPages(paginatedContent);
                    setLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false)
                })
        }
    }, [contractId])

    const paginateContent = (content: string) => {
        const lines = content.split("\n"); // Split content into lines
        let tempPage: string[] = [];
        let tempPages: string[][] = [];
    
        lines.forEach((line) => {
            if (tempPage.length >= 35) { // If page reaches 25 lines, push to pages
                tempPages.push([...tempPage]);
                tempPage = [];
            }
            tempPage.push(line);
        });
    
        if (tempPage.length) tempPages.push([...tempPage]); // Add last page if any remaining lines
    
        return tempPages.map(page => page.join("\n")); // Convert array of lines back to string
    };

    useLayoutEffect(() => {
        if (jobId && userId && !contractId) {
            setLoading(true)
            const requestParams = {
                jobId: jobId,
                userId: userId,
            };
            getJobApplication(requestParams)
                .then((res) => {
                    setLoading(false)
                    setJobDetail(res?.data.data)
                    const jobDetails = {
                        jobTitle: res?.data.data?.job?.jobTitle || '',
                        jobDescription: res?.data.data?.job?.jobDesc || '',
                        jobType: res?.data.data?.job?.jobType || '',
                        jobDuration: res?.data.data?.job?.jobDuration || '', 
                        jobDurationType: res?.data.data?.job?.jobDurationType || '', 
                        minBudget: res?.data.data?.job?.minBudget || '',
                        maxBudget: res?.data.data?.job?.maxBudget || '',
                        maxHours: res?.data.data?.job?.maxHours || null, 
                    };
                    generateInitialContract(jobDetails);
                })
                .catch((error) => {
                    setLoading(false)
                    console.error("Failed to fetch job application:", error);
                });
        }
    }, [jobId, userId]);

    const generateInitialContract = (jobDetails: JobDetails) => {
        setIsEditing(false)
        setPages([])
        const fullName = `${user.firstName} ${user.lastName}`;
        const location = user.location;
        const senderFullName = state?.userDetail ? `${state.userDetail.firstName} ${state.userDetail.lastName}` : "";
        let InitialContract = "**Service Agreement**\n\n";
        if (user.role === "freelancer") {
            InitialContract += `Generate a legally binding contract between the freelancer ${fullName} from ${location} and the client ${senderFullName}. 
The freelancer has submitted a proposal titled '{proposal_title}' for the job titled '${jobDetails.jobTitle}', described as '${jobDetails.jobDescription}'. 

**Job Details:**
- **Job Type:** ${jobDetails.jobType}
- **Job Duration:** ${jobDetails.jobDuration} ${jobDetails.jobDurationType}
- **Budget Range:** $${jobDetails.minBudget} - $${jobDetails.maxBudget}
${jobDetails.maxHours ? `- **Max Hours:** ${jobDetails.maxHours}` : ""}

The contract should outline:
- **Scope of Work:** Define the freelancer’s responsibilities.
- **Payment Terms:** Specify compensation, payment method, and schedule.
- **Deadlines & Deliverables:** Detail project milestones and final deadlines.
- **Intellectual Property Rights:** Clarify ownership of the completed work.
- **Confidentiality & Non-Disclosure:** Ensure private project details remain protected.
- **Termination Clause:** Outline the conditions for contract cancellation.
- **Dispute Resolution:** Provide a method for handling disagreements.

Ensure the contract is clear, legally structured, and protects both parties' interests.`;
        } else if (user.role === "client") {
            InitialContract += `Generate a legally binding contract between the client '${fullName}' and the freelancer ${senderFullName}. 
The contract is based on the freelancer’s proposal titled '${jobDetails.jobTitle}' for the job '${jobDetails.jobTitle}', described as '${jobDetails.jobDescription}'. 

**Job Details:**
- **Job Type:** ${jobDetails.jobType}
- **Job Duration:** ${jobDetails.jobDuration} ${jobDetails.jobDurationType}
- **Budget Range:** $${jobDetails.minBudget} - $${jobDetails.maxBudget}
${jobDetails.maxHours ? `- **Max Hours:** ${jobDetails.maxHours}` : ""}

The contract should specify:
- **Scope of Work:** Clearly define the freelancer’s tasks and expectations.
- **Payment Structure:** Detail payment amounts, milestones, and conditions.
- **Project Timeline:** Include start date, deadlines, and delivery schedule.
- **Confidentiality Clause:** Ensure sensitive information remains protected.
- **Intellectual Property Rights:** Define ownership of the completed work.
- **Dispute Resolution:** Specify how conflicts will be handled.
- **Termination Clause:** Define contract cancellation conditions.

Ensure the contract is well-structured, legally sound, and fair to both parties.`;
        }
        if (InitialContract) {
            handleGenerateContent(InitialContract);
        }
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    useEffect(() => {
        if (pages.length > 0) {
            setContractContent(pages[currentPage]);
        }
    }, [currentPage, pages]);

    const handleSaveEdit = () => {
        const updatedPages = [...pages];
        updatedPages[currentPage] = contractContent;
        setPages(updatedPages);
        setIsEditing(false);
    };

    const handleNext = () => {
        if (currentPage < pages.length - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleGenerateContent = async (inputValue: string) => {
        if (!inputValue.trim()) return;
        setLoading(true);
        const existingContent = pages.join(" ");
        const updatePrompt = `
            Here is an existing contract:
            ${existingContent}
    
            Modify the contract based on the following update:
            ${inputValue}
            
            Keep the original structure and content intact while making only the necessary updates.
        `;


        const newContent = await generateContract(updatePrompt);
        setLoading(false);
        const paginateContent = (content: string) => {
            const tempDiv = document.createElement("div");
            tempDiv.style.position = "absolute";
            tempDiv.style.visibility = "hidden";
            tempDiv.style.width = "794px";
            tempDiv.style.height = "1090px";
            tempDiv.style.overflow = "hidden";
            tempDiv.style.padding = "20px";
            tempDiv.style.lineHeight = "1.6";
            tempDiv.style.fontSize = "16px";
            tempDiv.style.whiteSpace = "pre-wrap";
            tempDiv.style.fontFamily = "Arial, sans-serif"; // Ensure same font
            document.body.appendChild(tempDiv);

            const words = content.split(" "); // Split at word level
            let tempContent = "";
            let tempPages: string[] = [];

            words.forEach((word) => {
                tempDiv.innerHTML = tempContent + " " + word;

                if (tempDiv.scrollHeight > tempDiv.clientHeight) {
                    tempPages.push(tempContent.trim()); // Save the previous page
                    tempContent = word; // Start new page with the overflowing word
                } else {
                    tempContent += " " + word;
                }
            });

            if (tempContent) tempPages.push(tempContent.trim()); // Last page
            document.body.removeChild(tempDiv);
            return tempPages;
        };

        const paginatedContent = paginateContent(newContent?.data.response);
        setPages(paginatedContent);
        setCurrentPage(0);
        setInputValue("");
    };

    const handleEditorChange = (value: string) => {
        setContractContent(value); // Update state without resetting cursor position
    };


    const handleSendContract = async () => {
        setSendContractLoading(true)
        await addUpdateContract({ jobId: jobId, content: pages.join("\n\n") }).then((res) => {
            setSendContractLoading(false)
            const contractId = res?.data.data.contractId;
            handleSendChatMessage(contractId)
        }).catch((err) => {
            console.error(err)
            setSendContractLoading(false)
        })
    };


    const handleSendChatMessage = async (contractId: string) => {
        if (!contractContent.trim()) {
            console.error("Contract content is empty!");
            return;
        }

        if (!selectedChatRoom) {
            console.error("No chat room selected!");
            return;
        }

        try {
            // Construct Custom Message for Contract
            const customMessage = {
                contractTitle: `Contract for ${jobDetail?.job?.jobTitle || "Job"}`,
                jobId: jobId,
                from: agora?.agoraUserName,
                senderId: userId,
                recipientId: state?.userDetail?.userId,
                contractId: contractId,
                senderName: `${user.firstName} ${user.lastName}`,
                isSigned: false,
            };

            // Send Custom Message via Agora Chat SDK
            const messageRes = await sendRoomMessage(
                selectedChatRoom,
                "",
                "custom",
                customMessage,
                "contract"
            );

            if (messageRes) {
                const newMessage = {
                    id: messageRes.message.time.toString(),
                    from: agora?.agoraUserName,
                    to: selectedChatRoom,
                    customExts: customMessage,
                    time: messageRes.message.time,
                    type: "custom",
                    customEvent: "contract",
                };
                navigate('/chat', { state: { recipientId: state?.userDetail?.userId, lastSelectedChatRoom: selectedChatRoom, agoraId: state?.userDetail?.agoraId } });
            }
        } catch (error) {
            console.error("Failed to send contract:", error);
        }
    }

    const handleSignContract = (contractId: string) => {
        signContract({ contractId: contractId }).then((res) => {
            const contractId = res?.data.data[0].contractId
            handleSendSignContract(contractId)
        }).catch((err) => {
            console.log(err, "err")
        })
    }

    const handleSendSignContract = async (contractId: string) => {
        if (!selectedChatRoom) {
            console.error("No chat room selected!");
            return;
        }

        try {
            const customMessage = {
                contractTitle: `Contract for ${jobDetail?.job?.jobTitle || "Job"}`,
                from: agora?.agoraUserName,
                senderName: agora?.agoraUserName || '',
                senderId: userId,
                recipientId: state?.userDetail?.userId,
                contractId: contractId,
            };

            const messageRes = await sendRoomMessage(
                selectedChatRoom,
                "",
                "custom",
                customMessage,
                "signContract"
            );

            if (messageRes) {
                const newMessage = {
                    id: messageRes.message.time.toString(),
                    from: agora?.agoraUserName,
                    senderName: agora?.agoraUserName || '',
                    to: selectedChatRoom,
                    customExts: customMessage,
                    time: messageRes.message.time,
                    type: "custom",
                    customEvent: "signContract",
                };
                navigate('/chat', { state: { recipientId: state?.userDetail?.userId, lastSelectedChatRoom: selectedChatRoom , agoraId: state?.userDetail?.agoraId } });
            }
        } catch (error) {
            console.error("Failed to send contract:", error);
        }
    }

    return (
        <div>
            <div className="p-10 h-dvh">
                <div className="flex justify-between items-center">
                    <div>
                        <p className="text-4xl font-bold">Contract</p>
                    </div>
                    {loading ? <div className='flex gap-2'>
                        <Skeleton.Button active className='w-28 h-8 flex justify-end' />
                        <Skeleton.Button active className='w-32 h-8 flex justify-end' />
                        <Skeleton.Button active className='w-36 h-8 flex justify-end' />
                    </div>
                        : (
                            contractDetails?.createdBy !== userId && contractId ? (
                                <div className="flex gap-2 items-center">
                                    <CustomButton buttonText={"Cancel"} onClick={() => navigate('/chat', { state: { lastSelectedChatRoom: selectedChatRoom, agoraId: state?.userDetail?.agoraId } })} className="font-sans" />
                                    <CustomButton buttonText={"Sign contract"} onClick={() => setSendContractVerificationModal(true)} className="font-sans" />
                                </div>
                            ) : (
                                contractContent && (
                                    <div className="flex gap-2 items-center">
                                        <CustomButton buttonText={"Cancel"} onClick={() => navigate('/chat', { state: { lastSelectedChatRoom: selectedChatRoom, agoraId: state?.userDetail?.agoraId } })} className="font-sans" />
                                        {isEditing ? (
                                            <CustomButton buttonText={"Save"} onClick={() => handleSaveEdit()} className="font-sans" />
                                        ) : (
                                            <CustomButton buttonText={"Edit Manually"} onClick={() => handleEditClick()} className="font-sans" />
                                        )}
                                        <CustomButton loading={sendContractLoading} buttonText="Send contract" onClick={() => handleSendContract()} className="font-sans text-white" />
                                    </div>
                                )
                            )
                        )}
                </div>
                <div className='h-[calc(100%-40px)] w-full flex flex-col justify-center'>
                    {isEditing ? (
                        <div className='h-[calc(100%-80px)] overflow-y-auto max-h-[1140px]'>
                            <SimpleMdeReact
                                className='max-h-[calc(100%-80px)]'
                                getMdeInstance={(instance) => (editorRef.current = instance)}
                                value={contractContent}
                                onChange={handleEditorChange}
                                options={simpleMdeConfig}
                            />
                        </div>
                    ) : (
                        <div ref={pageRef} className='mt-2 p-2 mx-auto h-[calc(100%-80px)] max-h-[1140px] overflow-auto'>

                            <div className="a4-page border">
                                <div className="a4-content prose lg:prose-lg">
                                    <div className="prose lg:prose-lg leading-relaxed space-y-3">
                                        {loading ? (
                                            <div className='rounded-full flex flex-col gap-3'>
                                                <Skeleton paragraph={{ rows: 10,  width: ["100%", "90%", "95%", "80%", "0%", "90%", "95%", "80%",] }} active className='w-full m-0' />
                                            </div>
                                        ) : (
                                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                                {contractContent}
                                            </ReactMarkdown>


                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {pages.length > 0 && (
                        <div className="flex justify-center gap-3 items-center mt-2">
                            <Button
                                className='bg-primary disabled:bg-gray'
                                type="primary"
                                size='small'
                                shape="circle"
                                icon={<Minus size={16} />}
                                onClick={handlePrevious}
                                disabled={currentPage === 0}
                            />

                            <p className='bg-primary/35 px-3 m-0 rounded-full'>Page {currentPage + 1} of {pages.length}</p>
                            <Button
                                className='bg-primary disabled:bg-gray'
                                type="primary"
                                shape="circle"
                                size='small'
                                icon={<Plus size={16} />}
                                onClick={handleNext}
                                disabled={currentPage === pages.length - 1}
                            />
                        </div>
                    )}

                    <div className="relative flex items-center py-2 justify-center h-14">
                        {loading ? <div className='w-1/2'>
                            <Skeleton.Button active className='w-full h-10' />
                        </div> : (
                            contractDetails?.createdBy !== userId && contractId ? (
                                <div className='w-1/2'>
                                    <CustomButton className='w-full h-10 text-base font-sans' onClick={() => setSendContractVerificationModal(true)} buttonText='Sign contract' />
                                </div>
                            ) : (
                                <InputBox
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder="Write a message to optimize"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" && !loading) {
                                            handleGenerateContent(inputValue);
                                        }
                                    }}
                                    suffix={loading ? <Loader className='animate-spin' /> : <SendHorizontal className="cursor-pointer text-black hover:text-primary" onClick={() => handleGenerateContent(inputValue)} />}
                                    className="w-1/2 p-2"
                                />

                            )
                        )}
                    </div>
                </div>
            </div>
            <SendContractModal isProposalModal={sendContactVerificationModal} setProposalModal={() => setSendContractVerificationModal(false)} onSendContract={() => handleSignContract(state?.contractId)} />
        </div>
    );
};

export default SendContract;
