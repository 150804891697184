import React, { useEffect, useRef, useState } from 'react';
import { Select, Typography, TimePicker } from 'antd';
import link from '../../../../assets/link.svg';
import './OfferedServices.scss'
import Question from '../../../../components/Question';
import InputBox from '../../../../components/InputBox';
import CONSTANTS from './constants';
import { getFreelancerServices } from '../../../../services/freelancerService';
import { searchByKey, tranformServicesData } from '../../../../utils/globalUtils';

type service = {
    label: string
    value: string
}

type subServiceCategoryProp = {
    label: string
    options: service[]
}

type freelancerServicesDetailsProps = {
    professionalTitle: string
    serviceId: string
    openingHrs: string
    closingHrs: string
    portfolioUrl1: string
    portfolioUrl2: string
}

type ValidationState = {
    professionalTitle: boolean,
    serviceId: boolean,
    openingHrs: boolean,
    closingHrs: boolean,
    portfolioUrl1: boolean,
    portfolioUrl2: boolean,
}

interface OfferedServicesProps {
    validationErrors: ValidationState
    freelancerServicesDetails: freelancerServicesDetailsProps
    setFreelancerServicesDetails: React.Dispatch<React.SetStateAction<{
        professionalTitle: string;
        serviceId: string;
        openingHrs: string;
        closingHrs: string;
        portfolioUrl1: string;
        portfolioUrl2: string;
    }>>
}

const { Title, Text } = Typography;

const OfferedServices = ({validationErrors, freelancerServicesDetails, setFreelancerServicesDetails }: OfferedServicesProps) => {
    const dataFetchedRef = useRef(false);
    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [services, setServices] = useState([]);

    const retrieveServices = async() => {
        const response = await getFreelancerServices();
        if(response?.data?.statusCode === 200) {
            const tranformedData = tranformServicesData(response?.data?.data);
            setIndustries(tranformedData);
        } else {
            setIndustries([]);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        retrieveServices();
    }, [])

    useEffect(() => {
        if(selectedIndustry) {
            const relevantCategories = searchByKey('serviceId', selectedIndustry, industries);
            const tranformedCategories = tranformServicesData(relevantCategories);
            setCategories(tranformedCategories);
            setSelectedCategory(() => '');
        }
    }, [selectedIndustry])

    useEffect(() => {
        if(selectedCategory) {
            const relevantServices = searchByKey('serviceId', selectedCategory, categories);
            const tranformedServices = tranformServicesData(relevantServices);
            setServices(tranformedServices);
            setFreelancerServicesDetails({...freelancerServicesDetails, serviceId: ''});
        }
    }, [selectedCategory])

    const onTimeChange = (key: string, timeString: string | string[]) => {
       setFreelancerServicesDetails({...freelancerServicesDetails, [key]: timeString || timeString[0]});
    };

    return (
        <div className='OfferedServices'>
            <div className='OfferedServices__FirstRow'>
                <InputBox label={CONSTANTS.PROFESSIONAL_TITLE} placeholder='Enter professional title' value={freelancerServicesDetails?.professionalTitle} status={validationErrors?.professionalTitle ? 'error' : ''} errorMessage={'Please enter professional title'} onChange={(event) => setFreelancerServicesDetails({...freelancerServicesDetails, professionalTitle: event?.target?.value})}/>
            </div>
            <div className='OfferedServices__SecondRow'>
                <div className='OfferedServices__SecondRow__Industry'>
                    <Title level={5}>{CONSTANTS.INDUSTRY}</Title>
                    <Select style={{width: '100%'}} value={selectedIndustry} options={industries} placeholder={CONSTANTS.INDUSTRY_PLACEHOLDER} status={validationErrors?.serviceId ? 'error' : ''} onChange={(value: string) => setSelectedIndustry(value)} />
                    {validationErrors?.serviceId && <Text type='danger'>{CONSTANTS?.ERROR_MESSAGES?.INDUSTRY}</Text>}
                </div>
                {selectedIndustry && (<div className='OfferedServices__SecondRow__Category'>
                    <Title level={5}>{CONSTANTS.CATEGORY}</Title>
                    <Select style={{width: '100%'}} value={selectedCategory} options={categories} placeholder={CONSTANTS.Category_PLACEHOLDER} status={validationErrors?.serviceId ? 'error' : ''} onChange={(value: string) => setSelectedCategory(value)} />
                    {validationErrors?.serviceId && <Text type='danger'>{CONSTANTS?.ERROR_MESSAGES?.CATEGORY}</Text>}
                </div>
                )}
                {selectedIndustry && selectedCategory && (
                    <>
                        <Question value={freelancerServicesDetails?.serviceId} label={CONSTANTS.SERVICE_QUESTION} options={services} onChange={(key: string, value: string | string[]) => setFreelancerServicesDetails({...freelancerServicesDetails, serviceId: Array.isArray(value) ? value[0] : value })} />
                        {validationErrors?.serviceId && <Text type='danger'>{CONSTANTS?.ERROR_MESSAGES?.SERVICE}</Text>}
                    </>
                )}
            </div>
            <div className='OfferedServices__ThirdRow'>
                <Title className='OfferedServices__ThirdRow__Label' level={5}>{CONSTANTS.WORKING_HOURS}</Title>
                <div className='OfferedServices__ThirdRow__Inputs'>
                    <TimePicker className='OfferedServices__ThirdRow__Inputs__OpeningHrs' use12Hours format="h:mm A" placeholder={CONSTANTS.OPENING_HOURS} status={validationErrors?.openingHrs ? 'error' : ''} onChange={(_, timeString) => onTimeChange('openingHrs', timeString)} />
                    <TimePicker className='OfferedServices__ThirdRow__Inputs__ClosingHrs' use12Hours format="h:mm A" placeholder={CONSTANTS.CLOSING_HOURS} status={validationErrors?.closingHrs ? 'error' : ''} onChange={(_, timeString) => onTimeChange('closingHrs', timeString)} />
                </div>
                {(validationErrors?.openingHrs || validationErrors?.closingHrs) && <Text type='danger'>{CONSTANTS?.ERROR_MESSAGES?.HOURS}</Text>}
            </div>
            <div className='OfferedServices__FourthRow'>
                <Title className='OfferedServices__FourthRow__Label' level={5}>{CONSTANTS.PORTFOLIO_LINKS}</Title>
                <div className='OfferedServices__FourthRow__Inputs'>
                    <InputBox value={freelancerServicesDetails?.portfolioUrl1} prefix={<img src={link} alt='link icon' />} status={validationErrors?.portfolioUrl1 ? 'error' : ''} onChange={(event) => setFreelancerServicesDetails({...freelancerServicesDetails, portfolioUrl1: event?.target?.value})} />
                    <InputBox value={freelancerServicesDetails?.portfolioUrl2} prefix={<img src={link} alt='link icon' />} status={validationErrors?.portfolioUrl2 ? 'error' : ''} onChange={(event) => setFreelancerServicesDetails({...freelancerServicesDetails, portfolioUrl2: event?.target?.value ?? ''})} />
                </div>
                {(validationErrors?.portfolioUrl1) && <Text type='danger'>{CONSTANTS?.ERROR_MESSAGES?.PORTFOLIO}</Text>}
            </div>
        </div>
    );
}

export default OfferedServices;