import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import Login from './modules/Login';
import Registration from './modules/Registration';
import Layout from './modules/Dashboard/Layout';
import Profile from './modules/Dashboard/Profile';
import RegistrationProtectedRoute from './modules/Registration/Layout';
import Discover from './modules/Dashboard/Discover';
import Onboarding from './modules/Onboarding';
import UploadProfilePicture from './modules/UploadProfilePicture';
import Payment from './modules/Payment';
import Questionnaire from './modules/Questionnaire';
import VerifyIdentity from './modules/VerifyIdentity';
import ClientServices from './modules/ClientServices';
import { useAppDispatch } from './hooks/reduxHooks';
import { setCredentials } from './slice/authSlice';
import FreelancerJobs from './modules/FreelancerJobs';
import ClientProjects from './modules/ClientProjects';
import FreelancerApplications from './modules/FreelancerApplications';
import LandingPage from './modules/LandingPage';
import { useAuth } from './contexts/AuthContext';
import Chat from './components/Chat';
import "./index.css";
import ForgotPassword from './modules/ForgotPassword';
import ResetPassword from './modules/ResetPassword';
import SendContract from './modules/contract';
import ViewContract from './modules/contract/components/viewContract';
function App() {
  const dispatch = useAppDispatch();
  const { token, user } = useAuth();

  useEffect(() => {
    if (typeof user === 'object' && user !== null) {
      dispatch(setCredentials({ user: { ...user } }));
    } else {
      dispatch(setCredentials({ user: user }));
    }
  }, []);

  return (
    <div className="App ">
      {/* <Login /> */}
      {/* <Registration /> */}
      <Router>
        <Routes>
          {!token && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path='/forgot-password' element={<ForgotPassword/>}/>
              <Route path='/reset-password' element={<ResetPassword/>}/>
              
              <Route path="/" element={<LandingPage />} />
              <Route element={<RegistrationProtectedRoute />}>
                <Route path='/registration' element={<Registration />} />
                <Route path='onboarding' element={<Onboarding />} />
                <Route path='upload-picture' element={<UploadProfilePicture />} />
                <Route path='payment-mode' element={<Payment />} />
                <Route path='questions' element={<Questionnaire />} />
                <Route path='verify-identity' element={<VerifyIdentity />} />
                <Route path='interested-services' element={<ClientServices />} />
              </Route>
            </>
          )}
          {token && (
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<div />} />
              <Route path="/registration" element={<Navigate to="/dashboard" />} />
              <Route path="/login" element={<Navigate to="/dashboard" />} />
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/discover" element={<Discover />} />
              <Route path="/jobs" element={<FreelancerJobs />} />
              <Route path="/client-projects" element={<ClientProjects />} />
              <Route path='/client-projects/:projectId' element={<FreelancerApplications />} />
              <Route path="/chat" element={<Chat />} />
              <Route path='/contract' element={<SendContract/>}/>
              <Route path='/view-contract' element={<ViewContract/>}/>

              {/* <Route path="/about" element={<About />} /> */}
              {/* <Route path="/contact" element={<Contact />} /> */}
            </Route>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
