

import AC from 'agora-chat';

export let conn: any = null
const APP_ID = "12dda5ac2299476cbe11dceb58df6c60";
const APP_KEY = "611294779#1491489";

export const initializeAgoraChat = async (userName: string,chatToken: string ) => {
    try {
        console.warn("CALLED initializeAgoraChat");

        if (!conn) {
            conn = new AC.connection({
                appKey: APP_KEY,
                //apiUrl: 'a61.chat.agora.io',
                //url: 'msync-api-61.chat.agora.io'
            });

            conn.listen({
                onConnected: () => console.log("Agora Chat Connected"),
                onDisconnected: () => console.log("Agora Chat Disconnected"),
                onError: (error: any) => console.error("Agora Chat Error:", error),
                onTextMessage: (message: any) => {
                    //console.log("HEHE1", message)
                },
            });
            if (!conn.isOpened()) {
                await conn.open({
                    user: userName,
                    accessToken: chatToken,
                });
            }
        }
    } catch (error) {
        console.error("Failed to initialize Agora Chat:", error)
        throw error
    }
}

export const joinChatRoom = async (chatRoomId: string) => {
    if (!conn) {
        console.error("Agora Chat not initialized. Call initializeAgoraChat first.");
        return;
    }

    try {
        await conn.joinChatRoom({ roomId: chatRoomId });
        console.log(`✅ Successfully joined chat room: ${chatRoomId}`);
    } catch (error) {
        console.error(`❌ Failed to join chat room: ${chatRoomId}`, error);
        throw error;
    }
};


//export const sendMessage = async (receiverId: string, text: string) => {
//    //await ensureConnection();
//    if (!conn) {
//        console.error("Agora Chat not initialized. Call initializeAgoraChat first.");
//        return;
//    }

//    if (!receiverId || !text) {
//        console.error("❌ Missing receiver or message text");
//        return;
//    }

//    const message = AC.message.create({
//        chatType: "singleChat",
//        type: "txt",
//        to: receiverId,
//        msg: text,
//    });

//    try {
//        //console.log("isOpened", conn.isOpened());
//        if (conn.isOpened()) {
//            return conn.send(message)
//                .then((res: any) => {
//                    //console.log("Message sent successfully:", res);
//                    return res;  // Ensure we return the response
//                })
//                .catch((e: any) => {
//                    console.error("Send private text error", e);
//                    throw e; // Re-throw error to be caught in handleSendMessage
//                });
//        }
//    } catch (error) {
//        console.error("❌ Message sending failed:", error);
//        throw error; // Ensure error propagates
//    }
//};


export const receiveMessages = async (appKey: string, agoraUserName: string, callback: (message: { sender: string; text: string }) => void) => {
    if (!conn) {
        console.error("Agora Chat not initialized. Call initializeAgoraChat first.")
        return () => { }
    }
    const eventHandler = {
        onTextMessage: (message: any) => {
            return message
            //console.log("📩 Received Message:", message)
            if (message?.from && message?.msg) {
                callback({ sender: message.from, text: message.msg })
            }
        },
    }

    conn.addEventHandler("chatListener", eventHandler)

    return () => {
        conn.removeEventHandler("chatListener")
        //console.log("🔄 Chat listener removed")
    }
}

//export const getHistoryMessages = async (agoraUserName: string) => {
//    const messages = await conn.getHistoryMessages({
//        targetId: agoraUserName,
//        chatType: 'singleChat'
//    })
//    //console.log("messages", messages);
//    return messages.messages.reverse()
//}

//export const getRoomHistoryMessages = async (chatRoomId: string) => {
//    if (!conn) {
//        console.error("Agora Chat not initialized.");
//        return [];
//    }

//    try {
//        const messages = await conn.getHistoryMessages({
//            targetId: chatRoomId,
//            chatType: 'chatRoom',
//            pageSize: 20
            
//        });
//        console.log(messages, "getHistoryMessages")

//        return messages.messages.reverse();
//    } catch (error) {
//        console.error("Error fetching chat room messages:", error);
//        return [];
//    }
//};

export const getRoomHistoryMessages = async (agoraUserName: string) => {
    const messages = await conn.getHistoryMessages({
        targetId: agoraUserName,
        chatType: 'chatRoom',
    })
    return messages.messages.reverse()
}

//export const sendRoomMessage = async (chatRoomId: string, text: string,) => {
//    if (!conn) {
//        console.error("Agora Chat not initialized.");
//        return;
//    }

//    if (!chatRoomId || !text) {
//        console.error("❌ Missing chat room ID or message text");
//        return;
//    }

//    const message = AC.message.create({
//        chatType: "chatRoom",
//        type: "txt",
//        to: chatRoomId,
//        msg: text,
//    });
//    console.log(text ,"%%text")

//    try {
//        if (conn.isOpened()) {
//            return conn.send(message)
//                .then((res: any) => {
//                    //console.log("✅ Message sent successfully:", res);
//                    return res;
//                })
//                .catch((e: any) => {
//                    //console.error("❌ Send chat room message error", e);
//                    throw e;
//                });
//        }
//    } catch (error) {
//        console.error("❌ Chat room message sending failed:", error);
//        throw error;
//    }
//};
export const sendRoomMessage = async (
    chatRoomId: string,
    text: string,
    type: "txt" | "custom",
    customData?: object,
    customEvent?: string
) => {
    if (!conn) {
        console.error("Agora Chat not initialized.");
        return;
    }

    if (!chatRoomId || (!text && type === "txt")) {
        console.error("❌ Missing chat room ID or message text");
        return;
    }

    let message;
    
    if (type === "txt") {
        message = AC.message.create({
            chatType: "chatRoom",
            type: "txt",
            to: chatRoomId,
            msg: text,
        });
    } else if (type === "custom" && customData) {
        message = AC.message.create({
            chatType: "chatRoom",
            type: "custom",
            to: chatRoomId,
            customEvent: customEvent ? customEvent : "", 
            customExts: customData, 
        });
    } else {
        console.error("❌ Invalid message format");
        return;
    }

    try {
        if (conn.isOpened()) {
            return conn.send(message)
                .then((res: any) => {
                    console.log("✅ Message sent successfully:", res);
                    return res;
                })
                .catch((e: any) => {
                    console.error("❌ Send chat room message error", e);
                    throw e;
                });
        }
    } catch (error) {
        console.error("❌ Chat room message sending failed:", error);
        throw error;
    }
};


export const getMessageById = async (messageId: string) => {
    if (!conn) {
        console.error("❌ Agora Chat is not initialized.");
        return;
    }

    try {
        const message = await conn.getMessageById(messageId);
        console.log("✅ Retrieved Message:", message);
        return message;
    } catch (error) {
        console.error("❌ Error retrieving message by ID:", error);
    }
};

//export const getConversationContactList = async () => {
//    if (!conn) {
//        console.error("Agora Chat not initialized. Call initializeAgoraChat first.");
//        return [];
//    }

//    try {
//        const response = await conn.getContacts();
//        console.log("*** ✅ Chat Rooms Fetched:", response.data);
//        return response; // List of chat rooms
//    } catch (error) {
//        console.error("❌ Error fetching chat rooms:", error);
//        return [];
//    }
//};

//export const getChatRoomMembers = async (pageNum: number = 1, pageSize: number = 100, chatRoomId: string) => {
//    if (!conn) {
//        console.error("Agora Chat not initialized. Call initializeAgoraChat first.");
//        return [];
//    }

//    try {
//        const response = await conn.listChatRoomMembers({ pageNum, pageSize, chatRoomId });
//        console.log("✅ Chat Rooms Member Fetched:", response.data);
//        return response; // List of chat rooms
//    } catch (error) {
//        console.error("❌ Error fetching chat rooms member:", error);
//        return [];
//    }
//};


//export const getContactsList = async () => {
//    if (!conn) {
//        console.error("Agora Chat not initialized. Call initializeAgoraChat first.");
//        return [];
//    }

//    try {
//        const response = await conn.getJoinedChatRooms({pageNum: 1, pageSize: 20})
//        console.log("✅ Chat getLocalConversations Fetched:", response.data);
//        return response; // List of chat rooms
//    } catch (error) {
//        console.error("❌ Error fetching getLocalConversations:", error);
//        return [];
//    }
//    //const contactList = await conn.getContacts()
//    //console.log(contactList, "✅  contactList")
//    //return contactList;
//}