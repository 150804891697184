import React, { useState } from 'react';
import { Input, InputNumber, Typography } from 'antd';
import './InputBox.scss'

interface InputProps {
    className?: string
    label?: string
    type?: string
    value?: string
    variant?: 'outlined'|'borderless'|'filled'
    width?: string
    placeholder?: string
    prefix?: React.ReactNode | null
    suffix?: React.ReactNode | null
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onNumberInputChange?: (value: string | null) => void
    status?: 'error'|'warning'|''
    errorMessage?: string,
    name?: string,
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    id?: string,
    formikError?: string,
    onKeyDown?: (e:any) => void
}

const { Title, Text } = Typography;


const InputBox = ({
    className = '',
    label = '',
    type = '',
    name = '',
    value = '',
    variant = 'filled',
    width = '100%',
    placeholder = '',
    prefix = null,
    suffix = null,
    status = '',
    formikError = '',
    errorMessage = '',
    id='',
    onChange = () => {},
    onNumberInputChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
}: InputProps) => {

 
    
      const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
          event.preventDefault();
        }
      };
    return (
        <div className={`${className} Input__Container`}>
            {label && <Title className='Input__Container__Label' level={5}>{label}</Title>}
            {/*{type === 'number' ? (
                <InputNumber id={id} type="number"  status={status} className='Input__Container__InputBox !h-[40px]' name={name} prefix={prefix} suffix={suffix} value={value} variant={variant} placeholder={placeholder} style={{width: `${width}`}} onChange={onNumberInputChange} onBlur={onBlur} />
            ) : type === 'password' ? (
                <Input.Password id={id} type="password" status={status} className='Input__Container__InputBox !h-[40px]' prefix={prefix} name={name} suffix={suffix} value={value} variant={variant} onChange={onChange} placeholder={placeholder} onBlur={onBlur} style={{width: `${width}`}} />
            ) : (
                <Input id={id} status={status} className='Input__Container__InputBox !h-[40px]' prefix={prefix}  suffix={suffix} type={type} value={value} variant={variant} onChange={onChange} placeholder={placeholder} onBlur={onBlur} style={{width: `${width}`}}/>
            )}*/}
            {type === 'number' ? (
                <InputNumber
                    id={name} // Ensure id is set
                    type="number" // Explicitly set type
                    status={status}
                    className="Input__Container__InputBox !h-[40px]"
                    name={name}
                    prefix={prefix}
                    stringMode={false}
                    suffix={suffix}
                    value={value}
                    variant={variant}
                    placeholder={placeholder}
                    style={{ width: `${width}` }}
                    onChange={onNumberInputChange}
                    onKeyDown={handleKeyPress}
                    onBlur={onBlur}
                />
            ) : type === 'password' ? (
                <Input.Password
                    id={name}
                    status={status}
                    className="Input__Container__InputBox !h-[40px]"
                    prefix={prefix}
                    name={name}
                    suffix={suffix}
                    type="password"
                    value={value}
                    variant={variant}
                    onChange={onChange}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    style={{ width: `${width}` }}
                />
            ) : (
                <Input
                
                    id={name}
                    status={status}
                    className="Input__Container__InputBox !h-[40px]"
                    prefix={prefix}
                    suffix={suffix}
                    type={type} // Ensure type is always valid
                    value={value}
                    variant={variant}
                    onChange={onChange}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    style={{ width: `${width}` }}
                    onKeyDown={onKeyDown}
                />
            )}
            {formikError && ( <p className='text-xs text-error '>{formikError}</p>)}
            {['error', 'warning'].includes(status) && <Text type='danger'>{errorMessage}</Text>}
        </div>
    );
}

export default InputBox;