import axiosInstance from "./axiosConfig";

export const getJobsList = async (requestParams: { limit: number; lastKey?: string }) => {
    try {
        const params: { limit: number; lastKey?: string } = { limit: requestParams.limit };
        if (requestParams.lastKey) {
            params.lastKey = requestParams.lastKey;
        }
        const response = await axiosInstance.get('job', { params });

        return response;
    } catch (err) {
        console.log('>>>> getJobsList >>> ERROR-> ', err);
    }
};

export const getAppliedJobsList = async (requestParams: { limit: number; lastKey?: string }) => {
    try {
        const params: { limit: number; lastKey?: string } = { limit: requestParams.limit };
        if (requestParams.lastKey) {
            params.lastKey = requestParams.lastKey;
        }
        const response = await axiosInstance.get('job/applied', { params });

        return response;
    } catch (err) {
        console.log('>>>> getJobsList >>> ERROR-> ', err);
    }
};


interface NewJobDetails {
    jobTitle: string
    jobDesc: string
    skills: string[] | []
    tools: string[] | []
    jobType: string
    minBudget?: string
    maxBudget?: string
    maxHours?: string
    paymentRateType?: string
    jobDuration?: string
    jobDurationType?: string
    minHourlyRate?: string
    maxHourlyRate?: string
    maxHoursPerWeek?: string
}

export const createJob = async (payload: NewJobDetails) => {
    try {
        const response = await axiosInstance.post('job', payload);
        return response;
    } catch (err) {
        console.log('>>>> createJob >>> ERROR-> ', err);
    }
}

export const jobApply = async (payload: {
    jobId: string
    description: string
    projects: string[] | []
}) => {
    try {
        const response = await axiosInstance.post('job/apply', payload);
        return response;
    } catch (err: any) {
        console.log('>>>> jobApply >>> ERROR-> ', err);
        return err?.response;
    }
}

export const getClientPostedJobs = async (requestParams: { limit?: number; lastKey?: string }) => {

    try {
        const params: { limit?: number; lastKey?: string } = { limit: requestParams.limit };
        if (requestParams.lastKey) {
            params.lastKey = requestParams.lastKey;
        }
        if (requestParams.limit) {
            params.limit = requestParams.limit;
        }
        const response = await axiosInstance.get('job/history', { params });
        return response;
    } catch (err) {
        console.log('>>>> getClientPostedJobs >>> ERROR-> ', err);
    }
}

export const getApplicationsByJobId = async (jobId: string) => {
    try {
        const response = await axiosInstance.get(`job/applications?jobId=${jobId}`);
        return response;
    } catch (err) {
        console.log('>>>> getApplicationsByJobId >>> ERROR-> ', err);
    }
}

export const acceptApplication = async (requestBody: {
    applicationId: string
}) => {
    try {
        const response = await axiosInstance.post('job/applications', requestBody);
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}

//export const getConversationsList = async ()=>{
//    try {
//        const response = await axiosInstance.get('chat/conversations');
//        return response;
//    } catch(err) {
//        console.log('>>>> acceptApplication >>> ERROR-> ', err);
//    }
//}
//export const getConversationsList = async ()=>{
//    try {
//        const response = await axiosInstance.get('chat/conversations');
//        return response;
//    } catch(err) {
//        console.log('>>>> acceptApplication >>> ERROR-> ', err);
//    }
//}
export const getDropdownJobList = async (requestParams: {
    memberId: string
}) => {
    try {
        const response = await axiosInstance.get('chat/user-project-chat-list', {
            params: requestParams,
        });
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}

//export const initiateChat = async (requestBody: {
//    freelancerUsername: string
//})=>{
//    try {
//        const response = await axiosInstance.post('chat/initiate-chat', requestBody);
//        return response;
//    } catch(err) {
//        console.log('>>>> acceptApplication >>> ERROR-> ', err);
//    }
//}

export const createConversation = async (requestBody: {
    jobId: string,
    agoraUserName: string
}) => {
    try {
        const response = await axiosInstance.post('chat/create-conversation', requestBody);
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}

export const getConversationsList = async () => {
    try {
        const response = await axiosInstance.get('chat/conversation-list');
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}

export const getProposalCheck = async (jobId: string) => {
    try {
        const response = await axiosInstance.get(`proposal/${jobId}`);
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}

export const getJobApplication = async (requestParams: { jobId: string, userId: string }) => {
    try {
        const response = await axiosInstance.get(`job/job-application`, {
            params: requestParams,
        });
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}

export const addUpdateContract = async (requestBody: {
    jobId: string,
    content: string
}) => {
    try {
        const response = await axiosInstance.post('contract', requestBody);
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}

export const getContract = async (contractId: string) => {
    try {
        const response = await axiosInstance.get(`contract/${contractId}`);
        return response;
    } catch (err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}


export const signContract = async (requestParams: { contractId: string }) => {
    try {
        const response = await axiosInstance.patch(`contract/sign?contractId=${requestParams.contractId}`);
        return response;
    } catch (err) {
        console.log('>>>> signContract >>> ERROR-> ', err);
    }
};