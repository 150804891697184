import React, { useEffect, useState } from 'react';
import { Input, message, Select, Typography, Upload } from 'antd';
import './EditProfile.scss'
import InputBox from '../../../../components/InputBox';
import CustomButton from '../../../../components/CustomButton';
import CONSTANTS from './constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import type { UploadFile, UploadProps } from 'antd';
import { updateClientProfile } from '../../../../services/clientService';
import { useNavigate } from 'react-router-dom';
import { setCredentials } from '../../../../slice/authSlice';
import { useAuth } from '../../../../contexts/AuthContext';
import { LoaderCircle } from 'lucide-react';
import { getCityApi, getCountryApi } from '../../../../services/locationService';
import { CityProps, CountryProps } from '../../../Registration/Common/AccountDetails';

interface EditProfileProps {
    imageUrl?: string
    firstName?: string
    lastName?: string
    setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>
}

const { Title } = Typography;
const { TextArea } = Input;


const EditProfile = ({ setIsEditProfile }: EditProfileProps) => {

    const dispatch = useAppDispatch();
    const { token, user: loggedInUser, saveLoggedInUser } = useAuth();
    const user = useAppSelector(state => state?.auth?.loggedInUser);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [userInfo, setUserInfo] = useState({
        profileImageUrl: user?.profileImageUrl,
        firstName: user?.firstName,
        lastName: user?.lastName,
        about: user?.about,
        address: {
            countryId: user?.address?.countryId ?? null,
            cityId: user?.address?.cityId ?? null,
        }
    });
    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [cityLoading, setCityLoading] = useState<boolean>(false)
    const [countryLoading, setCountryLoading] = useState<boolean>(false)
    const [selectedCountry, setSelectedCountry] = useState<number | null>(null)

    //const handleChange: UploadProps['onChange'] = (info) => {
    //    if (info?.file?.status === 'done' && info?.file?.response?.statusCode === 200) {
    //        message.success('Profile picture update successfully!');
    //      const imageUrl = info?.file?.response?.data?.url;
    //      if(imageUrl) {
    //        setUserInfo({...userInfo, profileImageUrl: imageUrl});
    //      } else {
    //        setUserInfo({...userInfo, profileImageUrl: user?.profileImageUrl});
    //      }
    //    }
    //    if(info?.file?.status === 'error') {
    //        setUserInfo({...userInfo, profileImageUrl: user?.profileImageUrl});
    //    }
    //};


    useEffect(() => {
        if (userInfo?.address?.countryId) {
            setSelectedCountry(userInfo?.address?.countryId);
        }

    }, [userInfo?.address?.countryId]);
    const getCountry = () => {
        setCountryLoading(true)
        getCountryApi().then((res: any) => {
            const countries = res.data.data.map((country: CountryProps) => ({
                value: country.countryId,
                label: country.countryName,
            }));
            setCountryList(countries);
            setCountryLoading(false)
        }).catch((err) => {
            setCountryLoading(false)
        })
    }

    useEffect(() => {
        getCountry()
    }, [])

    const getCity = (countryId: number) => {
        setCityLoading(true)
        getCityApi(countryId).then((res: any) => {
            const cities = res.data.data.map((city: CityProps) => ({
                value: city.cityId,
                label: city.cityName,
            }));
            setCityList(cities);
            setCityLoading(false)
        }).catch((err) => {
            setCityLoading(false)
        })
    }

    useEffect(() => {
           if (selectedCountry) {
               getCity(selectedCountry);
           }
       }, [selectedCountry]);


        const handleChange: UploadProps['onChange'] = (info) => {
            let newFileList = [...info.fileList];

            if (info.file.status === 'uploading') {
                setFileList(newFileList);
            }

            if (info.file.status === 'done' && info.file.response?.statusCode === 200) {
                const imageUrl = info?.fileList[0]?.response?.data?.url;
                message.success('Profile picture updated successfully!');
                setFileList(newFileList);
                if (imageUrl) {
                    setUserInfo({ ...userInfo, profileImageUrl: imageUrl });
                } else {
                    setUserInfo({ ...userInfo, profileImageUrl: user?.profileImageUrl });
                }
            }

            if (info.file.status === 'error') {
                message.error('Failed to upload image.');
                setFileList(newFileList);
                setUserInfo({ ...userInfo, profileImageUrl: user?.profileImageUrl });
            }
        };

        const handleProfileUpdate = async () => {
            setLoading(true);
            const resp = await updateClientProfile(userInfo);
            if (resp?.data?.statusCode === 200 || resp?.data?.statusCode === 201) {
                setLoading(false);
                saveLoggedInUser({ ...loggedInUser, ...resp?.data?.data });
                dispatch(setCredentials({ user: { ...user, ...resp?.data?.data } }));
                setIsEditProfile(false);
            } else {
                setLoading(false);
            }
        }

        return (
            <div className='EditProfile'>
                <div className='EditProfile__Title'>{CONSTANTS.EDIT_PROFILE}</div>
                <div className='EditProfile__ImageSection'>
                    <img src={userInfo?.profileImageUrl} alt={user?.firstName} />
                    <Upload
                        accept=".jpg,.jpeg,.png"
                        className='EditProfile__ImageSection__UploadButton'
                        name='file'
                        multiple={false}
                        maxCount={1}
                        action="https://dev-api.mercurygigs.com/common/file/upload"
                        onChange={handleChange}
                        headers={{ 'Authorization': token || '' }}
                        data={{ type: 'profile' }}
                        showUploadList={false}
                        fileList={fileList}
                    >
                        {fileList.some(file => file.status === 'uploading') ? (
                            <div className='d-flex items-center gap-2 w-full'>
                                <LoaderCircle className='animate-spin' />
                                <p className='text-black text-base'>Uploading..</p>
                            </div>
                        ) : (
                            CONSTANTS.UPLOAD_PHOTO
                        )}

                    </Upload>
                </div>
                <div className='EditProfile__Inputs'>
                    <InputBox width='290px' label={CONSTANTS.FIRST_NAME_LABEL} placeholder={CONSTANTS.FIRST_NAME_PLACEHOLDER} value={userInfo?.firstName} onChange={(event) => setUserInfo({ ...userInfo, firstName: event?.target?.value })} />
                    <InputBox width='290px' label={CONSTANTS.LAST_NAME_LABEL} placeholder={CONSTANTS.LAST_NAME_PLACEHOLDER} value={userInfo?.lastName} onChange={(event) => setUserInfo({ ...userInfo, lastName: event?.target?.value })} />
                </div>
                <div className='EditProfile__AboutInput'>
                    <Title className='EditProfile__AboutInput__Title' level={5}>About</Title>
                    <TextArea
                        className='EditProfile__AboutInput__TextArea'
                        value={userInfo?.about}
                        rows={3}
                        variant='filled'
                        placeholder='Tell us about yourself'
                        onChange={(event) => setUserInfo({ ...userInfo, about: event?.target?.value })}
                    />
                </div>
                <div className='flex w-full gap-2 items-center'>
                    <div className='w-full'>
                        <Title className='AccountDetails__ThirdRow__Title' level={5}>Country</Title>
                        <Select
                            className="w-full"
                            variant="filled"
                            placeholder="Select a country"
                            value={countryLoading ? null : userInfo?.address?.countryId || undefined}
                            onChange={(value) => {
                                setUserInfo({
                                    ...userInfo,
                                    address: {
                                        countryId: value,
                                        cityId: null,
                                    }
                                });
                            }}
                            showSearch
                            filterOption={(input, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={countryList}
                            loading={countryLoading}
                            suffixIcon={countryLoading ? <LoaderCircle size={18} className='text-primary animate-spin'/> : undefined}
                        />
                    </div>
                    <div className='w-full'>
                        <Title className='AccountDetails__ThirdRow__Title' level={5}>City</Title>
                        <Select
                            loading={cityLoading}
                            className="w-full"
                            variant="filled"
                            placeholder="Select a city"
                            options={cityList}
                            disabled={!selectedCountry}
                            onChange={(value) => {
                                setUserInfo({
                                    ...userInfo,
                                    address: {
                                        ...userInfo.address,
                                        cityId: value
                                    }
                                });
                            }}
                            showSearch
                            filterOption={(input, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            value={cityLoading ? null : userInfo?.address?.cityId || undefined}
                            suffixIcon={cityLoading ? <LoaderCircle size={18} className='text-primary animate-spin'/> : undefined}
                        />
                    </div>
                </div>
                <div className='EditProfile__Buttons'>
                    <CustomButton buttonText={CONSTANTS.BACK} onClick={() => setIsEditProfile(false)} />
                    <CustomButton buttonText={CONSTANTS.SAVE} onClick={handleProfileUpdate} loading={loading} />
                </div>
            </div>
        );
    }

export default EditProfile;