import React, { useEffect, useState } from 'react';
import { Flex, Typography, Carousel } from 'antd';
import logo from "../../assets/logo.svg";
import carousel1 from '../../assets/carousel1.png';
import banner from '../../assets/banner.png';
import freelancerUser from '../../assets/freelancerUser.png';
import rocket from '../../assets/rocket.png';
import './Landing.scss'
import CustomButton from '../../components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import InputBox from '../../components/InputBox';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const { Title, Text } = Typography;

const topCategories = [
    {id: 1, imageUrl: carousel1, title: 'House Cleaning'},
    {id: 2, imageUrl: carousel1, title: 'Interior Painting'},
    {id: 3, imageUrl: carousel1, title: 'Event Photography'},
    {id: 4, imageUrl: carousel1, title: 'Makeup Artistry'},
    {id: 5, imageUrl: carousel1, title: 'Phone Repairing'},
    {id: 6, imageUrl: carousel1, title: 'Pressure Washing'},
]

const navbarOptions = [
    {id: 'platform', label: 'Platform', redirectUrl: '/landing'},
    {id: 'about', label: 'About', redirectUrl: '/landing'},
    {id: 'contact', label: 'Contact', redirectUrl: '/landing'},
    {id: 'jobs', label: 'Jobs', redirectUrl: '/landing'},
    {id: 'jobPost', label: 'Post a Job', redirectUrl: '/landing'},
]

const slickSettings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },

        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const LandingPage = () => {

    const navigate = useNavigate();

    return (
        <div className='Landing'>
            <Flex vertical={true} className='Landing__Navigation'>
                <div className='Landing__Navigation__Navbar'>
                    <div className='Landing__Navigation__Navbar__Logo'>
                        <img src={logo} alt='logo' />
                    </div>
                    <div className='Landing__Navigation__Navbar__Options'>
                        {navbarOptions?.map((option: any) => <Link className='Landing__Navigation__Navbar__Options__Item' to={option?.redirectUrl} key={option?.id}>{option?.label}</Link>)}
                    </div>
                    <div className='Landing__Navigation__Navbar__Actions'>
                        <CustomButton className='Login__Card__LeftSection__SecondRow__Actions__SignIn' onClick={() => navigate('/login')} buttonText={'Login'} />
                        <CustomButton className='Login__Card__LeftSection__SecondRow__Actions__SignIn' onClick={() => navigate('/registration')} buttonText={'Signup'} />
                    </div>
                </div>
                <div className='Landing__Navigation__SubNavbar'>
                    <a className='Landing__Navigation__SubNavbar__Item'>All Services</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>House Cleaning</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>Event Photography</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>Makup Artistry</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>Phone Repairing</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>Pressure Washing</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>Accountant</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>Mechanic</a>
                    <a className='Landing__Navigation__SubNavbar__Item'>Architect</a>
                </div>
                <div className='Landing__Navigation__Content'>
                    <Carousel autoplay={false} className='Landing__Navigation__Content__Carousel'>
                        <div className='Landing__Navigation__Content__Carousel__Item'>
                            <img src={carousel1} width={'100%'} alt='carousel' />
                            <div className='Landing__Navigation__Content__Carousel__Item__OverlayText'>
                                <div className='Landing__Navigation__Content__Carousel__Item__OverlayText__Title'>
                                    A new way to work
                                </div>
                                <div className='Landing__Navigation__Content__Carousel__Item__OverlayText__SubTitle'>
                                    Manage your skills-based flexible workforce, all in one place.
                                </div>
                            </div>
                        </div>
                        <div className='Landing__Navigation__Content__Carousel__Item'>
                            <img src={carousel1} width={'100%'} alt='carousel' />
                        </div>
                        <div className='Landing__Navigation__Content__Carousel__Item'>
                            <img src={carousel1} width={'100%'} alt='carousel' />
                        </div>
                        <div className='Landing__Navigation__Content__Carousel__Item'>
                            <img src={carousel1} width={'100%'} alt='carousel' />
                        </div>
                    </Carousel>
                </div>
            </Flex>

            <div className='Landing__Categories'>
                <div className='Landing__Categories__TopCategories'>
                    <div className='Landing__Categories__TopCategories__Title'>Browse top categories</div>
                    <div className='Landing__Categories__TopCategories__List'>
                        <Slider {...slickSettings} className='Landing__Categories__TopCategories__List__Slider'>
                            {topCategories?.map((item) => (
                                <div className='Landing__Categories__TopCategories__List__Slider__Item' key={item?.id}>
                                    <img src={item?.imageUrl} alt={item?.title} />
                                    <Text strong>{item?.title}</Text>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

                <div className='Landing__Categories__Banner'>
                    <div className='Landing__Categories__Banner__LeftSection'>
                        <div className='Landing__Categories__Banner__LeftSection__Text1'>The one site you need to get <span>everything done</span></div>
                        <div className='Landing__Categories__Banner__LeftSection__Text2'>From custom guides made just for you to effortless project planning, it’s all here — in one free site</div>
                    </div>
                    <div className='Landing__Categories__Banner__RightSection'><img src={banner} alt='portal' /></div>
                </div>
            </div>

            <div className='Landing__FreelancerBanner'>
                <div className='Landing__FreelancerBanner__LeftSection'>
                    <img src={freelancerUser} alt='freelancer' />
                </div>
                <div className='Landing__FreelancerBanner__RightSection'>
                    <div className='Landing__FreelancerBanner__RightSection__Title'>Are you a freelancer?</div>
                    <div className='Landing__FreelancerBanner__RightSection__Description'>Join the world’s best independents on the commission-free freelancer marketplace with $30MM+ in projects.</div>
                    <div className='Landing__FreelancerBanner__RightSection__Signup'>
                        <CustomButton onClick={() => {}} buttonText='Sign up' />
                    </div>
                </div>
                <div className='Landing__FreelancerBanner__Rocket'>
                    <img src={rocket} alt='rocket' />
                </div>
            </div>

            <div className='Landing__Footer'>
                <div className='Landing__Footer__LeftSection'>
                    <div className='Landing__Footer__LeftSection__Header'>
                        <div className='Landing__Footer__LeftSection__Header__Title'>Join our newsletter</div>
                        <div className='Landing__Footer__LeftSection__Header__Description'>Sign up to our mailing list below and be the first to know about new updates. Don't worry, we hate spam too.</div>
                    </div>
                </div>
                <div className='Landing__Footer__RightSection'>
                    <div className='Landing__Footer__RightSection__Links'>
                        <div className='Landing__Footer__RightSection__Links__Quick'>
                            <div className='Landing__Footer__RightSection__Links__Quick__Title'>Quick Links</div>
                            {['Home', 'Services', 'Client', 'Blog']?.map((item, index) => <Link className='Landing__Footer__RightSection__Links__Quick__Option' to="/landing" key={index}>{item}</Link>)}
                        </div>
                        <div className='Landing__Footer__RightSection__Links__Social'>
                            <div className='Landing__Footer__RightSection__Links__Social__Title'>Social Links</div>
                            {['Facebook', 'LinkedIn']?.map((item, index) => <Link className='Landing__Footer__RightSection__Links__Social__Option' to="/landing" key={index}>{item}</Link>)}
                        </div>
                    </div>
                    <div className='Landing__Footer__RightSection__Copyright'>
                        &copy; Copyright Mercury 2024
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage;