import React, { useEffect, useRef, useState } from 'react';
import { Typography, Select, Segmented, Drawer, Spin } from 'antd';
import chat from '../../assets/chat.svg';
import notifications from '../../assets/notifications.svg';
import user from '../../assets/user.png';
import './FreelancerJobs.scss';
import CustomButton from '../../components/CustomButton';
import InputBox from '../../components/InputBox';
import { getAppliedJobsList, getJobsList, jobApply } from '../../services/jobService';
import JobDetails from '../../components/JobDetails';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import JobApply from '../JobApply';
import { setSelectedJob } from '../../slice/freelancerSlice';
import JobOverview from '../JobOverview';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

const FreelancerJobs = () => {

    const dispatch = useAppDispatch();

    const [jobsList, setJobsList] = useState<any[]>([]);
    const [lastJobKey, setLastJobKey] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();

    const userInfo = useAppSelector((state: any) => state?.auth?.loggedInUser);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showOverviewScreen, setShowOverviewScreen] = useState(false);
    const [selectedJobFilter, setSelectedJobFilter] = useState<string>('All Jobs')


    useEffect(() => {
        setJobsList([])
    }, [selectedJobFilter])
    
    const fetchAllJobs = async (lastJob: string) => {
        try {
            const response = await getJobsList({ limit: 1000, lastKey: lastJob });
            if (response?.data?.statusCode === 200) {
                const newJobs = response?.data?.data?.jobs;
                setJobsList(prevJobs => [...prevJobs, ...newJobs]);
                setLastJobKey(response?.data?.data?.lastKey || null);
                setHasMore(!!response?.data?.data?.lastKey);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
            setHasMore(false);
        }
    };
  

    const fetchAppliedJobs = async (lastJob: string) => {
        try {
            const response = await getAppliedJobsList({ limit: 10, lastKey: lastJob });
            if (response?.data?.statusCode === 200) {
                const newJobs = response?.data?.data?.jobs;
                setJobsList(prevJobs => [...prevJobs, ...newJobs]);
                setLastJobKey(response?.data?.data?.lastKey || null);
                setHasMore(!!response?.data?.data?.lastKey);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
            setHasMore(false);
        }
    };

    useEffect(() => {
        //if (dataFetchedRef.current) return;
        //dataFetchedRef.current = true;
        if (selectedJobFilter === 'All Jobs') {
            fetchAllJobs('');
        } else  {
            fetchAppliedJobs('')
        }
    }, [selectedJobFilter]);


    const onDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleOnJobClick = (job: any) => {
        dispatch(setSelectedJob({ job }));
        setShowOverviewScreen(() => true);
        setOpenDrawer(() => true);
    }

    const handleOnApplyBtnClick = (job: any) => {
        setJobsList([])
        dispatch(setSelectedJob({ job }));
        setOpenDrawer(true);
        setShowOverviewScreen(() => false);
    }
    const chatRedirection = () => {
        navigate('/chat')
    }
    return (
        <div className='FreelancerJobs'>
            <div className='FreelancerJobs__Header'>
                <div className='FreelancerJobs__Header__Title'>Jobs</div>
                <div className='FreelancerJobs__Header__Actions'>
                    <CustomButton buttonText='Upgrade' onClick={() => { }} />
                    <img src={chat} alt='chat' style={{ cursor: 'pointer' }} onClick={chatRedirection} />
                    <img src={notifications} alt='notifications' />
                </div>
            </div>
            <div className='FreelancerJobs__Body'>
                <div className='FreelancerJobs__Body__Filters'>
                    <Segmented
                        className="h-12 flex px-2 items-center border border-border-primary font-sans text-black"
                        options={['All Jobs', 'Applied']}
                        value={selectedJobFilter}
                        onChange={(value) => {
                            setJobsList([])
                            setLastJobKey(null)
                            setHasMore(true)
                            setSelectedJobFilter(value)
                        }}
                    />
                    <InputBox className='FreelancerJobs__Body__Filters__SearchInput' placeholder='Search for a Nursing Assistant' onChange={(event) => { }} />
                </div>
                <div className='FreelancerJobs__Body__JobsList'>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => {  if (lastJobKey) { selectedJobFilter === 'All Jobs'?  fetchAllJobs(lastJobKey): fetchAppliedJobs (lastJobKey) } }}
                        hasMore={hasMore}
                        loader={<div className='w-full flex justify-center my-2'><Spin /></div>}
                        useWindow={false}
                    >
                        {jobsList?.map((job: any) =>  (                              
                            <JobDetails
                                    userRole={userInfo?.role}
                                    jobId={selectedJobFilter === 'All Jobs' ? job?.jobId : job?.job?.jobId}
                                    clientName={selectedJobFilter === 'All Jobs' ? `${job?.client?.firstName} ${job?.client?.lastName}`: `${job?.job?.client?.firstName} ${job?.job?.client?.lastName}`}
                                    posterImageUrl={selectedJobFilter === 'All Jobs' ? job?.client?.profileImageUrl : job?.job?.client?.profileImageUrl}
                                    jobDurationLabel={selectedJobFilter === 'All Jobs' ? job?.jobDurationLabel: job?.job?.jobDurationLabel}
                                    hiringType={selectedJobFilter === 'All Jobs' ?  job?.jobType : job?.job?.jobType}
                                    jobTitle={selectedJobFilter === 'All Jobs' ? job?.jobTitle : job?.job?.jobTitle}
                                    minBudget={selectedJobFilter === 'All Jobs' ? job?.jobType === 'onetime' ? job?.minBudget : job?.minHourlyRate : job?.job?.jobType === 'onetime' ? job?.job?.minBudget : job?.job?.minHourlyRate}
                                    maxBudget={selectedJobFilter === 'All Jobs' ? job?.jobType === 'onetime' ? job?.maxBudget : job?.maxHourlyRate : job?.job?.jobType === 'onetime' ? job?.job?.maxBudget : job?.job?.maxHourlyRate}
                                    jobType={selectedJobFilter === 'All Jobs' ? job?.jobType : job?.job?.jobType}
                                    duration={selectedJobFilter === 'All Jobs' ? job?.maxHoursPerWeek :job?.job?.maxHoursPerWeek}
                                    postedDate={selectedJobFilter === 'All Jobs' ?  job?.createdAt : job?.job?.createdAt}
                                    skills={selectedJobFilter === 'All Jobs' ? job?.skills : job?.job?.skills}
                                    key={selectedJobFilter === 'All Jobs' ?  job?.jobId : job?.job?.jobId}
                                    showApplyButton={selectedJobFilter === 'All Jobs' ? !job?.jobApplication?.status : !job?.status}
                                    onApplyClick={(event) => {
                                        event.stopPropagation();
                                        handleOnApplyBtnClick(selectedJobFilter === 'All Jobs' ? job : job?.job);
                                    }}
                                    onClick={() => handleOnJobClick(selectedJobFilter === 'All Jobs' ? job : job?.job)}
                                    status={selectedJobFilter === 'All Jobs' ? job?.jobApplication?.status : job?.status}
                                />
                            ))}
                    </InfiniteScroll>
                </div>
            </div>
            <Drawer
                closable={false}
                onClose={onDrawerClose}
                open={openDrawer}
                size={'large'}
            >
                {showOverviewScreen ? <JobOverview onApply={() => setShowOverviewScreen(false)} /> : <JobApply onAfterSubmit={() =>  {setJobsList([]); fetchAllJobs('')}} onClose={onDrawerClose} />}
            </Drawer>
        </div>
    );
};

export default FreelancerJobs;