import axiosInstance from "./axiosConfig";

export const getServices = async (type: string) => {
    try {
        const response = await axiosInstance.get(`services/categories?type=${type}`);
        return response;
    } catch(err) {
        console.log('>>>> getServices >>> ERROR-> ', err);
    }
};

export const saveInterestedServices = async (requestData: {servicesInterested: string[]}) => {
    try {
        const response = await axiosInstance.post('client/services', requestData);
        return response;
    } catch(err) {
        console.log('>>>> saveInterestedServices >>> ERROR-> ', err);
    }
};

export const getSkillsList = async () => {
    try {
        const response = await axiosInstance.get('job/skills');
        return response;
    } catch(err) {
        console.log('>>>> getSkillsList >>> ERROR-> ', err);
    }
};

export const getToolsList = async () => {
    try {
        const response = await axiosInstance.get('job/tools');
        return response;
    } catch(err) {
        console.log('>>>> getToolsList >>> ERROR-> ', err);
    }
};

export const updateClientProfile = async (requestData: {
    profileImageUrl: string
    firstName: string
    lastName: string
}) => {
    try {
        const response = await axiosInstance.post('common/profile', requestData);
        return response;
    } catch(err) {
        console.log('>>>> updateClientProfile >>> ERROR-> ', err);
    }
}