import { message } from 'antd';
import { getClientDataPacket, getfreelancerDataPacket } from "../utils/globalUtils";
import axiosInstance from "./axiosConfig";

//export const registerUser = async (requestData: {
//    [key: string]: string;
//    role: string;
//  }) => {
//    try {
//        const response = await axiosInstance.post('/auth/register', requestData);
//        return response;
//    } catch(err) {
//        console.log('>>>>>>> ERROR-> ', err);
//        throw err;
//    }
//};
export const registerUser = async (requestData: {
  [key: string]: string;
  role: string;
}) => {
  try {
    const response = await axiosInstance.post('/auth/register', requestData);
    return response;
  } catch (err: any) {
    if (err.response && err.response.status === 409) {
      return err.response; // Returning the response instead of throwing an error
    }
    throw err; // For other errors, rethrow them
  }
};


export const verifyOtp = async (requestData: {
  [key: string]: string;
  otp: string;
}) => {
  try {
      const response = await axiosInstance.post('/auth/verify-otp', requestData);
      localStorage.setItem('userDetails', JSON.stringify(response?.data?.data));
      return response;
  } catch(err) {
      console.log('>>>>>>> ERROR-> ', err);
      throw err
      ; // For other errors, rethrow them
  }
};

export const setupUserPassword = async (requestData: {
  password: string
}) => {
  try {
      const response = await axiosInstance.post('auth/setup-password', requestData);
      return response;
  } catch(err) {
      console.log('>>>>>>> ERROR-> ', err);
      throw err;
  }
};

export const postFreelancerDetails = async (requestData: {
  fullName: string
  userName: string
  phoneNumber: string
  dateOfBirth: string
  about: string
  address: {
    countryId: number | null;
    cityId: number | null;
};
  professionalTitle: string
  serviceId: string
  openingHrs: string
  closingHrs: string
  portfolioUrl1: string
  portfolioUrl2: string
}) => {
  try {
      const requestPacket = getfreelancerDataPacket(requestData);
      const response = await axiosInstance.post('freelancer/onboarding', requestPacket);
      return response;
  } catch(err) {
      console.log('>>>>>>> ERROR-> ', err);
  }
};

export const postClientDetails = async (requestData: {
  fullName: string
  userName: string;
  phoneNumber: string
  dateOfBirth: string
  about: string
  address: {
    countryId: number | null;
    cityId: number | null;
};
}) => {
  try {
      const requestPacket = getClientDataPacket(requestData);
      const response = await axiosInstance.post('client/enrollment', requestPacket);
      return response;
  } catch(err) {
      console.log('>>>> postFreelancerDetails >>> ERROR-> ', err);
  }
};

export const postProfilePicture = async (requestData: {
  profileImageUrl: string
}) => {
  try {
    const response = await axiosInstance.post('common/profile', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> postProfilePicture >>>>> ERROR-> ', err);
  }
}

export const uploadDocument = async(requestData: {
  type: string,
  file: string
}) => {
  try {
    const response = await axiosInstance.post('common/file/upload', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> uploadDocument >>>>> ERROR-> ', err);
  }
}

export const saveVerifyDocument = async(requestData: {
  type: string,
  fileUrl: string
}) => {
  try {
    const response = await axiosInstance.post('common/user-verification', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> verifyIdentity >>>>> ERROR-> ', err);
  }
}

export const loginUser = async(requestData: {
  email: string
  password: string
}) => {
  try {
    const response = await axiosInstance.post('auth/login', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> loginUser >>>>> ERROR-> ', err);
  }
}


export const getProfile = async () => {
  try {
      const response = await axiosInstance.get('common/profile');
      return response;
  } catch(err) {
      message.error('Failed to fetch profile data');
      console.log('>>>> getProfile >>> ERROR-> ', err);
  }
};

export const forgotPasswordApi = async (requestData: { email: string; otp?: string }) => {
  try {
    const payload: { email: string; otp?: string } = { email: requestData.email };
    
    if (requestData.otp) {
      payload.otp = requestData.otp;
    }

    const response = await axiosInstance.post('/auth/forgot-password', payload);
    return response;
  } catch (err: any) {
    if (err.response && err.response.status === 409) {
      return err.response;
    }
    throw err;
  }
};

export const resetPassword = async (requestData: { email: string; otp: string, password: string }) => {
  try {
    const response = await axiosInstance.post('/auth/reset-password', requestData);
    return response;
  } catch (err: any) {
    if (err.response && err.response.status === 409) {
      return err.response;
    }
    throw err;
  }
};