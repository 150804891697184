import React, { useEffect, useState } from 'react';
import { Divider, message } from "antd";
import map from '../../assets/map.svg';
import phone from '../../assets/phone.svg';
import calendar from '../../assets/calendar.svg';
import user from '../../assets/user.png';
import edit from '../../assets/edit.svg';
import behance from '../../assets/behance.svg';
import dribble from '../../assets/dribble.svg';
import CustomButton from '../CustomButton';
import './ProfileCard.scss';
import fallback from '../../assets/fallback.png'
import { getProfile } from '../../services/authService';
import { MapPin } from 'lucide-react';

interface FreelancerProfile  {
    address?: {
        cityId?: number,
        cityName?: string,
        countryId?:number,
        countryName?: string

    }
    portfolioUrl: string[];
    lastName: string;
    userName: string;
    createdAt: string; // ISO date string
    professionalTitle: string;
    firstName: string;
    freelancerId: string;
    about: string;
    userId: string;
    updatedAt: string; // ISO date string
    serviceId: string;
    workingHours: {
        openHour: string; // "HH:MM" format
        closeHour: string; // "HH:MM" format
    };
    dateOfBirth: string; // "YYYY-MM-DD" format
    phone: string;
};
interface ProfileCardProps {
    imageUrl?: string
    userName?: string
    phoneNumber?: string
    dob?: string
    role?: string
    about?: string
    address?: {
        cityId?: number,
        cityName?: string,
        countryId?:number,
        countryName?: string

    }
    responseTime?: string
    setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>
}

const skills = [
    {label: 'Image retouching', id: 1},
    {label: 'Fashion photography', id: 2},
    {label: 'Headshot photography', id: 3},
    {label: 'Framer Developer', id: 4},
]


const ProfileCard = ({
    imageUrl = '', 
    userName = '',
    phoneNumber = '',
    dob = '',
    address = {},
    about = '',
    role = 'client',
    responseTime = '',
    setIsEditProfile}: ProfileCardProps) => {
    const [profileInfo, setProfileInfo] = useState<FreelancerProfile | null>(null)
    useEffect(() => {
        getProfile().then((res: any) => {
            setProfileInfo(res?.data?.data)
        }).catch(() => {
            message.error('Failed to fetch profile data');
        })
    }, [])
    return (
        <div className='ProfileCard'>
            <div className='ProfileCard__UserImage'>
                <img className='ProfileCard__UserImage__Image' src={imageUrl} alt={userName} onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = fallback
                    }}
                    />
                <CustomButton className='ProfileCard__UserImage__VerifiedButton' buttonText='Verified' onClick={() => {}} />
                {/* <Link to={'/edit-profile'} className='ProfileCard__UserImage__EditProfile'><img src={edit} alt='profile edit' /></Link> */}
                <div className='ProfileCard__UserImage__EditProfile' onClick={() => setIsEditProfile(true)}><img src={edit} alt='profile edit' />
                    </div>
            </div>
            <div className='ProfileCard__UserName'>
                {userName}
            </div>
            {role === 'client' && (
                <div className='ProfileCard__About'>
                    <div className='ProfileCard__About__Title'>About</div>
                    <div className='ProfileCard__About__Info'>
                        <div>
                            {about?.length ? about : '-'}
                        </div>
                        <Divider className='ProfileCard__About__Info__Divider' />
                        <div className='flex items-start'>
                            <img src={map} alt='map pointer'/>
                            <p className='font-medium'>{[address?.countryName, address?.cityName].filter(Boolean).join(", ")}</p>
                        </div>
                        <Divider className='ProfileCard__About__Info__Divider' />
                        <div>
                            <img src={phone} alt='phone'/>{phoneNumber}
                        </div>
                        <Divider className='ProfileCard__About__Info__Divider' />
                        <div>
                            <img src={calendar} alt='calendar'/>{dob}
                        </div>
                    </div>
                </div>
            )}
            {role === 'freelancer' && (
                <>
                    <div className='ProfileCard__GetInTouch'>
                        <CustomButton buttonText='Get in touch' onClick={() => {}} />
                        <div className='ProfileCard__GetInTouch__RespondTime'>responds within {responseTime} hours</div>
                    </div>
                    <div className='ProfileCard__FreelancerAbout'>
                        <div className='ProfileCard__FreelancerAbout__Description'>
                            <div className='ProfileCard__FreelancerAbout__Description__Title'>
                                Description
                            </div>
                            <div className='ProfileCard__FreelancerAbout__Description__Body'>
                                {profileInfo?.about }
                                {/*I'm a professional photographer. I’m highly adaptable and experienced, specializing in weddings, engagements and portraiture photography.*/}
                            </div>
                            <div className='flex items-start'>
                            <img src={map} alt='map pointer'/>
                            <p className='font-medium'>{[profileInfo?.address?.countryName, profileInfo?.address?.cityName].filter(Boolean).join(", ")}</p>
                        </div>
                        </div>
                        <Divider className='ProfileCard__FreelancerAbout__Divider' />
                        {/*<div className='ProfileCard__FreelancerAbout__SkillSet'>
                            <div className='ProfileCard__FreelancerAbout__SkillSet__Title'>
                                Skill Set
                            </div>
                            <div className='ProfileCard__FreelancerAbout__SkillSet__Body'>
                                {skills?.map(skill => (<div className='ProfileCard__FreelancerAbout__SkillSet__Body__SkillTile' key={skill?.id}>{skill?.label}</div>))}
                            </div>
                        </div>*/}
                        {/*<Divider className='ProfileCard__FreelancerAbout__Divider' />*/}
                        <div className='ProfileCard__FreelancerAbout__Links'>
                            <div className='ProfileCard__FreelancerAbout__Links__Title'>
                                Links
                            </div>
                            <div className='ProfileCard__FreelancerAbout__Links__Body flex flex-col gap-1'>
                                {profileInfo?.portfolioUrl.map((link, index) => (
                                    <a
                                        className="text-sm hover:underline hover:text-primary"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        key={index}
                                        href={link.startsWith('http') ? link : `https://${link}`} // Ensure valid URL format
                                    >
                                        {link}
                                    </a>
                                ))}
                                {/*<div><img src={behance} alt='behance link' /></div>*/}
                                {/*<div><img src={dribble} alt='behance link' /></div>*/}
                            </div>
                        </div>
                        <Divider className='ProfileCard__FreelancerAbout__Divider' />
                        <div className='ProfileCard__FreelancerAbout__Milestones'>
                            <div className='ProfileCard__FreelancerAbout__Milestones__Title'>
                                Milestones
                            </div>
                            <div className='ProfileCard__FreelancerAbout__Milestones__Body'>
                               <div className='ProfileCard__FreelancerAbout__Milestones__Body__Tile'>0 Projects completed</div>
                               <div className='ProfileCard__FreelancerAbout__Milestones__Body__Tile'>0 5-star reviews</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default ProfileCard;