import axios from "axios";
import axiosInstance from "./axiosConfig";

//const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_KEY;
//export const generateContractContent = async (prompt: string) => {
//    try {
//        const response = await axios.post(
//            "https://api.openai.com/v1/chat/completions",
//            {
//                model: "gpt-4", // Choose "gpt-3.5-turbo" if needed
//                //messages: [{ role: "system", content: "You are a contract writer." }, { role: "user", content: prompt }],
//                messages: [{ role: "user", content: prompt }],
//                //max_tokens: 300, // Adjust length of response
//            },
//            {
//                headers: {
//                    "Authorization": `Bearer ${OPENAI_API_KEY}`,
//                    "Content-Type": "application/json"
//                }
//            }
//        );
//        return response.data.choices[0].message.content;
//    } catch (error) {
//        console.error("OpenAI API Error:", error);
//        return "Error generating content.";
//    }
//};



export const generateContract = async (message: string) => {
    try {
        const response = await axiosInstance.post('chatgpt', {message: message});
        return response;
    } catch(err) {
        console.log('>>>> createJob >>> ERROR-> ', err);
    }
}