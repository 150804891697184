import React, { useEffect, useState } from 'react'
import { Input, message, Typography } from 'antd';
import freelancer from "../../assets/freelancer.svg";
import logo from "../../assets/logo.svg";
import '../Login/Login.scss'
import CustomButton from '../../components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPasswordApi, loginUser, verifyOtp } from '../../services/authService';
import CONSTANTS from '../Login/constans';
import InputBox from '../../components/InputBox';
const { Title } = Typography;
const ForgotPassword = () => {
    const [userCredentials, setUserCredentials] = useState({ email: '' });
    const [sendOtp, setSendOtp] = useState<boolean>(false)
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState<string>('');
const navigate = useNavigate()
    useEffect(() => {
        setOtp('')
    }, [sendOtp])

    const handleForgotPassword = () => {
        setLoading(true);
        const body = {
            email: userCredentials.email
        }
        forgotPasswordApi(body).then((res) => {
            setSendOtp(true)
            message.success({
                content: res?.data?.message,
                duration: 3,
                className: 'font-sans'
            });
            setLoading(false);

        }).catch((err) => {
            setSendOtp(false)
            message.error({
                content: err?.response?.data?.message,
                duration: 5,
                className: 'font-sans'
            });
            setLoading(false);
        })
    }

    const handleVerifyOtp = () => {
        setLoading(true);
        forgotPasswordApi({ email: userCredentials.email, otp: otp }).then((res) => {
            setLoading(false);
            message.success({
                content: res?.data?.message,
                duration: 3,
                className: 'font-sans'
            });
            navigate('/reset-password', {state: { email: userCredentials.email, otp: otp }})
        }).catch((err) => {
            message.error({
                content: err?.response?.data?.message,
                duration: 5,
                className: 'font-sans'
            });
            setLoading(false);
        })
    }
    return (
        <div className='Login'>
            <div style={{ width: '1408px', height: '750px' }} className='Login__Card'>
                <div className='Login__Card__LeftSection'>
                    <div className='Login__Card__LeftSection__FirstRow'>
                        <img src={logo} alt='logo' />
                    </div>
                    {!sendOtp ? (
                        <div className='Login__Card__LeftSection__SecondRow'>
                            <Title level={3}>Forgot Password</Title>
                            <div className='Login__Card__LeftSection__SecondRow__InputFields'>
                                <InputBox className='Email-container' label={CONSTANTS.EMAIL_LABEL} variant='filled' value={userCredentials?.email} placeholder={CONSTANTS.EMAIL_PLACEHOLDER} onChange={(event) => setUserCredentials({ ...userCredentials, email: event?.target?.value })} />
                                {/*<InputBox className='Password-Container' type='password' label={CONSTANTS.PASSWORD_LABEL} variant='filled' value={userCredentials?.password} placeholder={CONSTANTS.PASSWORD_PLACEHOLDER} onChange={(event) => setUserCredentials({...userCredentials, password: event?.target?.value})} />*/}
                            </div>
                            <div className='Login__Card__LeftSection__SecondRow__Actions justify-end'>

                                {/*<button onClick={()=>navigate('/forgot-password')} className='Login__Card__LeftSection__SecondRow__Actions__ForgotPwd'>{CONSTANTS.FORGOT_PASSWORD}</button>*/}
                                <CustomButton disabled={userCredentials.email === '' ? true : false} className='Login__Card__LeftSection__SecondRow__Actions__SignIn' onClick={() => handleForgotPassword()} buttonText='Submit' loading={loading} />
                            </div>
                        </div>
                    ) : (
                        <div className='Login__Card__LeftSection__SecondRow'>
                            <Title level={3}>Confirm your email</Title>
                            <div>
                                {`We've sent an email with a code to `}
                                <strong>{userCredentials.email}</strong>
                                {`, please enter it below to create your Mercury account.`}
                            </div>
                            <div className='Login__Card__LeftSection__SecondRow__InputFields'>
                                <Input.OTP variant='filled' size='large' formatter={(input) => input.toUpperCase()} value={otp} onChange={(text) => setOtp(text)} />
                            </div>
                            {/*<div className='Otp__Container__CodeNotReceived'>Didn't receive a code?{' '}<span className='Otp__Container__CodeNotReceived__SendCodeAgain'>Send code again.</span></div>*/}
                            <div className='flex justify-between items-center'>
                                <CustomButton className='Login__Card__LeftSection__SecondRow__Actions__SignIn' onClick={() => setSendOtp(false)} buttonText='Back' />
                                <CustomButton className='Login__Card__LeftSection__SecondRow__Actions__SignIn' onClick={() => handleVerifyOtp()} buttonText='Next' loading={loading} />
                            </div>
                        </div>
                    )}
                    <div className='Login__Card__LeftSection__ThirdRow'>
                        {CONSTANTS.NO_ACCOUNT} <Link to='/registration' className='Login__Card__LeftSection__ThirdRow__GetStarted'>{CONSTANTS.STARTED_WITH_MERCURY}</Link>
                    </div>
                </div>
                <div className='Login__Card__RightSection'>
                    <img className='Login__Card__RightSection__Image' src={freelancer} alt='freelancer' />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword