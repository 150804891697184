import React, { useEffect, useState } from 'react'
import CustomModal from '../../../components/CustomModal';
import CustomButton from '../../../components/CustomButton';
import { Checkbox } from 'antd';

interface customMessageModalProps {
    isProposalModal: boolean;
    setProposalModal: (val: boolean) => void;
    onSendContract: () => void;
}


const SendContractModal = ({ isProposalModal, setProposalModal,  onSendContract }: customMessageModalProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(()=>{
        setIsChecked(false)
    },[isProposalModal])
    const onModalClose = () => {
        setProposalModal(false)
    }

    const onSend = () => {
        onSendContract()
        setProposalModal(false)
    }


    return (
        <CustomModal isOpen={isProposalModal} onCancel={() => onModalClose()} closable={false} width={'400px'} footer={[
            <CustomButton
                className='px-5 py-2 rounded-full'
                key='back'
                onClick={onModalClose}
                buttonText="Close"
            />,
            <CustomButton
                className='px-5 py-2 rounded-full'
                key='back'
                disabled={!isChecked}
                onClick={()=> onSend()}
                buttonText="Send"
            />,
        ]}>
            <div>   
            <p className='text-xl font-medium  font-sans'>I have read the contract and I'm accepting all terms and condition written.</p>
            <div className='mt-3 '>
            <Checkbox className='text-base italic' checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>I agree to the terms and conditions</Checkbox>
            </div>
            </div>

        </CustomModal>
    )
}

export default SendContractModal