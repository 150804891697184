import React, { useEffect, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import CONSTANTS from './constants';
import './UploadProfilePicture.scss'
import ProfilePicUploader from '../Registration/Common/ProfilePicUploader';
import { Link, useNavigate } from 'react-router-dom';
import { postProfilePicture } from '../../services/authService';


const UploadProfilePicture = () => {

    const navigate = useNavigate();

    const { TITLES, NEXT, ALREADY_HAVE_ACCOUNT, GOTO_DASHBOARD } = CONSTANTS;
    const [currentScreen, setCurrentScreen] = useState<string>('registrationChoice');
    const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const saveProfilePicture = async() => {
        if(currentScreenIndex === 1 && currentScreen === 'profilePicUploader') {
            const resp = await postProfilePicture({profileImageUrl: imageUrl });
            if(resp?.data?.statusCode === 201 || resp?.data?.statusCode === 200) {
                setLoading(() => false);
                navigate('/verify-identity', {replace: true});
            } else {
                setIsError(true);
                setLoading(() => false);
                setCurrentScreenIndex(currentScreenIndex - 1);
            }
        } else {
            setIsError(true);
            setLoading(() => false);
            setCurrentScreen((prev) => 'profilePicUploader');
        }
    }

    useEffect(() => {
        switch(currentScreenIndex) {
            case 0:
                setCurrentScreen('profilePicUploader');
                break;
            case 1:
                setLoading(() => true);
                if(imageUrl) {
                    setIsError(() => false);
                    saveProfilePicture();
                } else {
                    setIsError(true);
                    setLoading(() => false);
                    setCurrentScreenIndex(currentScreenIndex - 1);
                }
                break;
            default:
                setCurrentScreen('profilePicUploader');
        }

    }, [currentScreenIndex]);

    return (
        <>
            <div className='Registration__Card__Body__MainContent'>
                <div className='Registration__Card__Body__MainContent__SubHeader'>
                    {currentScreen === 'profilePicUploader' && <>{TITLES.UPLOAD_PROFILE_TITLE}</>}
                </div>
                <div className='Registration__Card__Body__MainContent__Body'>
                    {currentScreen === 'profilePicUploader' && (<ProfilePicUploader isError={isError} setImageUrl={setImageUrl} />)}
                </div>
                <div className='Registration__Card__Body__MainContent__Footer'>
                    <CustomButton className='Registration__Card__Body__MainContent__Footer__NextBtn' onClick={(event) => setCurrentScreenIndex(currentScreenIndex + 1)} buttonText={NEXT} loading={loading} />
                </div>
            </div>
            <div className='Registration__Card__Body__Footer'>{ALREADY_HAVE_ACCOUNT} <Link to={'/login'} className='Registration__Card__Body__Footer__GotoDashboard'>{GOTO_DASHBOARD}</Link></div>
        </>
    );
}

export default UploadProfilePicture;