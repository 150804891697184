import React, { useState } from 'react'
import CustomModal from '../CustomModal'
import CustomButton from '../CustomButton'
import { Button, Input, Select, Spin, Steps } from 'antd';
import InputBox from '../InputBox';
import dollar from '../../assets/dollar.svg';
import { FieldArray, useFormik, FormikProvider, FormikErrors } from 'formik';
import * as Yup from 'yup';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';
import { addProposal } from '../../services/freelancerService';
import { ProposalListProps } from '.';
interface sentProposalModalProps {
    isProposalModal: boolean;
    setProposalModal: (val: boolean) => void;
    jobId: string;
    onSuccess: (val: any)=> void,
    proposalData: ProposalListProps | null
}
interface Milestone {
    milestoneDisc: string;
    milestoneAmount: number | undefined;
    milestoneDuration: number | undefined;
    milestoneDurationType: string;
}


interface FormValues {
    title: string;
    milestones: Milestone[];
    description: string;
    additionalCost: number| undefined;
    additionalCostEnabled: boolean;
    costDescription: string
}

const SendProposal = ({ isProposalModal, setProposalModal, jobId ,onSuccess, proposalData}: sentProposalModalProps) => {
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [additionalCost, setAdditionalCost] = useState<boolean>(false)

    const modalClose = () => {
        formik.resetForm()
        setCurrentStep(0)
        setProposalModal(false)
        setAdditionalCost(false)
    }

    const addProposals = (values: FormValues) => {
        setIsLoading(true)
        const data: {
            jobId: string;
            title: string;
            description: string;
            mileStone: { description: string; amount: number; proposalDuration: number; proposalDurationType: string }[];
            additionalCost?: { amount: number; description: string }[];
        } = {
            jobId: jobId,
            title: values.title,
            description: values.description,
            mileStone: values.milestones.map(milestone => ({
                description: milestone.milestoneDisc,
                amount: milestone.milestoneAmount !== undefined ? parseFloat(milestone.milestoneAmount.toString()) : 0,
                proposalDuration: milestone.milestoneDuration !== undefined ? parseFloat(milestone.milestoneDuration.toString()) : 0,
                proposalDurationType: milestone.milestoneDurationType
            }))
        };
    
        if (values.additionalCostEnabled && values.additionalCost !== undefined) {
            data.additionalCost = [{
                amount: parseFloat(values.additionalCost.toString()),
                description: values.costDescription || ''
            }];
        }
        addProposal(data).then((res) => {
            onSuccess(res?.data?.data)
            setIsLoading(false)
            modalClose()

        }).catch((err) => {
            setIsLoading(false)
            modalClose()
        })
    }
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is a required field.'),
        milestones: Yup.array().of(
            Yup.object({
                milestoneDisc: Yup.string().required('Description is required.'),
                milestoneAmount: Yup.number()
                    .typeError('Milestone amount must be a number')
                    .required('Milestone amount is required')
                    .min(1, 'Additional cost must be a positive number'), 
                milestoneDuration: Yup.number()
                    .typeError('Duration must be a number')
                    .required('Duration is required')
                    .min(1, 'Duration must be at least 1'),
                milestoneDurationType: Yup.string()
                    .required('Duration type is required')
            })
        ),
        description: Yup.string().required('Description is required'),
        additionalCost: Yup.number()
            .typeError('Additional cost amount must be a number')
            .min(1, 'Additional cost must be a positive number') 
            .when('additionalCostEnabled', {
                is: true, 
                then: (schema) => schema.required('Additional cost is required'),
                otherwise: (schema) => schema.notRequired(),
            }),
            costDescription: Yup.string().when('additionalCostEnabled', {
                is: true, 
                then: (schema) => schema.required('Additional cost description is required'),
                otherwise: (schema) => schema.notRequired(),
            }),
    });

    const formik = useFormik<FormValues>({
        enableReinitialize: true,
        validationSchema,
        initialValues: {
            title: proposalData ? proposalData.title : '',
            milestones: proposalData 
            ? proposalData.mileStone.map(ms => ({ 
                milestoneDisc: ms.description, 
                milestoneAmount: ms.amount ,
                milestoneDuration: ms.proposalDuration,
                milestoneDurationType: ms.proposalDurationType ? ms.proposalDurationType : 'hours'
            }))
            : [{ milestoneDisc: '', milestoneAmount: undefined, milestoneDuration: undefined, milestoneDurationType: 'hours' }],
            description: proposalData ? proposalData.description : '',
            additionalCost: proposalData?.additionalCost?.length 
            ? proposalData.additionalCost[0].amount 
            : undefined,
            additionalCostEnabled: proposalData?.additionalCost?.length ? true : false,
            costDescription: proposalData?.additionalCost 
            ? proposalData.additionalCost[0]?.description || '' 
            : ''
        },
        onSubmit: (values) => {
            addProposals(values)
        }
    });

    const isValidStep0 = formik.values.title !== '' && 
    formik.values.milestones.every((milestone: Milestone, index: number) =>
        milestone.milestoneDisc !== undefined &&
        milestone.milestoneAmount !== undefined &&
        milestone.milestoneDuration !== undefined && 
        milestone.milestoneDurationType !== undefined && 
        !(formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDisc &&
        !(formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneAmount &&
        !(formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDuration && 
        !(formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDurationType 
    ) &&
    (!formik.values.additionalCostEnabled || (
        formik.values.additionalCost !== undefined &&
        formik.values.costDescription !== ''
    ));

    const isValidStep1 = formik.values.description !== '';
    return (
        <div className='font-sans'>
            <CustomModal isOpen={isProposalModal} onCancel={() => modalClose()} closable={false} width={'700px'} footer={[
                <CustomButton
                    className='px-5 py-2 rounded-full'
                    key='back'
                    onClick={() => {
                        if (currentStep === 0) {
                            modalClose()
                        } else {
                            setCurrentStep(currentStep - 1);
                        }
                    }}
                    buttonText={currentStep === 0 ? "Cancel" : "Back"}
                />,
                <CustomButton
                    className='px-5 py-2 rounded-full'
                    key={currentStep === 2 ? 'Send' : 'Next'}
                    disabled={
                        (currentStep === 0 && !isValidStep0) ||
                        (currentStep === 1 && !isValidStep1)
                    }
                    onClick={() => {
                        if (currentStep === 0 && isValidStep0) {
                            setCurrentStep(1);
                        } else if (currentStep === 1 && isValidStep1) {
                            setCurrentStep(2);
                        } else {
                            formik.handleSubmit();
                        }
                    }}
                    buttonText={currentStep === 2 ? 'Send' : 'Next'}
                    loading={false} />
            ]}>
                <div>
                    <Spin spinning={isLoading} tip="Loading..">
                        <FormikProvider value={formik}>
                            <div>
                                <div className='w-full flex justify-center'>
                                    <Steps
                                        className='w-4/5 d-flex justify-end'
                                        size="small"
                                        current={currentStep}
                                        progressDot
                                        labelPlacement='vertical'
                                        items={[
                                            {
                                                title: 'Overview',
                                            },
                                            {
                                                title: 'Deliverables',
                                            },
                                            {
                                                title: 'Review',
                                            },
                                        ]}
                                    />
                                </div>
                                {currentStep === 0 && (
                                    <div className='mt-3'>
                                        <div >
                                            <p className='text-2xl text-black font-bold font-sans'>{proposalData ? "Edit Proposal":"Create proposal"}</p>
                                        </div>
                                        <div className='mt-2'>
                                            <Title className='Input__Container__Label' level={5}>Title</Title>
                                            <Input
                                                className='Input__Container__InputBox !h-[40px]'
                                                name='title'
                                                id='title'
                                                value={formik.values.title}
                                                variant='filled'
                                                placeholder='Title'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                status={
                                                    formik.touched.title && formik.errors.title
                                                        ? 'error' : ''
                                                }
                                            />
                                            <p className='text-xs text-error '>{formik.touched.title &&
                                                formik.errors.title ? formik.errors.title : ''}
                                            </p>
                                        </div>
                                        <FieldArray name='milestones'>
                                            {({ push, remove }) => (
                                                <div className='mt-2'>
                                                    {formik.values.milestones.map((milestone: Milestone, index: number) => (
                                                        <div key={index} className='mb-3'>
                                                            <div>
                                                                <Title className='Input__Container__Label' level={5}>{`Milestone ${index + 1}`}</Title>
                                                                <Input
                                                                    className='Input__Container__InputBox !h-[40px]'
                                                                    type='text'
                                                                    id={`milestones.${index}.milestoneDisc`}
                                                                    name={`milestones.${index}.milestoneDisc`}
                                                                    value={milestone.milestoneDisc}
                                                                    variant='filled'
                                                                    placeholder='Description'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    status={
                                                                        formik.touched.milestones?.[index]?.milestoneDisc &&
                                                                            (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDisc
                                                                            ? 'error'
                                                                            : ''
                                                                    }
                                                                />
                                                                <p className='text-xs text-error '>{formik.touched.milestones?.[index]?.milestoneDisc &&
                                                                    (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDisc ? (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDisc : ''}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <Input
                                                                    className='Input__Container__InputBox !h-[40px] mt-1'
                                                                    id={`milestones.${index}.milestoneAmount`}
                                                                    type='number'
                                                                    name={`milestones.${index}.milestoneAmount`}
                                                                    value={milestone.milestoneAmount}
                                                                    variant='filled'
                                                                    placeholder='Amount in USD'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    status={
                                                                        formik.touched.milestones?.[index]?.milestoneAmount &&
                                                                            (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneAmount
                                                                            ? 'error'
                                                                            : ''
                                                                    }
                                                                />
                                                                <p className='text-xs text-error '>{formik.touched.milestones?.[index]?.milestoneAmount &&
                                                                    (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneAmount ? (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneAmount : ''}
                                                                </p>
                                                            </div>
                                                            <div className='grid grid-cols-3 gap-3'>
                                                            <div className='col-span-2'>
                                                                <Input
                                                                    className='Input__Container__InputBox !h-[40px] mt-1'
                                                                    id={`milestones.${index}.milestoneDuration`}
                                                                    type='number'
                                                                    name={`milestones.${index}.milestoneDuration`}
                                                                    value={milestone.milestoneDuration}
                                                                    variant='filled'
                                                                    placeholder='Proposal duration'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    status={
                                                                        formik.touched.milestones?.[index]?.milestoneDuration &&
                                                                            (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDuration
                                                                            ? 'error'
                                                                            : ''
                                                                    }
                                                                />
                                                                <p className='text-xs text-error '>
                                                                    {formik.touched.milestones?.[index]?.milestoneDuration &&
                                                                    (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDuration ? (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDuration : ''}
                                                                </p>
                                                            </div>
                                                                <div>
                                                                    <Select
                                                                        className='Input__Container__InputBox !h-[40px] mt-1 !w-full'
                                                                        id={`milestones.${index}.milestoneDurationType`}
                                                                        placeholder="Proposal type"
                                                                        value={formik.values.milestones[index].milestoneDurationType} 
                                                                        variant='filled'
                                                                        onChange={(value) => formik.setFieldValue(`milestones.${index}.milestoneDurationType`, value)} // Corrected onChange
                                                                        onBlur={() => formik.setFieldTouched(`milestones.${index}.milestoneDurationType`, true)} // Ensures blur event is properly track
                                                                        options={[
                                                                            { value: "hours", label: "Hours" },
                                                                            { value: "days", label: "Days" },
                                                                            { value: "weeks", label: "Weeks" },
                                                                            { value: "months", label: "Months" }
                                                                        ]}
                                                                        defaultValue={'hours'}
                                                                        status={
                                                                            formik.touched.milestones?.[index]?.milestoneDurationType &&
                                                                                (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDurationType
                                                                                ? 'error'
                                                                                : ''
                                                                        }
                                                                    />
                                                                     <p className='text-xs text-error '>
                                                                    {formik.touched.milestones?.[index]?.milestoneDurationType &&
                                                                    (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDurationType ? (formik.errors.milestones?.[index] as FormikErrors<Milestone>)?.milestoneDurationType : ''}
                                                                </p>
                                                                </div>
                                                            </div>
                                                            {index > 0 && (
                                                                <Button
                                                                    className='mt-2 text-red-500'
                                                                    onClick={() => remove(index)}
                                                                    disabled={formik.values.milestones.length === 1}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            )}
                                                        </div>
                                                    ))}
                                                    <Button
                                                        className='text-base border-2 border-black font-semibold mt-2'
                                                        onClick={() => push({ milestoneDisc: '', milestoneAmount: '', milestoneDuration: '', milestoneDurationType: 'hours'   })}
                                                    >
                                                        Add milestone +
                                                    </Button>
                                                </div>
                                            )}
                                        </FieldArray>
                                        <Button
                                            className='text-base border-2 border-black font-semibold mt-2'
                                            onClick={() => {
                                                setAdditionalCost(!additionalCost);
                                                formik.setFieldValue("additionalCostEnabled", !additionalCost);
                                            }}
                                        >
                                            Additional cost
                                        </Button>
                                        {formik.values.additionalCostEnabled && (
                                            <div>
                                                <div className='mt-1'>
                                                    <Title className='Input__Container__Label' level={5}>Additional cost</Title>
                                                    <Input
                                                        className='Input__Container__InputBox !h-[40px]'
                                                        id='additionalCost'
                                                        type='number'
                                                        name='additionalCost'
                                                        value={formik.values.additionalCost}
                                                        variant='filled'
                                                        placeholder='Additional cost in USD'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        status={
                                                            formik.touched.additionalCost &&
                                                                formik.errors.additionalCost
                                                                ? 'error'
                                                                : ''
                                                        }
                                                    />
                                                    <p className='text-xs text-error '>
                                                        {formik.touched.additionalCost && formik.errors.additionalCost ? formik.errors.additionalCost : ''}
                                                    </p>
                                                </div>
                                                <div className='mt-2'>
                                                    <Input
                                                        className='Input__Container__InputBox !h-[40px]'
                                                        id='costDescription'
                                                        type='text'
                                                        name='costDescription'
                                                        value={formik.values.costDescription}
                                                        variant='filled'
                                                        placeholder='Additional cost description'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        status={
                                                            formik.touched.costDescription &&
                                                                formik.errors.costDescription
                                                                ? 'error'
                                                                : ''
                                                        }
                                                    />
                                                    <p className='text-xs text-error '>
                                                        {formik.touched.costDescription && formik.errors.costDescription ? formik.errors.costDescription : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {currentStep === 1 && (
                                    <div className='mt-3'>
                                        <div>
                                            <p className='text-2xl text-black font-bold font-sans'>Deliverables</p>
                                        </div>
                                        <div className='mt-2'>
                                            <Title className='Input__Container__Label' level={5}>Description</Title>
                                            <TextArea className='Input__Container__InputBox' rows={4} placeholder="Add details" name='description' id='description' value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        </div>
                                    </div>
                                )}
                                {currentStep === 2 && (
                                    <div className='mt-3'>
                                        <div>
                                            <p className='text-2xl text-black font-bold font-sans'>Review proposal</p>
                                        </div>
                                        <div className='mt-2'>
                                            <p className='font-semibold text-base'>Title : <span>{formik.values.title}</span></p>
                                            {formik.values.milestones.map((milestone: Milestone, index: number) => {
                                                return (
                                                    <p key={index} className='font-semibold text-base'>Milestone {index + 1} : <span>{milestone.milestoneDisc} | {milestone.milestoneAmount} USD | {milestone.milestoneDuration} : {milestone.milestoneDurationType}</span></p>
                                                )
                                            })}
                                            <p className='font-semibold text-base'>Deliverables : <span>{formik.values.description}</span></p>
                                            {formik.values.additionalCostEnabled && (<p className='font-semibold text-base'>Additional cost : <span>{formik.values.additionalCost} USD</span></p>)}
                                            {formik.values.additionalCostEnabled && (<p className='font-semibold text-base'>Additional cost description : <span>{formik.values.costDescription}</span></p>)}

                                        </div>

                                    </div>
                                )}
                            </div>
                        </FormikProvider>
                    </Spin>
                </div>
            </CustomModal>
        </div>
    )
}

export default SendProposal