import React, { useEffect, useState } from 'react'
import { Input, message, Typography } from 'antd';
import freelancer from "../../assets/freelancer.svg";
import logo from "../../assets/logo.svg";
import '../Login/Login.scss'
import CustomButton from '../../components/CustomButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { forgotPasswordApi, loginUser, resetPassword, setupUserPassword, verifyOtp } from '../../services/authService';
import CONSTANTS from '../Login/constans';
import InputBox from '../../components/InputBox';
import { useFormik } from "formik"
import * as Yup from "yup"
import { CircleCheckBig, CircleX } from 'lucide-react';
const { Title } = Typography;
const ResetPassword = () => {
    const location = useLocation()
    const { state } = location
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);


    const handleResetPassword = (values: { password: string, confirmPassword: string }) => {
        setLoading(true)
        resetPassword({ email: state.email, otp: state.otp, password: values.password }).then((res) => {
            setLoading(false)
            message.success({
                content: res?.data?.message,
                duration: 5,
                className: 'font-sans'
            });
            navigate('/login')
        }).catch((err) => {
            message.error({
                content: err?.response?.data?.message,
                duration: 5,
                className: 'font-sans'
            });
            setLoading(false)
        })
    }
    const validationSchema = Yup.object({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            //.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            //.matches(/[0-9]/, 'Password must contain at least one number')
            //.matches(/[@$!%*?&]/, 'Password must contain at least one special character')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password")], "Passwords must match")
            .required("Confirm password is required"),
    });


    const formik = useFormik({
        enableReinitialize: true,
        validationSchema,
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        onSubmit: (values) => {
            handleResetPassword(values)
        },
    });

    const password = formik.values.password;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);
    const hasMinLength = password.length >= 8;

    const renderIcon = (isValid: boolean) =>
        isValid ? <CircleCheckBig size={18} style={{ color: 'green' }} /> :
            <CircleX size={18} style={{ color: 'red' }} />;

    return (
        <div className='Login'>
            <div style={{ width: '1408px', height: '750px' }} className='Login__Card'>
                <div className='Login__Card__LeftSection'>
                    <div className='Login__Card__LeftSection__FirstRow'>
                        <img src={logo} alt='logo' />
                    </div>
                    <div className='Login__Card__LeftSection__SecondRow'>
                        <Title level={3}>Forgot Password</Title>
                        <div className='Login__Card__LeftSection__SecondRow__InputFields'>
                            <InputBox
                                className='Password-Container'
                                type='password'
                                name='password'
                                id='password'
                                label={CONSTANTS.PASSWORD_LABEL}
                                variant='filled'
                                value={formik.values.password}
                                placeholder={CONSTANTS.PASSWORD_PLACEHOLDER}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                formikError={
                                    formik.touched.password && formik.errors.password
                                        ? formik.errors.password
                                        : ""
                                }
                            />
                            <div>
                                {formik.values.password.length && (!hasUpperCase || !hasNumber || !hasSpecialChar || !hasMinLength) ? (
                                    <div className="my-2">
                                        <ul className="flex items-start flex-col gap-1">
                                            <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                                                <span className="transition-all duration-300 text-red-500 scale-90">
                                                    {renderIcon(hasUpperCase)}
                                                </span>
                                                Contains at least one uppercase letter
                                            </li>
                                            <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                                                <span className="transition-all duration-300 text-red-500 scale-90">
                                                    {renderIcon(hasNumber)}
                                                </span>
                                                Contains at least one number
                                            </li>
                                            <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                                                <span className="transition-all duration-300 text-red-500 scale-90">
                                                    {renderIcon(hasSpecialChar)}
                                                </span>
                                                Contains at least one special character (@$!%*?&)
                                            </li>
                                            <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                                                <span className="transition-all duration-300 text-red-500 scale-90">
                                                    {renderIcon(hasMinLength)}
                                                </span>
                                                Minimum of 8 characters
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                            <InputBox
                                className='Password-Container'
                                type='password'
                                name='confirmPassword'
                                id='confirmPassword'
                                label='Confirm Password'
                                variant='filled'
                                value={formik.values.confirmPassword}
                                placeholder='Type your confirm password'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                formikError={
                                    formik.touched.confirmPassword && formik.errors.confirmPassword
                                        ? formik.errors.confirmPassword
                                        : ""
                                }
                            />
                        </div>
                        <div className='Login__Card__LeftSection__SecondRow__Actions justify-end'>
                            <CustomButton disabled={!(formik.isValid && formik.dirty)} className='Login__Card__LeftSection__SecondRow__Actions__SignIn' onClick={() => formik.handleSubmit()} buttonText='Submit' loading={loading} />
                        </div>
                    </div>
                    <div className='Login__Card__LeftSection__ThirdRow'>
                        {CONSTANTS.NO_ACCOUNT} <Link to='/registration' className='Login__Card__LeftSection__ThirdRow__GetStarted'>{CONSTANTS.STARTED_WITH_MERCURY}</Link>
                    </div>
                </div>
                <div className='Login__Card__RightSection'>
                    <img className='Login__Card__RightSection__Image' src={freelancer} alt='freelancer' />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword