import React, { useEffect, useState } from 'react';
import { Typography, Upload } from 'antd';
import './VerifyIdentity.scss';
import CONSTANTS from './constants';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import identity from '../../assets/identity.svg';
import { saveVerifyDocument, uploadDocument } from '../../services/authService';
import type { GetProp, UploadProps } from 'antd';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useAuth } from '../../contexts/AuthContext';
import { setCredentials } from '../../slice/authSlice';
import { setAgoraCredentials } from '../../slice/agoraSlice';
import { initializeAgoraChat } from '../../utils/agoraChat';

const { Title, Text } = Typography;
const VerifyIdentity = () => {
  const { saveToken, saveChatToken, saveLoggedInUser } = useAuth();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const userInfo = JSON.parse(localStorage.getItem('userDetails') || '{}');

  const saveProfilePicture = async (url: string) => {
    const resp = await saveVerifyDocument({ type: 'document', fileUrl: url });
    if (resp?.data?.statusCode === 201 || resp?.data?.statusCode === 200) {
      const chatToken = resp?.data?.data?.chatToken;
      const agoraUserName = resp?.data?.data?.user?.agoraUserName;

      setLoading(false);
      if (userInfo?.role === 'client') {
        navigate('/interested-services', {
          state: {
            response: JSON.parse(JSON.stringify(resp?.data?.data)) // Ensures it's serializable
          }
        });
      } else {
        saveToken(resp?.data?.data?.accessToken);
        saveChatToken(resp?.data?.data?.chatToken)
        dispatch(setCredentials({ user: { ...resp?.data?.data?.user, ...resp?.data?.data?.freelancer, ...resp?.data?.data?.client } }));
        saveLoggedInUser({ ...resp?.data?.data?.user, ...resp?.data?.data?.freelancer, ...resp?.data?.data?.client });
        dispatch(setAgoraCredentials({ chatToken, agoraUserName }));
        initializeAgoraChat(agoraUserName, chatToken);
        navigate('/dashboard', { replace: true });
      }
    } else {
        setLoading(() => false);
    }
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    setLoading(() => true);
    if (info?.file?.status === 'done' && info?.file?.response?.statusCode === 200) {
      // Get this url from response in real world.
      const imageUrl = info?.file?.response?.data?.url;
      if (imageUrl) {
        saveProfilePicture(imageUrl);
      } else {
        setLoading(() => false);
      }
    }
    if (info?.file?.status === 'error') {
      setLoading(() => false);
    }
  };

  return (
    <>
      <div className='Registration__Card__Body__MainContent'>
        <div className='Registration__Card__Body__MainContent__SubHeader'>
          <img src={identity} alt='verify-identity' />
        </div>
        <div className='Registration__Card__Body__MainContent__Body'>
          <Title className="Registration__Card__Body__MainContent__Body__VerifyIdentity__Title" level={3}>{CONSTANTS.VERIFY_IDENTITY_TITLE}</Title>
          <Text className="Registration__Card__Body__MainContent__Body__VerifyIdentity__Text">{CONSTANTS.VERIFY_IDENTITY_DESCRIPTION}</Text>
        </div>
        <div className='Registration__Card__Body__MainContent__Footer'>
          <Upload
            name='file'
            multiple={false}
            maxCount={1}
            action="https://dev-api.mercurygigs.com/common/file/upload"
            onChange={handleChange}
            headers={{ 'Authorization': userInfo?.accessToken || null }}
            data={{ type: 'documents' }}
            showUploadList={false}
          >
            <CustomButton buttonText={CONSTANTS.ID} onClick={() => { }} loading={loading} />
          </Upload>
        </div>
      </div>
      <div className='Registration__Card__Body__Footer'>{CONSTANTS.ALREADY_HAVE_ACCOUNT} <Link to={'/login'} className='Registration__Card__Body__Footer__GotoDashboard'>{CONSTANTS.GOTO_DASHBOARD}</Link></div>
    </>
  );
};

export default VerifyIdentity;