import React, { useState } from 'react';
import { Typography } from 'antd';
import './ProjectsList.scss';
import CustomButton from '../../components/CustomButton';
import ProjectCard from '../../components/ProjectCard';

const { Title, Text } = Typography;

type ProjectProp = {
    id: string
    title: string
    imageUrl: string
}

interface IProjectsListProps {
    projects?: ProjectProp[]
    onBackBtnClick: () => void
    onLinkBtnClick: (selectedProjectsList: string[]) => void
}

const ProjectsList = ({projects, onBackBtnClick = () => {}, onLinkBtnClick} : IProjectsListProps) => {
    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

    const handleOnProjectSelection = (projectId: string) => {
        setSelectedProjects((prevList) => {
            const newArray = [...prevList];
            if(newArray?.includes(projectId)) {
                return newArray?.filter(item => item !== projectId);
            } else if(newArray?.length < 3) {
                newArray.push(projectId);
                return newArray;
            } else {
                return newArray;
            }
        });
    };

    return (
        <div className='ProjectsListContainer'>
            <Title className='ProjectsListContainer__Header' level={5}>Select projects to link as examples</Title>
            <Text className='ProjectsListContainer__SubHeader'>Choose projects</Text>
            <div className='ProjectsListContainer__Body'>
                {projects?.map(({id, title, imageUrl}: ProjectProp) => (
                    <ProjectCard key={id} id={id} title={title} imageUrl={imageUrl} isSelected={selectedProjects.includes(id)} handleProjectClick={(projectId: string) => handleOnProjectSelection(projectId)} />
                ))}
            </div>
            <Text className='ProjectsListContainer__ProjectsCount'>{selectedProjects?.length}/3 selected</Text>
            <div className='ProjectsListContainer__FooterActions'>
                <CustomButton buttonText='Back' onClick={onBackBtnClick} />
                <CustomButton disabled={!selectedProjects?.length} buttonText='Link Selected Projects' onClick={() => onLinkBtnClick(selectedProjects)} />
            </div>
        </div>
    );
};

export default ProjectsList;