import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Segmented, Spin } from 'antd';
import chat from '../../assets/chat.svg';
import notifications from '../../assets/notifications.svg';
import './ClientProjects.scss';
import CustomButton from '../../components/CustomButton';
import InputBox from '../../components/InputBox';
import { getClientPostedJobs, getJobsList, jobApply } from '../../services/jobService';
import JobDetails from '../../components/JobDetails';
import { useNavigate } from 'react-router-dom';
import JobPostModalBody from '../Dashboard/Profile/JobPostModalBody';
import InfiniteScroll from 'react-infinite-scroller';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setSelectedJob } from '../../slice/freelancerSlice';
import JobOverview from '../JobOverview';

const ClientProjects = () => {
    const [addNewJobModal, setAddNewJobModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const [jobsList, setJobsList] = useState<any[]>([]);
    const [lastJobKey, setLastJobKey] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const dataFetchedRef = useRef(false);
    const dispatch = useAppDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showOverviewScreen, setShowOverviewScreen] = useState(false);

    const fetchProjects = async (lastJobId: string) => {
        try {
            const response = await getClientPostedJobs({ limit: 10, lastKey: lastJobId ?? '' });
            if (response?.data?.statusCode === 200) {
                const newJobs = response?.data?.data?.jobs;
                setJobsList(prevJobs => [...prevJobs, ...newJobs]);
                setLastJobKey(response?.data?.data?.lastKey || null);
                setHasMore(!!response?.data?.data?.lastKey);
            } else {
                setJobsList([]);
                setHasMore(false);
            }
        } catch {
            setHasMore(false);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchProjects("");
    }, []);

    const newJobAdded = () => {
        setLastJobKey(null);
        setJobsList([]);
        setHasMore(true)
        setTimeout(() => {
            fetchProjects('');
        }, 1000)
    };

    const onDrawerClose = () => {
        setOpenDrawer(false);
    };
    
    

    const handleOnViewApplication = (jobId: string) => {
        navigate(`/client-projects/${jobId}`);
    }

     const handleOnJobClick = (job: any) => {
           dispatch(setSelectedJob({ job }));
           setShowOverviewScreen(() => true);
           setOpenDrawer(() => true);
       }

    return (
        <div className='ClientProjectsContainer'>
            <div className='ClientProjectsContainer__Header'>
                <div className='ClientProjectsContainer__Header__Title'>My Jobs</div>
                <div className='ClientProjectsContainer__Header__Actions'>
                    <CustomButton buttonText='Add Job' onClick={() => setAddNewJobModal(true)} />
                    <CustomButton buttonText='Upgrade' onClick={() => {}} />
                    <img style={{ cursor: 'pointer' }} src={chat} alt='chat' onClick={() => {navigate('/chat')}} />
                    <img style={{ cursor: 'pointer' }} src={notifications} alt='notifications' />
                </div>
            </div>
            <div className='ClientProjectsContainer__Body'>
            <InfiniteScroll
                        pageStart={0}
                        loadMore={() => {if(lastJobKey){fetchProjects(lastJobKey ?? '')}}}
                        hasMore={hasMore}
                        loader={<div className='w-full flex justify-center my-2'><Spin/></div>}
                        useWindow={false}
                    >
                {jobsList?.map((job: any) => (
                    <JobDetails 
                        userRole={'client'}
                        jobId={job?.jobId}
                        clientName={`${job?.client?.firstName} ${job?.client?.lastName}`}
                        posterImageUrl={job?.client?.profileImageUrl}
                        jobDurationLabel={job?.jobDurationLabel}
                        hiringType={job?.jobType}
                        jobTitle={job?.jobTitle}
                        minBudget={job?.jobType === 'onetime' ? job?.minBudget : job?.minHourlyRate}
                        maxBudget={job?.jobType === 'onetime' ? job?.maxBudget : job?.maxHourlyRate}
                        jobType={job?.jobType}
                        duration={job?.maxHoursPerWeek}
                        postedDate={job?.createdAt}
                        skills={job?.skills}
                        key={job?.jobId}
                        showClientImage={false}
                        showClientName={false}
                        showViewApplicationsButton={job?.jobState !== 'closed'}
                        applicationsCount={job?.appliedCount?.toString()}
                        onViewApplicationsClick={(jobId) => handleOnViewApplication(jobId)}
                        onClick={() => handleOnJobClick(job)}
                        
                    />
                ))}
                </InfiniteScroll>
            </div>
            <JobPostModalBody openJobPostModal={addNewJobModal} setAddNewJob={setAddNewJobModal} onSuccess={() => newJobAdded()}/>
            <Drawer
                closable={false}
                onClose={onDrawerClose}
                open={openDrawer}
                size={'large'}
            >
                 <JobOverview onApply={() => setShowOverviewScreen(false)} />
            </Drawer>
        </div>
    );
};

export default ClientProjects;