import React from 'react';
import { Button,} from 'antd';
import './CustomButton.scss'

interface ButtonProps {
    className?: string
    buttonText?: string
    type?: 'primary'|'dashed'|'link'|'text'|'default'
    disabled?: boolean
    shape?: 'default'|'circle'|'round'
    size?: 'large'|'middle'|'small'
    loading?: boolean
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const CustomButton = ({
    className = '',
    buttonText = '',
    type = 'primary',
    disabled = false,
    shape = 'default',
    size = 'middle',
    loading = false,
    onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {}
}: ButtonProps) => {
    return (
       <Button className={`${className} CustomButton`} type={type} disabled={disabled} shape={shape} size={size} loading={loading} onClick={onClick}>{buttonText}</Button>
    );
}

export default CustomButton;