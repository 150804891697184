import React from 'react';
import { Input } from 'antd';
import type { GetProps } from 'antd';
import './OtpScreen.scss'
import CONSTANTS from './constants';

type OTPProps = GetProps<typeof Input.OTP> & {
    email: string;
};

const OtpScreen = ({value, onChange, email}: OTPProps) => {
    return (
        <div className='Otp__Container'>
            <div className='Otp__Container__Title'>
                {CONSTANTS.OTP_SCREEN_TITLE_FIRST} {email}, {CONSTANTS.OTP_SCREEN_TITLE_LAST}
            </div>
            <Input.OTP variant='filled' size='large' formatter={(input) => input.toUpperCase()} value={value} onChange={onChange}/>
            <div className='Otp__Container__CodeNotReceived'>{CONSTANTS.OTP_NOT_RECEIVED} <span className='Otp__Container__CodeNotReceived__SendCodeAgain'>{CONSTANTS.SEND_CODE_AGAIN}</span></div>
        </div>
    );
}

export default OtpScreen;