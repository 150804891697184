/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import "./chat.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
    conn,
    getMessageById,
    getRoomHistoryMessages,
    initializeAgoraChat,
    joinChatRoom,
    sendRoomMessage
} from "../../utils/agoraChat"
import {
    getConversationsList,
    getDropdownJobList,
    getProposalCheck
} from "../../services/jobService"

import notifications from "../../assets/notifications.svg"
import SendProposal from "./sendProposal"
import { Dropdown, Menu, Button, Select, Divider, Badge, Spin } from "antd"
import { CheckCircleOutlined, DownOutlined, FileTextOutlined, LoadingOutlined } from "@ant-design/icons"
import { useAppSelector } from "../../hooks/reduxHooks"
import moment from 'moment'
import { RootState } from '../../store'
import CustomMessageModal from './customMessageModal'
import { Handshake } from 'lucide-react'

interface Message {
    id: string;
    from: string;
    to: string;
    time: number;
    type?: "txt" | "custom";
    msg?: string;
    customEvent?: string;
    customExts?: {
        title?: string;
        description?: string;
        mileStone?: { description: string; amount: number }[];
        additionalCost?: any[];
        senderName?: string,
        contractId?: string
    };
}

interface User {
    agoraId: string
    agoraUserName: string
    firstName: string
    lastName: string
    professionalTitle: string
    avatar: string
    messages: Message[]
    userId: string
}
interface ChatProps {
    selectedUser: { id: string; name: string; avatar: string }
}

interface Freelancer {
    firstName: string
    lastName: string
    agoraId: string
    agoraUserName: string
    professionalTitle: string
    location: string
    service: string
    isVerified: boolean
    rating: string
}

interface User {
    freelancer: Freelancer // Add this property
    clientId: string
    avatar: string
    messages: Message[]
}

//interface ChatRoomProps {
//    chatRoomId: string
//    chatRoomName: string
//}
interface ChatRoomProps {
    chatRoomId: string;
    hostId: string;
    updatedAt: string;
    chatRoomName: string;
    memberId: string;
    jobId: string;  // Ensure jobId is declared
    createdAt: string;
    chatId: string;
}

export interface ProposalListProps {
    freelancerId: string;
    proposalId: string;
    jobId: string;
    title: string;
    description: string;
    isDeleted: boolean;
    createdAt: string; 
    updatedAt: string; 
    mileStone: {
        description: string;
        amount: number;
        proposalDuration: number;
        proposalDurationType: string;
    }[];
    additionalCost?: { // Optional property
        description: string;
        amount: number;
    }[];
}


export const ProposalStatus = {
    IN_PROGRESS: "IN_PROGRESS",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",

    // Method to get formatted values for dropdown
    getValues() {
        return [
            { value: this.IN_PROGRESS, label: "In Progress" },
            { value: this.ACCEPTED, label: "Accepted" },
            { value: this.REJECTED, label: "Rejected" }
        ]
    }
}

interface ChatRoom {
    chatRoomId: string
    chatRoomName: string
}

const Chat: React.FC = () => {
    const { pathname } = useLocation()
    const [applicationsList, setApplicationsList] = useState([])
    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    const [searchTerm, setSearchTerm] = useState<string>("") // Search input state
    const [inputMessage, setInputMessage] = useState<string>("")
    const [messages, setMessage] = useState<Message[]>([])
    const [roomData, setRoomData] = useState<ChatRoomProps[]>([])
    const [SelectedRoomData, setSelectedRoomData] = useState<ChatRoomProps | null>(null)
    const [isProposalModalOpen, setIsProposalModalOpen] = useState<boolean>(false)
    const [selectedChatRoom, setSelectedChatRoom] = useState<string | null>(null)
    const [unreadMessages, setUnreadMessages] = useState<{ [key: string]: number }>({});
    const [fetchingLoading, setFetchingLoading] = useState<boolean>(false)
    const [messageModal, setMessageModal] = useState<boolean>(false)
    const [selectedMessage, setSelectedMessage] = useState<Message | null>(null)
    const [proposalList, setProposalList] = useState<ProposalListProps | null>(null)
    const location = useLocation()
    const chatMessagesRef = useRef<HTMLDivElement | null>(null)
    const isChatPage = location.pathname === "/chat"
    const { projectId, agoraId } = location.state || {};
    const { lastSelectedChatRoom } = location.state || ''
    const userInfo = useAppSelector((state: any) => state?.auth?.loggedInUser)
    const agora = useAppSelector((state: any) => state?.agora)
    const navigate = useNavigate();

    useLayoutEffect(() => {
        initializeAgoraChat(agora?.agoraUserName, agora?.chatToken)
    }, [agora])


    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTo({
                top: chatMessagesRef.current.scrollHeight,
                behavior: "smooth"
            })
        }
    }, [messages])

    useEffect(() => {
        setSelectedChatRoom(null);

        if (projectId && roomData.length > 0) {
            const defaultRoom = roomData.find((room: any) => room.jobId === projectId);
            if (defaultRoom) {
                setSelectedRoomData(defaultRoom);
                setSelectedChatRoom(defaultRoom.chatRoomId);
                handleChatRoomSelection(defaultRoom.chatRoomId);
                return;
            }
        }

        if (lastSelectedChatRoom && roomData.length > 0) {
            const lastRoom = roomData.find((room: any) => room.chatRoomId === lastSelectedChatRoom);
            if (lastRoom) {
                setSelectedRoomData(lastRoom);
                setSelectedChatRoom(lastRoom.chatRoomId);
                handleChatRoomSelection(lastRoom.chatRoomId);
                return;
            }
        }

        if (roomData.length > 0) {
            setSelectedRoomData(roomData[0]);
            setSelectedChatRoom(roomData[0].chatRoomId);
            handleChatRoomSelection(roomData[0].chatRoomId);
        }
    }, [projectId, roomData, lastSelectedChatRoom]);

    const fetchConversationsList = async () => {
        setFetchingLoading(true)
        try {
            const res = await getConversationsList();
            const conversations = res?.data?.data || [];
            setApplicationsList(conversations);

            if (conversations.length > 0) {
                const matchedUser = conversations.find((user: any) => user.agoraId === agoraId);
                if (matchedUser) {
                    setSelectedUser(matchedUser);
                } else {
                    setSelectedUser(conversations[0]); // Default to first user if no match
                }
            }
            setFetchingLoading(false)
        } catch (err) {
            setFetchingLoading(false)
        }
    };

    const fetchProposalStatus = async () => {
        const selectedJobId = roomData.find(room => room.chatRoomId === selectedChatRoom)?.jobId || null;
        if (selectedJobId) {
            await getProposalCheck(selectedJobId).then((res: any) => {
                setProposalList(res.data.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        if (selectedChatRoom) {
            fetchProposalStatus()
        }
    }, [selectedChatRoom])

    const fetchChatRooms = async () => {
        try {
            const res = await getDropdownJobList({
                memberId: selectedUser?.userId ?? ""
            })
            setRoomData(res?.data?.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchConversationsList()
    }, [])

    useEffect(() => {
        if (selectedUser) {
            fetchChatRooms()
        }
    }, [selectedUser])

    const handleUserSelection = async (user: User) => {
        setMessage([])
        setSelectedUser(user)
        //const messages = await getHistoryMessages(user.agoraUserName);
        //setMessage(prevMessages => [...prevMessages, ...messages]);
    }

    const handleChatRoomSelection = async (chatRoomId: string) => {
        setMessage([])
        setUnreadMessages((prevUnread) => ({
            ...prevUnread,
            [chatRoomId]: 0,
        }));
        setSelectedChatRoom(chatRoomId)
        const selectedRoom = roomData.find((room: any) => room.chatRoomId === chatRoomId) || null;
        setSelectedRoomData(selectedRoom);
        await joinChatRoom(chatRoomId)

        const roomMessages = await getRoomHistoryMessages(chatRoomId)
        setMessage((prevMessages) => [...prevMessages, ...roomMessages])
    }

    const handleListener = () => {
        conn.listen({
            onConnected: () => console.log("Agora Chat Connected"),
            onDisconnected: () => console.log("Agora Chat Disconnected"),
            onError: (error: any) => console.error("Agora Chat Error:", error),
            onTextMessage: (message: any) => {
                const newMessage = {
                    id: message.id,
                    from: message.from,
                    to: message.to,
                    msg: message.data,
                    time: Number(message.time),
                };

                if (message.to === selectedChatRoom) {
                    setMessage((prevMessages) => [...prevMessages, newMessage]);
                } else {
                    setUnreadMessages((prevUnread) => ({
                        ...prevUnread,
                        [message.to]: (prevUnread[message.to] || 0) + 1,
                    }));
                }
            },
            onCustomMessage: (message: any) => {
                if (message.customEvent === "signContract") {
                    const contractMessage: Message = {
                        id: message.id,
                        from: message.from,
                        to: message.to,
                        customExts: message.customExts,
                        time: Number(message.time),
                        type: "custom",
                        customEvent: "signContract",
                    };

                    if (message.to === selectedChatRoom) {
                        setMessage((prevMessages) => [...prevMessages, contractMessage]);
                    } else {
                        setUnreadMessages((prevUnread) => ({
                            ...prevUnread,
                            [message.to]: (prevUnread[message.to] || 0) + 1,
                        }));
                    }
                }
                if (message.customEvent === "contract") {
                    const contractMessage: Message = {
                        id: message.id,
                        from: message.from,
                        to: message.to,
                        customExts: message.customExts,
                        time: Number(message.time),
                        type: "custom",
                        customEvent: "contract",
                    };

                    if (message.to === selectedChatRoom) {
                        setMessage((prevMessages) => [...prevMessages, contractMessage]);
                    } else {
                        setUnreadMessages((prevUnread) => ({
                            ...prevUnread,
                            [message.to]: (prevUnread[message.to] || 0) + 1,
                        }));
                    }
                }
            }


        })
    }

    useEffect(() => {
        if (conn) {
            handleListener()
        }
    }, [conn, selectedChatRoom])

    const getInitials = (firstName: string, lastName: string) => {
        if (!firstName || !lastName) return ""
        return `${firstName.charAt(0).toUpperCase()}${lastName
            .charAt(0)
            .toUpperCase()}`
    }

    const handleSendMessage = async () => {
        if (!inputMessage.trim()) return

        try {
            if (selectedChatRoom) {
                const res = await sendRoomMessage(selectedChatRoom, inputMessage, 'txt')
                if (res) {
                    const newMessage = {
                        id: res.message.time.toString(),
                        from: agora?.agoraUserName,
                        to: selectedChatRoom,
                        msg: res.message.msg,
                        time: res.message.time
                    }
                    setMessage((prevMessages) => [...prevMessages, newMessage])
                    setInputMessage("")
                }
            }
        } catch (error) {
            console.error("Failed to send message", error)
        }
    }



    const groupMessagesByDate = (messages: Message[]) => {
        const groupedMessages: { [key: string]: Message[] } = {};

        messages.forEach((message) => {
            const messageDate = moment(message.time).format("YYYY-MM-DD");
            const today = moment().format("YYYY-MM-DD");
            const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

            let displayDate = messageDate;

            if (messageDate === today) {
                displayDate = "Today";
            } else if (messageDate === yesterday) {
                displayDate = "Yesterday";
            } else {
                displayDate = moment(message.time).format("DD MMM YYYY");
            }

            if (!groupedMessages[displayDate]) {
                groupedMessages[displayDate] = [];
            }

            groupedMessages[displayDate].push(message);
        });

        return groupedMessages;
    };

    const getLatestProposalMessage = (messages: Message[]): Message | null => {
        let latestProposal: Message | null = null;
        messages.forEach((msg) => {
            if (msg.type === "custom" && msg.customEvent === "proposal") {
                if (!latestProposal || msg.time > latestProposal.time) {
                    latestProposal = msg;
                }
            }
        });
        return latestProposal;
    };

    const getLatestContractMessage = (messages: Message[]): Message | null => {
        let latestProposal: Message | null = null;
        messages.forEach((msg) => {
            if (msg.type === "custom" && msg.customEvent === "contract") {
                if (!latestProposal || msg.time > latestProposal.time) {
                    latestProposal = msg;
                }
            }
        });
        return latestProposal;
    };

    const handleMessageClick = async (msg: Message) => {
        setSelectedMessage(msg)
        setMessageModal(true)
    };

    const isContractSigned = messages.some((msg: any) => {
        const isMatch = msg.customEvent === "signContract" && msg.customExts.contractId;
        return isMatch;
    });
    
    const lastSignedContractId = messages
    ?.filter((msg: any) => msg.customEvent === "signContract" && msg.customExts?.contractId)
    ?.pop()?.customExts?.contractId;

    const renderMessageContent = (msgContent: any, isLatest: boolean) => {
        if (msgContent.type === "custom") {
            if (msgContent.customEvent === "proposal") {
                if (!msgContent.customExts || typeof msgContent.customExts !== "object") {
                    return <p className="text-red-500">Invalid custom message format</p>;
                }

                return (
                    <div className="">
                        <p> Here’s my proposal for your required job:</p>
                        <div className="w-full min-h-20 bg-white rounded-xl p-3 mt-2 flex items-center gap-2">
                            <div className={`min-w-14 min-h-14 h-14 w-14 ${isLatest ? 'bg-primary' : 'bg-border-primary'}  rounded-lg`}>
                                <div className="w-full h-full flex justify-center items-center">
                                    <FileTextOutlined className="text-3xl text-white" />
                                </div>
                            </div>
                            <div>
                                <p className={`${isLatest ? 'text-black' : 'text-border-primary'}  text-base font-semibold`}>{msgContent.customExts.title}</p>
                                {/*<p className="text-black/45 text-sm font-semibold">0 MB</p>*/}
                            </div>
                        </div>

                        <div className="w-full flex justify-end mt-2">
                            <Button
                                onClick={() => handleMessageClick(msgContent.customExts)}
                                disabled={!isLatest}
                                className="!px-6 !font-semibold !font-sans disabled:bg-border-primary disabled:text-black"
                            >
                                View
                            </Button>
                        </div>
                    </div>
                );
            }
            if (msgContent.customEvent === "contract") {
                const isSender = msgContent.customExts.from === agora?.agoraUserName;
                const isCurrentSigned = msgContent.customExts?.contractId === lastSignedContractId;
                return (
                    <div className={"p-3 border rounded-lg bg-white shadow-md"}>
                        <p className={`text-black font-medium ${isLatest ? 'text-primary' : 'text-black/50'}`}>
                            {isSender
                                ? "You have sent a contract."
                                : `${msgContent.customExts.senderName ?? ''} sent you a contract`}
                        </p>
                        <div className="mt-2">
                            {isLatest && isCurrentSigned ? (
                                <div>
                                    <div className='flex justify-center mt-2'>
                                        <button 
                                        className='font-sans flex gap-2 items-center text-primary border-2 rounded-md px-3 py-1 text-base font-semibold'
                                        onClick={()=>navigate('/view-contract', {state: {
                                            userDetail: selectedUser,
                                            projectDetail: SelectedRoomData,
                                            contractId: msgContent.customExts.contractId,
                                            selectedChatRoom: selectedChatRoom
                                        }})}
                                        >
                                        <Handshake className="!text-primary" />
                                            View Contract
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    onClick={() => navigate('/contract', {
                                        state: {
                                            userDetail: selectedUser,
                                            projectDetail: SelectedRoomData,
                                            contractId: msgContent.customExts.contractId,
                                            selectedChatRoom: selectedChatRoom
                                        }
                                    })}
                                    disabled={!isLatest || isContractSigned}
                                    className={`px-4 py-2 rounded-lg font-medium border-2 text-black ${isLatest && !isContractSigned ? 'border-primary' : 'border-border-primary'
                                        } disabled:cursor-not-allowed`}
                                >
                                    View Contract
                                </button>
                            )}
                        </div>
                    </div>
                );
            }

        }

        return <p className="text-red-500">Unknown message type</p>;
    };


    const groupedMessages = groupMessagesByDate(messages);
    return (
        <div>
            <div
                className={
                    pathname === "/chat" ? "FreelancerApplicationsContainer" : ""
                }
                style={pathname !== "/chat" ? { padding: "0px" } : {}}
            >
                {pathname === "/chat" && (
                    <div className="FreelancerApplicationsContainer__Header">
                        <div className="FreelancerApplicationsContainer__Header__Title">
                            Chat
                        </div>
                        <div className="FreelancerApplicationsContainer__Header__Actions">
                            <img
                                src={notifications}
                                alt="notifications"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                )}
                <div className={isChatPage ? "chat-active" : ""}>
                    <div className="chat-list">
                        <div className="chat-container">
                            <div className="sidebar">
                                <div className="search-with-message">
                                    <h2>Messages</h2>
                                    <div className="search">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <ul>
                                    <ul>
                                        {applicationsList?.map((user: any) => (
                                            <li
                                                key={user.userId}
                                                style={{ marginBottom: "4px" }}
                                                className={
                                                    selectedUser?.userId === user?.userId ? "active" : ""
                                                }
                                                onClick={() => {
                                                    if (selectedUser?.userId !== user?.userId) {
                                                        handleUserSelection(user)
                                                    }
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        border: "1px solid",
                                                        marginRight: "5px",
                                                        borderRadius: "50%",
                                                        width: "25px",
                                                        height: "25px",
                                                        fontSize: "10px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    {getInitials(user.firstName, user.lastName)}
                                                </div>
                                                <div className="user-info">
                                                    <h5 style={{ fontWeight: "bold" }}>
                                                        {userInfo?.role === "client"
                                                            ? user.professionalTitle
                                                            : `${user.firstName} ${user.lastName}`}
                                                    </h5>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </ul>
                            </div>
                            <div className="chat-section" ref={chatMessagesRef}>
                                {selectedUser && (
                                    <>
                                        <div className="chat-header">
                                            <div className="header-content">
                                                <h3>{selectedUser.firstName}</h3>
                                                <div className="buttons">
                                                    {userInfo?.role !== "client" && (
                                                        <button
                                                            onClick={() => setIsProposalModalOpen(true)}
                                                            className={`send-proposal ${proposalList === null ? "bg-primary" : "bg-black"}`}
                                                        >
                                                            {proposalList === null ? "Send Proposal" : "Edit Proposal"}
                                                        </button>
                                                    )}
                                                    {isContractSigned ? (
                                                        <button onClick={() => navigate('/view-contract', {
                                                            state: {
                                                                userDetail: selectedUser,
                                                                contractId: lastSignedContractId,
                                                                projectDetail: SelectedRoomData,
                                                                selectedChatRoom: selectedChatRoom
                                                            }
                                                        })} className="generate-contract">
                                                            View Contract
                                                        </button>
                                                    ): (
                                                        <button onClick={() => navigate('/contract', {
                                                            state: {
                                                                userDetail: selectedUser,
                                                                projectDetail: SelectedRoomData,
                                                                selectedChatRoom: selectedChatRoom
                                                            }
                                                        })} className="generate-contract">
                                                            Generate Contract
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-full mt-2">
                                                <Select
                                                    className="w-4/5"
                                                    value={selectedChatRoom}
                                                    onChange={(value) => handleChatRoomSelection(value)}
                                                    options={roomData.map((room: any) => ({
                                                        value: room.chatRoomId,
                                                        label: (
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <span>{room.chatRoomName}</span>
                                                                {unreadMessages[room.chatRoomId] > 0 && (
                                                                    <Badge style={{ backgroundColor: '#6A57E3' }} count={unreadMessages[room.chatRoomId]} />
                                                                    //<span className='bg-primary text-white rounded-full p-1 text-xs w-2 '>
                                                                    //    {unreadMessages[room.chatRoomId]}
                                                                    //</span>
                                                                )}
                                                            </div>
                                                        )
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="chat-messages " ref={chatMessagesRef}>
                                            {fetchingLoading ? (
                                                <div className="flex h-full w-full justify-center items-center">
                                                    <Spin />
                                                </div>
                                            ) : (
                                                Object.keys(groupedMessages).map((date, dateIndex) => {
                                                    const latestProposal = getLatestProposalMessage(groupedMessages[date]);
                                                    const latestContract = getLatestContractMessage(groupedMessages[date])
                                                    return (
                                                        <React.Fragment key={date}>
                                                            <Divider variant="dashed" className="!text-xs !font-semibold !font-sans !border-primary">
                                                                {date}
                                                            </Divider>
                                                            {groupedMessages[date].map((msg) => {
                                                                const isLatestProposal = msg.id === latestProposal?.id;
                                                                const isLatestContract = msg.id === latestContract?.id;
                                                                if (msg.customEvent === "signContract") {
                                                                    const isSender = msg?.customExts?.senderName === agora?.agoraUserName;
                                                                    return (
                                                                        <React.Fragment key={msg.id}>
                                                                            <Divider className="!text-sm !font-semibold !font-sans !text-[#018749] !border-[#018749]">
                                                                                {isSender ? "You signed a contract" : `${msg?.customExts?.senderName} signed a contract`}
                                                                            </Divider>
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                                return (
                                                                    <div key={msg.id} className={`message ${msg.from === userInfo?.agoraUserName ? "sent" : "received"}`}>
                                                                        <div>
                                                                            {msg.type === "custom" ? (
                                                                                <>
                                                                                    {msg.customEvent !== "signContract" && (
                                                                                        renderMessageContent(msg, msg.customEvent === "proposal" ? isLatestProposal : isLatestContract)
                                                                                    )}
                                                                                    {/*{renderMessageContent(msg, msg.id === msg?.customEvent === "proposal" ?  latestProposal?.id : latestContract?.id )}*/}
                                                                                    <p style={{ fontSize: "10px", opacity: 0.4, display: "flex", justifyContent: "end" }}>
                                                                                        {moment(msg.time).format("hh:mm A")}
                                                                                    </p>
                                                                                </>
                                                                            ) : (
                                                                                <div>
                                                                                    <p className="break-words">{msg.msg}</p>
                                                                                    <p style={{ fontSize: "10px", opacity: 0.4, display: "flex", justifyContent: "end" }}>
                                                                                        {moment(msg.time).format("hh:mm A")}
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    );
                                                })
                                            )}
                                        </div>
                                        <div className="chat-input">
                                            <input
                                                type="text"
                                                placeholder="Write a message..."
                                                value={inputMessage}
                                                onChange={(e) => setInputMessage(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        handleSendMessage();
                                                    }
                                                }}
                                            />
                                            <button type="submit" onClick={() => handleSendMessage()}>
                                                ➤
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/*<ChatSection selectedUser={selectedUser} agoraUserName={agoraUserName} handleSendMessage={()=> handleSendMessage()} messages={messages ?? \}/>*/}
                        </div>
                    </div>
                </div>
            </div>
            {selectedUser && (
                <SendProposal
                    isProposalModal={isProposalModalOpen}
                    jobId={roomData.find(room => room.chatRoomId === selectedChatRoom)?.jobId || ""}
                    setProposalModal={() => setIsProposalModalOpen(false)}
                    proposalData={proposalList}
                    onSuccess={async (res) => {
                        if (selectedChatRoom) {
                            try {
                                const customMessage = {
                                    title: res.title || "",
                                    description: res.description || "",
                                    mileStone: res.mileStone || [],
                                    additionalCost: res.additionalCost && res.additionalCost.length > 0 ? res.additionalCost : undefined
                                };

                                const messageRes = await sendRoomMessage(
                                    selectedChatRoom,
                                    "",
                                    "custom",
                                    customMessage,
                                    "proposal"
                                );

                                if (messageRes) {
                                    const newMessage = {
                                        id: messageRes.message.time.toString(),
                                        from: agora?.agoraUserName,
                                        to: selectedChatRoom,
                                        customExts: customMessage,
                                        time: messageRes.message.time,
                                        type: "custom",
                                        customEvent: "proposal",
                                    };
                                    setMessage((prevMessages: any) => [...prevMessages, newMessage]);
                                    fetchProposalStatus()
                                }
                            } catch (error) {
                                console.error("Failed to send custom proposal message to chat", error);
                            }
                        }
                    }}
                />

            )}
            {selectedMessage && (
                <CustomMessageModal isProposalModal={messageModal} setProposalModal={() => setMessageModal(false)} messages={selectedMessage} OnsetMessages={() => setSelectedMessage(null)} />
            )}
        </div>
    )
}

export default Chat
