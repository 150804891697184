import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientState {
    selectedApplication: any
};

const initialState: ClientState = {
    selectedApplication: null
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setSelectedApplication: (state, action: PayloadAction<any>) => {
            const { application } = action.payload;
            state.selectedApplication = application;
        },
    },
    extraReducers(builder) {
    }
});

export const { setSelectedApplication } = clientSlice.actions;

export default clientSlice.reducer;