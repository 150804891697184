import React, { useState } from 'react';
import { Typography, Select } from 'antd';
import { Segmented } from 'antd';
import './Discover.scss';
import CustomButton from '../../../components/CustomButton';
import chat from '../../../assets/chat.svg';
import notifications from '../../../assets/notifications.svg';
import magnifier from '../../../assets/magnifier.svg';
import user from '../../../assets/user.png';
import InputBox from '../../../components/InputBox';
import FreelancerTile from '../../../components/FreelancerTile';

const { Title } = Typography;

const Discover = () => {

    let options = []

    for (let i = 10; i < 36; i++) {
        options.push({
          value: i.toString(36) + i,
          label: i.toString(36) + i,
        });
      }

    const [filtersData, setFiltersData] = useState({
        skills: [],
        tools: [],
        minRate: '',
        maxRate: '',
    });

    return (
        <>
            <div className='Discover'>
                <div className='Discover__Header'>
                    <div className='Discover__Header__Title'>Discover</div>
                    <div className='Discover__Header__Actions'>
                        <CustomButton buttonText='Upgrade' onClick={() => {}} />
                        <img src={chat} alt='chat' />
                        <img src={notifications} alt='notifications' />
                    </div>
                </div>
                <div className='Discover__Body'>
                    <div className='Discover__Body__Filters'>
                        <Title className='Discover__Body__Filters__Title' level={5}>Filters</Title>
                        <div className='Discover__Body__Filters__Skills'>
                            <Title className='Discover__Body__Filters__Skills__Title' level={5}>Skills</Title>
                            <Select
                                mode="multiple"
                                placeholder="Search for skills"
                                onChange={(value) => setFiltersData({...filtersData, skills: value})}
                                options={options}
                                style={{width: '100%'}}
                            />
                        </div>
                        <div className='Discover__Body__Filters__Tools'>
                            <Title className='Discover__Body__Filters__Tkills__Title' level={5}>Tools</Title>
                            <Select
                                mode="multiple"
                                placeholder="Search for tools"
                                onChange={(value) => setFiltersData({...filtersData, tools: value})}
                                options={options}
                                style={{width: '100%'}}
                            />
                        </div>
                        <div className='Discover__Body__Filters__Rate'>
                            <Title className='Discover__Body__Filters__Tkills__Title' level={5}>Rate</Title>
                            <div className='Discover__Body__Filters__Rate__Inputs'>
                                <InputBox placeholder="Min." value={filtersData?.minRate} onChange={(event) => setFiltersData({...filtersData, minRate: event?.target?.value})} />
                                <InputBox placeholder="Max." value={filtersData?.maxRate} onChange={(event) => setFiltersData({...filtersData, maxRate: event?.target?.value})} />
                            </div>
                        </div>
                    </div>
                    <div className='Discover__Body__MainSection'>
                        <div className='Discover__Body__MainSection__AdditionalFilters'>
                            <InputBox prefix={<img src={magnifier} />} placeholder='Search for a Nursing Assistant' />
                            <Segmented<string>
                                options={['People', 'Projects']}
                                onChange={(value) => {
                                console.log(value); // string
                                }}
                            />
                        </div>
                        <div className='Discover__Body__MainSection__SearchResults'>
                            <FreelancerTile freelancerId="1" userName='Ronald Richard' imageUrl={user} professionalTitle='Photographer' rating='4.5' jobTitles={['Product Designer']} minRate='70' maxRate='100'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Discover;