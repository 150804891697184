import axiosInstance from "./axiosConfig";

export const getCountryApi = async () => {
    try {
        const response = await axiosInstance.get(`common/country`);
        return response;
    } catch(err) {
        return err;
    }
};

export const getCityApi = async (countryId: string | number) => {
    try {
        const response = await axiosInstance.get(`/common/city/${countryId}`);
        return response;
    } catch(err) {
        return err;
    }
};