import React, { useEffect, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import CONSTANTS from './constants';
import { postClientDetails, postFreelancerDetails } from '../../services/authService';
import AccountDetails from '../Registration/Common/AccountDetails';
import OfferedServices from '../Registration/Freelancer/OfferedServices';
import './Onboarding.scss'
import { getAdditionalFreelancerErrorFields, getOnboradingErrorFields, validFreelancerDetails, validOnboardingDetails } from '../../utils/globalUtils';
import moment from 'moment';
import { message } from 'antd';

const defaultAccountDetails  = {
    fullName: '',
    userName: '',
    phoneNumber: '',
    dateOfBirth: '',
    about: '',
    address: {
        countryId:  null,
        cityId:  null,
    }
}

const defaultDetailsValidationErrror  = {
    fullName: false,
    userName: false,
    phoneNumber: false,
    dateOfBirth: false,
    location: false,
    
}

const defaultFreelancerDetailsValidationErrror  = {
    professionalTitle: false,
    serviceId: false,
    openingHrs: false,
    closingHrs: false,
    portfolioUrl1: false,
    portfolioUrl2: false
}

const defaultFreelancerServicesDetails = {
    professionalTitle: '',
    serviceId: '',
    openingHrs: '',
    closingHrs: '',
    portfolioUrl1: '',
    portfolioUrl2: '',
}

const Onboarding = () => {

    const navigate = useNavigate();

    const { TITLES, BACK, NEXT, ALREADY_HAVE_ACCOUNT, GOTO_DASHBOARD } = CONSTANTS;
    const [currentScreen, setCurrentScreen] = useState<string>('registrationChoice');
    const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0);
    const [accountDetails, setAccountDetails] = useState(defaultAccountDetails)
    const [accountDetailsValidationState, setAccountDetailsValidationState] = useState(defaultDetailsValidationErrror)

    const [freelancerServicesDetails, setFreelancerServicesDetails] = useState(defaultFreelancerServicesDetails)
    const [freelancerDetailsValidationState, setFreelancerDetailsValidationState] = useState(defaultFreelancerDetailsValidationErrror)
    const [loading, setLoading] = useState<boolean>(false);
    const userInfo = JSON.parse(localStorage.getItem('userDetails') || '{}');

     const isOlderThan16 = moment().diff(moment(accountDetails.dateOfBirth, "YYYY-MM-DD"), 'years') >= 16;
    const saveFreelancerDetails = async() => {
        if(currentScreenIndex === 2 && currentScreen === 'freelancerServices') {
            const { address, ...rest } = accountDetails;
        const { countryId, cityId } = address;
            const requestData = {
                ...rest,  
                address: { countryId, cityId },
                ...freelancerServicesDetails, 
            };
            const resp = await postFreelancerDetails(requestData);
            if(resp?.data?.statusCode === 201 || resp?.data?.statusCode === 200) {
                localStorage.setItem('serviceId', freelancerServicesDetails?.serviceId);
                setLoading(() => false);
                setAccountDetails(defaultAccountDetails);
                setFreelancerServicesDetails(defaultFreelancerServicesDetails);
                navigate('/questions', {replace: true});
            } else {
                setLoading(() => false);
                setCurrentScreenIndex(currentScreenIndex - 1);
            }
        } else {
            setLoading(() => false);
            setCurrentScreen((prev) => 'freelancerServices');
        }
    }

    const saveClientDetails = async() => {
        if(currentScreenIndex === 1 && currentScreen === 'accountDetails') {
            const { address, ...rest } = accountDetails;
        const { countryId, cityId } = address;
            const resp = await postClientDetails({
                ...rest,  
                address: { countryId, cityId }
            });
            if(resp?.data?.statusCode === 201 || resp?.data?.statusCode === 200) {
                setLoading(() => false);
                setAccountDetails(defaultAccountDetails);
                navigate('/upload-picture', {replace: true});
            } else {
                setLoading(() => false);
                setCurrentScreenIndex(currentScreenIndex - 1);
            }
        } else {
            setLoading(() => false);
            setCurrentScreen((prev) => 'accountDetails');
        }
    }

    useEffect(() => {
        switch(currentScreenIndex) {
            case 0:
                setCurrentScreen('accountDetails');
                break;
            case 1:
                setLoading(() => true);
                if(validOnboardingDetails(accountDetails)) {
                    if(accountDetails.dateOfBirth && isOlderThan16){
                    if(userInfo?.role?.toLowerCase() === 'freelancer') {
                        setLoading(() => false);
                        setCurrentScreen('freelancerServices');
                    } else {
                        saveClientDetails();
                    }
                }else {
                    setLoading(() => false);
                    setAccountDetailsValidationState(prevState => ({
                        ...prevState,
                        dateOfBirth: true
                    }));
                    message.error({
                        content: 'Age must be at least 16 years!',
                        duration: 3,
                    });
                    setFreelancerDetailsValidationState(defaultFreelancerDetailsValidationErrror); // Reset before setting new errors
                  
                }
                } else {
                    const errorFields = getOnboradingErrorFields(accountDetails);
                    setAccountDetailsValidationState({...accountDetailsValidationState, ...errorFields});

                    setLoading(() => false);
                    setCurrentScreenIndex(currentScreenIndex - 1);
                }
                break;
            //case 2:
                
            //    setLoading(() => true);
            //    if(validFreelancerDetails(freelancerServicesDetails) ) {
            //        saveFreelancerDetails();
            //    } else {
            //        const errorFields = getAdditionalFreelancerErrorFields(freelancerServicesDetails);
            //        setFreelancerDetailsValidationState({...accountDetailsValidationState, ...errorFields});
            //        setLoading(() => false);
            //        setCurrentScreenIndex(currentScreenIndex - 1);
            //    }
            //    break;
            case 2:
    setLoading(() => true);
    if (validFreelancerDetails(freelancerServicesDetails)) {
        saveFreelancerDetails();
    } else {
        setFreelancerDetailsValidationState(defaultFreelancerDetailsValidationErrror); // Reset before setting new errors
        const errorFields = getAdditionalFreelancerErrorFields(freelancerServicesDetails);
        setFreelancerDetailsValidationState(errorFields);
        setLoading(() => false);
        setCurrentScreenIndex(currentScreenIndex - 1);
    }
    break;
            default:
                setCurrentScreen('accountDetails');
        }

    }, [currentScreenIndex]);

    const handleOnAccountDetailsChange = (key: string, value: any) => {
        setAccountDetails((prev) => {
            if (key === 'countryId' || key === 'cityId') {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        [key]: value
                    }
                };
            }
            return { ...prev, [key]: value };
        });
    };

    return (
        <>
            <div className='Registration__Card__Body__MainContent'>
                <div className='Registration__Card__Body__MainContent__SubHeader'>
                    {currentScreen === 'accountDetails' && <>{TITLES.ACCOUNT_CONFIGURE_TITLE}</>}
                    {currentScreen === 'freelancerServices' && <>{TITLES.FREELANCER_PRFILE_SETUP_TITLE}</>}
                </div>
                <div className='Registration__Card__Body__MainContent__Body'>
                    {currentScreen === 'accountDetails' && (<AccountDetails {...accountDetails} validationErrors={accountDetailsValidationState} handleOnChange={handleOnAccountDetailsChange} />)}
                    {currentScreen === 'freelancerServices' && (
                        <OfferedServices
                            validationErrors={freelancerDetailsValidationState}
                            freelancerServicesDetails={freelancerServicesDetails}
                            setFreelancerServicesDetails={setFreelancerServicesDetails}
                            
                        />)
                    }
                </div>
                <div className='Registration__Card__Body__MainContent__Footer'>
                    {['freelancerServices'].includes(currentScreen) && <CustomButton className='Registration__Card__Body__MainContent__Footer__BackBtn' onClick={(event) => setCurrentScreenIndex(currentScreenIndex - 1)} buttonText={BACK} />}
                    <CustomButton className='Registration__Card__Body__MainContent__Footer__NextBtn' onClick={(event) => setCurrentScreenIndex(currentScreenIndex + 1)} buttonText={NEXT} loading={loading} />
                </div>
            </div>
            <div className='Registration__Card__Body__Footer'>{ALREADY_HAVE_ACCOUNT} <Link to={'/login'} className='Registration__Card__Body__Footer__GotoDashboard'>{GOTO_DASHBOARD}</Link></div>
        </>
    );
}

export default Onboarding;