import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getContract } from '../../../services/jobService';
import { GetContractResponse } from '../../../utils/types';
import { Button, Skeleton } from 'antd';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "../contract.scss"
import { Minus, Plus } from 'lucide-react';
import CustomButton from '../../../components/CustomButton';

const ViewContract = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState<string[]>([]);
    const [contractDetails, setContractDetails] = useState<GetContractResponse | null>(null)
    const pageRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    const contractId = state?.contractId || '';
    const selectedChatRoom = state?.projectDetail?.chatRoomId || '';


    useEffect(() => {
        if (contractId) {
            setLoading(true)
            getContract(contractId)
                .then((res) => {
                    setContractDetails(res?.data.data)
                    const content = res?.data.data?.content || "";
                    const paginatedContent = paginateContent(content);
                    setPages(paginatedContent);
                    setLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false)
                })
        }
    }, [contractId])



    const paginateContent = (content: string) => {
        const lines = content.split("\n"); // Split content into lines
        let tempPage: string[] = [];
        let tempPages: string[][] = [];

        lines.forEach((line) => {
            if (tempPage.length >= 35) { // If page reaches 25 lines, push to pages
                tempPages.push([...tempPage]);
                tempPage = [];
            }
            tempPage.push(line);
        });

        if (tempPage.length) tempPages.push([...tempPage]); // Add last page if any remaining lines

        return tempPages.map(page => page.join("\n")); // Convert array of lines back to string
    };

    const handleNext = () => {
        if (currentPage < pages.length - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        if (pageRef.current) {
            pageRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [currentPage]);

    return (
        <div>
            <div className="p-10 h-dvh">
                <div className="flex justify-between items-center">
                    <div>
                        <p className="text-4xl font-bold">View Contract</p>
                    </div>
                    <CustomButton buttonText={"Cancel"} onClick={() => navigate('/chat', { state: { lastSelectedChatRoom: selectedChatRoom, agoraId: state?.userDetail?.agoraId } })} className="font-sans" />
                </div>
                <div className='h-[calc(100%-40px)] w-full flex flex-col justify-center'>
                    <div ref={pageRef} className='mt-2 p-2 mx-auto h-[calc(100%-80px)] max-h-[1140px] overflow-auto'>

                        <div className="a4-page border">
                            <div className="a4-content prose lg:prose-lg">
                                <div className="prose lg:prose-lg leading-relaxed space-y-3">
                                    {loading ? (
                                        <div className='rounded-full flex flex-col gap-3'>
                                            <Skeleton paragraph={{ rows: 10, width: ["100%", "90%", "95%", "80%", "0%", "90%", "95%", "80%",] }} active className='w-full m-0' />
                                        </div>
                                    ) : (
                                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                            {pages[currentPage]}
                                        </ReactMarkdown>


                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {pages.length > 0 && (
                        <div className="flex justify-center gap-3 items-center mt-2">
                            <Button
                                className='bg-primary disabled:bg-gray'
                                type="primary"
                                size='small'
                                shape="circle"
                                icon={<Minus size={16} />}
                                onClick={handlePrevious}
                                disabled={currentPage === 0}
                            />

                            <p className='bg-primary/35 px-3 m-0 rounded-full'>Page {currentPage + 1} of {pages.length}</p>
                            <Button
                                className='bg-primary disabled:bg-gray'
                                type="primary"
                                shape="circle"
                                size='small'
                                icon={<Plus size={16} />}
                                onClick={handleNext}
                                disabled={currentPage === pages.length - 1}
                            />
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}

export default ViewContract