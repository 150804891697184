import React, { useState, useEffect, useContext } from 'react';
import { initializeAgoraChat } from '../utils/agoraChat';

interface AuthContextType {
    token: string | null
    user: any
    saveToken: (userToken: string | null) => void
    saveChatToken: (chatToken: string | null) => void
    saveLoggedInUser: (user: any) => void
}

const AuthContext = React.createContext<AuthContextType>({
    token: null,
    user: null,
    saveToken: (userToken: string | null) => {},
    saveChatToken: (chatToken: string | null) => {},
    saveLoggedInUser: (user: any) => {}
});

export function useAuth() {
    //initializeAgoraChat();
    return useContext(AuthContext);
}

export function AuthProvider(props: any) {

    const getLoggedInUserInfo = () => {
        const user = JSON.parse(sessionStorage?.getItem('loggedInUser') || 'null');
        return user;
    }

    const getToken = () => {
        const tokenString = sessionStorage?.getItem('token') || 'null';
        const userToken = JSON.parse(tokenString);
        // console.log(userToken,'userToken')
        return userToken;
    };

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getLoggedInUserInfo());

    // useEffect(() => {
    // }, []);
 
   const saveToken = (userToken: string | null) => {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };
    const saveLoggedInUser = (user: any) => {
        sessionStorage.setItem('loggedInUser', JSON.stringify(user));
        setUser(user);
    }
    const saveChatToken = (chatToken: string | null) => {
        sessionStorage.setItem('chatToken', JSON.stringify(chatToken));
        setToken(chatToken);
    }
    const value = {
        saveChatToken,
        saveToken,
        saveLoggedInUser,
        user,
        token
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}