import React from 'react'
import CustomModal from '../CustomModal';
import CustomButton from '../CustomButton';

interface customMessageModalProps {
    isProposalModal: boolean;
    setProposalModal: (val: boolean) => void;
    messages: any
    OnsetMessages: (val: null) => void;
}
const CustomMessageModal = ({ isProposalModal, setProposalModal, messages, OnsetMessages }: customMessageModalProps) => {

    const onModalClose = () => {
        setProposalModal(false)
        OnsetMessages(null)
    }
    return (
        <CustomModal isOpen={isProposalModal} onCancel={() => onModalClose()} closable={false} width={'700px'} footer={[
            <CustomButton
                className='px-5 py-2 rounded-full'
                key='back'
                onClick={onModalClose}
                buttonText="Close"
            />,
        ]}>
            <div>
                <div>
                    <p className='text-2xl text-black font-bold font-sans'>Review proposal</p>
                </div>
                <div className='mt-2'>
                    <p className='font-semibold text-base'>Title : <span>{messages?.title}</span></p>
                    {messages?.mileStone?.map((milestone: any, index: number) => {
                        return (
                            <p key={index} className='font-semibold text-base'>Milestone {index + 1} : <span>{milestone?.description} | {milestone?.amount} USD | {milestone.proposalDuration} : {milestone.proposalDurationType.charAt(0).toUpperCase() + milestone.proposalDurationType.slice(1).toLowerCase()}</span></p>
                        )
                    })}
                    <p className='font-semibold text-base'>Deliverables : <span>{messages?.description}</span></p>
                    {messages?.additionalCost && (<p className='font-semibold text-base'>Additional cost : <span>{messages?.additionalCost[0].amount} USD</span></p>)}
                    {messages?.additionalCost && (<p className='font-semibold text-base'>Additional cost description : <span>{messages?.additionalCost[0].description}</span></p>)}

                </div>
            </div>
        </CustomModal>

    )
}

export default CustomMessageModal