import React from 'react';
import { Typography } from 'antd';
import './ProjectCard.scss'

interface IProjectCardProps {
    id: string
    title: string
    imageUrl: string
    isSelected?: boolean
    handleProjectClick?: (id: string) => void
}

const { Text } = Typography;

const ProjectCard = ({
    id,
    title,
    imageUrl,
    isSelected = false,
    handleProjectClick = () => {}
}: IProjectCardProps) => {
    return (
       <div className={`ProjectCardContainer ${isSelected ? 'ProjectCardContainer__Selected' : ''}`} onClick={() => handleProjectClick(id)}>
            <img className='ProjectCardContainer__Image' src={imageUrl} alt={title} />
            <Text className='ProjectCardContainer__Title' strong>{title}</Text>
       </div>
    );
}

export default ProjectCard;