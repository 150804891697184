import React from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import './Layout.scss'
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { logout } from '../../../slice/authSlice';
import { useAuth } from '../../../contexts/AuthContext';
import { clearAgoraCredentials } from '../../../slice/agoraSlice';

const Layout = () => {
    const { token, saveToken, saveLoggedInUser } = useAuth();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(logout())
        dispatch(clearAgoraCredentials());
        sessionStorage.clear();
        saveToken(null);
        saveLoggedInUser({});
        navigate('/login', {replace: true});
        
    }

    return token ? (
        <div className='DashboardLayout'>
            <div className='DashboardLayout__Sidebar'>
                <Sidebar handleLogout={handleLogout} />
            </div>
            <main className='DashboardLayout__MainSection'>
                <Outlet />
            </main>
        </div>
    ) : (
        <Navigate to='/login' />
    );
}

export default Layout;