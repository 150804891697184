import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AgoraState {
    chatToken: string | null;
    agoraUserName: string | null;
    isInitialized: boolean;
}

const initialState: AgoraState = {
    chatToken: null,
    agoraUserName: null,
    isInitialized: false,
};

const agoraSlice = createSlice({
    name: "agora",
    initialState,
    reducers: {
        setAgoraCredentials: (state, action: PayloadAction<{ chatToken: string; agoraUserName: string }>) => {
            state.chatToken = action.payload.chatToken;
            state.agoraUserName = action.payload.agoraUserName;
            state.isInitialized = true;
        },
        clearAgoraCredentials: (state) => {
            state.chatToken = null;
            state.agoraUserName = null;
            state.isInitialized = false;
        },
    },
});

export const { setAgoraCredentials, clearAgoraCredentials } = agoraSlice.actions;
export default agoraSlice.reducer;
