import React, { useEffect, useState } from 'react';
import logo from "../../assets/logo.svg";
import verify from "../../assets/verify.svg";
import './Registration.scss'
import CustomButton from '../../components/CustomButton';
import RegistrationChoice from './Common/RegistrationChoice';
import AccountSetup from './Common/AccountSetup';
import OtpScreen from './Common/OtpScreen';
import PasswordSetup from './Common/PasswordSetup';
import ProfilePicUploader from './Common/ProfilePicUploader';
import AccountDetails from './Common/AccountDetails';
import PaymentDetails from './Common/PaymentDetails';
// import VerifyIdentity from '../VerifyIdentity';
import Services from './Client/Services';
import OfferedServices from './Freelancer/OfferedServices';
import { Link, useNavigate } from 'react-router-dom';
import CONSTANTS from './constants';
import { registerUser, setupUserPassword, verifyOtp } from '../../services/authService';
import { message } from 'antd';

interface AccountId {
    [key: string]: string
}

const defaultAccountDetails  = {
    fullName: '',
    userName: '',
    phoneNumber: '',
    dateOfBirth: '',
    location: ''
}

const defaultFreelancerServicesDetails = {
    professionalTitle: '',
    subCategoryService: '',
    openingHrs: '',
    closingHrs: '',
    portfolioLink: ''
}

const defaultPasswordInputs = {
    password: '',
    confirmPassword: '',
    showMismatchError: false,
}

const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Registration = () => {

    const navigate = useNavigate();

    const { TITLES, BACK, NEXT, ALREADY_HAVE_ACCOUNT, GOTO_DASHBOARD } = CONSTANTS;
    const [currentScreen, setcurrentScreen] = useState<string>('registrationChoice');
    const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0);
    const [selectedRegistrationOption, setRegistrationSelectedOption] = useState('freelancer');
    const [accountId, setAccountId] = useState<AccountId>({});
    const [isInvalidAccountId, setIsInvalidAccountId] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [passwordInputs, setPasswordInputs] = useState(defaultPasswordInputs);
    const [accountDetails, setAccountDetails] = useState(defaultAccountDetails)
    // const [freelancerServicesDetails, setFreelancerServicesDetails] = useState(defaultFreelancerServicesDetails)
    // const [selectedServices, setSelectedServices] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false);



    const registerNewUser = () => {
        if (
          currentScreenIndex === 2 &&
          currentScreen === 'accountSetup' &&
          (accountId?.email || accountId?.phone)
        ) {
          setLoading(true);
      
          registerUser({ ...accountId, role: selectedRegistrationOption.toLowerCase() })
            .then((resp) => {
              if (resp?.data?.statusCode === 200) {
                setLoading(false);
                setcurrentScreen('otpScreen');
                setOtp(''); // reset OTP
              } else if (
                  resp?.data?.statusCode === 409 ||
                  resp?.data?.message === 'User already exists'
                ) {
                message.error({
                  content: resp?.data?.message,
                  duration: 3,
                });
                setLoading(false);
                setIsInvalidAccountId(true);
                setErrorMessage(resp?.data?.message);
                setCurrentScreenIndex(currentScreenIndex - 1);
              } else {
                setLoading(false);
                setErrorMessage('Please enter a valid email id');
                setCurrentScreenIndex(currentScreenIndex - 1);
              }
            })
            .catch((error) => {
              // This catch block handles any errors thrown in registerUser
              setLoading(false);
              setIsInvalidAccountId(true);
              setCurrentScreenIndex(currentScreenIndex - 1);
            });
        } else {
          setLoading(false);
          setcurrentScreen('otpScreen');
        }
      };
      
    const verifyUserOtp = async() =>  {
        setLoading(() => true);
        if(currentScreenIndex === 3 && currentScreen === 'otpScreen' && otp) {
            const resp = await verifyOtp({...accountId, otp: otp});
            if(resp?.data?.statusCode === 200 || resp?.data?.statusCode === 201) {
                setLoading(() => false);
                setcurrentScreen('passwordSetup');
                setOtp((prev) => '');
            } else {
                setLoading(() => false);
                setCurrentScreenIndex(currentScreenIndex - 1);
            }
        } else {
            setLoading(() => false);
            setcurrentScreen((prev) => 'passwordSetup')
        }
    }

    const setupPassword = async() => {
        setLoading(() => true);
        if(currentScreenIndex === 4 && currentScreen === 'passwordSetup') {
            if(passwordInputs?.password && passwordInputs?.confirmPassword && passwordInputs?.password === passwordInputs?.confirmPassword ) {
                setPasswordInputs({...passwordInputs, showMismatchError: false});
                const resp = await setupUserPassword({password: passwordInputs?.password});
                if(resp?.data?.statusCode === 201 || resp?.data?.statusCode === 200) {
                    setLoading(() => false);
                    setAccountDetails(defaultAccountDetails);
                    // setcurrentScreen('accountDetails');
                    setPasswordInputs(() => defaultPasswordInputs);
                    navigate('/onboarding', {replace: true});
                    return;
                } else {
                    setLoading(() => false);
                    setCurrentScreenIndex(currentScreenIndex - 1);
                }
            } else {
                setLoading(() => false);
                setCurrentScreenIndex(currentScreenIndex - 1);
                setPasswordInputs({...passwordInputs, showMismatchError: true});
            }
        } else {
            setLoading(() => false);
            setcurrentScreen((prev) => 'otpScreen');
        }
    }
    const handleNextClick = () => {
        if (currentScreen === 'passwordSetup' && passwordInputs.showMismatchError) {
            message.error('Please fix the errors before proceeding.');
        } else {
            setCurrentScreenIndex(currentScreenIndex + 1);
        }
    };

    useEffect(() => {
        switch(currentScreenIndex) {
            case 0: 
                setcurrentScreen('registrationChoice');
                break;
            case 1:
                // setAccountId({});
                setOtp('');
                setcurrentScreen('accountSetup');
                break;
            case 2:
                setLoading(() => true);
                if(accountId?.email && validateEmail(accountId?.email)) {
                    setIsInvalidAccountId(false)
                    registerNewUser();
                } else {
                    setLoading(() => false);
                    setIsInvalidAccountId(true);
                    setErrorMessage('Please enter a valid email id');
                    setCurrentScreenIndex(currentScreenIndex - 1);
                }
                break;
            case 3:
                verifyUserOtp();
                break;
            case 4:
                setupPassword();
                break;
            // case 5:
            //     if(selectedRegistrationOption === 'freelancer') {
            //         setFreelancerServicesDetails(defaultFreelancerServicesDetails);
            //         setcurrentScreen('freelancerServices');
            //     } else {
            //         setcurrentScreen('profilePicUploader');
            //     }
            //     break;
            // case 6:
            //     if(selectedRegistrationOption === 'freelancer') {
            //         saveFreelancerDetails();
            //     } else {
            //         setcurrentScreen('paymentDetails');
            //     }
            //     break;
            // case 7:
            //     if(selectedRegistrationOption === 'freelancer') {
            //         setcurrentScreen('paymentDetails');
            //     } else {
            //         setcurrentScreen('verifyIdentity');
            //     }
            //     break;
            // case 8:
            //     if(selectedRegistrationOption === 'freelancer') {
            //         setcurrentScreen('verifyIdentity');
            //     } else {
            //         setcurrentScreen('interestedServices');
            //     }
            //     break;
            default:
                setcurrentScreen('registrationChoice');
        }

    }, [currentScreenIndex]);

    const handleOptionChange = (event: any) => {
        setRegistrationSelectedOption(event?.target.value);
    };

    const handleOnAccountDetailsChange = (key: string, value: string) => {
        setAccountDetails({...accountDetails, [key]: value});
    };

    return (
        <>
            <div className='Registration__Card__Body__MainContent'>
                <div className='Registration__Card__Body__MainContent__SubHeader'>
                    {currentScreen === 'registrationChoice' && <>{TITLES.REGISTRATION_CHOICE_TITLE}</>}
                    {currentScreen === 'accountSetup' && <>{TITLES.ACCOUNT_SETUP_TITLE}</>}
                    {currentScreen === 'otpScreen' && <>{TITLES.EMAIL_CONFIRM_TITLE}</>}
                    {currentScreen === 'passwordSetup' && <>{TITLES.CHOOSE_PASSWORD_TITLE}</>}
                    {/* {currentScreen === 'accountDetails' && <>{TITLES.ACCOUNT_CONFIGURE_TITLE}</>} */}
                    {/* {currentScreen === 'profilePicUploader' && <>{TITLES.UPLOAD_PROFILE_TITLE}</>} */}
                    {/* {currentScreen === 'paymentDetails' && <>{TITLES.PAYMENT_DETAILS_TITLE}</>} */}
                    {/* {currentScreen === 'verifyIdentity' && <><img src={verify} alt={TITLES.VERIFY_IDENTITY_TITLE} /></>} */}
                    {/* {currentScreen === 'interestedServices' && <>{TITLES.INTERESTED_SERVICES_TITLE}</>} */}
                    {/* {currentScreen === 'freelancerServices' && <>{TITLES.FREELANCER_PRFILE_SETUP_TITLE}</>} */}
                </div>
                <div className='Registration__Card__Body__MainContent__Body'>
                    {currentScreen === 'registrationChoice' && (<RegistrationChoice selectedOption={selectedRegistrationOption} handleOptionChange={handleOptionChange} />)}
                    {currentScreen === 'accountSetup' && (<AccountSetup accountId={accountId} isInvalidAccountId={isInvalidAccountId} errorMessage={errorMessage} handleOnChange={(key:string, value: string) => {
                            setAccountId({[key]: value});
                            setIsInvalidAccountId(false);
                        }} 
                    />)}
                    {currentScreen === 'otpScreen' && (<OtpScreen value={otp} onChange={(text) => setOtp(text)} email={accountId.email} />)}
                    {currentScreen === 'passwordSetup' && (<PasswordSetup passwordInputs={passwordInputs} handleOnPasswordChange={(key, value, hasError) => {setPasswordInputs((prev) => ({...prev,[key]: value, showMismatchError: hasError ?? prev.showMismatchError}))}}/>)}
                    {/* {currentScreen === 'accountDetails' && (<AccountDetails {...accountDetails} handleOnChange={handleOnAccountDetailsChange} />)} */}
                    {/* {currentScreen === 'profilePicUploader' && (<ProfilePicUploader />)} */}
                    {/* {currentScreen === 'paymentDetails' && (<PaymentDetails />)} */}
                    {/* {currentScreen === 'verifyIdentity' && (<VerifyIdentity />)} */}
                    {/* {currentScreen === 'interestedServices' && (<Services selectedServices={selectedServices} setSelectedServices={setSelectedServices}/>)} */}
                    {/* {currentScreen === 'freelancerServices' && (
                        <OfferedServices
                            servicesList={[{label: 'Automotive services', value: 'automotive'}, {label: 'Beauty', value: 'beauty'}]} 
                            subServiceCategory={{
                                label: 'What types of Automotive services do you offer?',
                                options: [{label: 'Automotive services', value: 'carRepair'}, {label: 'Beauty', value: 'beauty'}]
                            }}
                            freelancerServicesDetails={freelancerServicesDetails}
                            setFreelancerServicesDetails={setFreelancerServicesDetails}
                        />)} */}
                </div>
                <div className='Registration__Card__Body__MainContent__Footer'>
                    {!['accountSetup'].includes(currentScreen) && <CustomButton className='Registration__Card__Body__MainContent__Footer__BackBtn' onClick={(event) => setCurrentScreenIndex(currentScreenIndex - 1)} buttonText={BACK} />}
                    <CustomButton className='Registration__Card__Body__MainContent__Footer__NextBtn' onClick={handleNextClick} buttonText={NEXT} loading={loading} />
                </div>
            </div>
            <div className='Registration__Card__Body__Footer'>{ALREADY_HAVE_ACCOUNT} <Link to={'/login'} className='Registration__Card__Body__Footer__GotoDashboard'>{GOTO_DASHBOARD}</Link></div>
        </>
    );
}

export default Registration;