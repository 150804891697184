import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputBox from '../../../../components/InputBox';
import './PasswordSetup.scss';
import CONSTANTS from './constants';
import { CircleCheckBig, CircleX, X } from 'lucide-react';

const { Text } = Typography;

// Strong password validation schema
const validationSchema = Yup.object({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        //.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        //.matches(/[0-9]/, 'Password must contain at least one number')
        //.matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required')
});

interface PasswordInputsProps {
    password: string;
    confirmPassword: string;
    showMismatchError: boolean;
}

interface PasswordSetupProps {
    passwordInputs: {
        password: string;
        confirmPassword: string;
        showMismatchError: boolean;
    };
    handleOnPasswordChange: (key: string, value: string, hasError?: boolean) => void; // Allow 3rd param
}

const PasswordSetup = ({ passwordInputs, handleOnPasswordChange }: PasswordSetupProps) => {
    const formik = useFormik({
        initialValues: {
            password: passwordInputs.password,
            confirmPassword: passwordInputs.confirmPassword
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: () => { }
    });

    useEffect(() => {
        const hasMismatchError = formik.touched.confirmPassword && !!formik.errors.confirmPassword;
        handleOnPasswordChange('password', formik.values.password);
        handleOnPasswordChange('confirmPassword', formik.values.confirmPassword, hasMismatchError);
    }, [formik.values.password, formik.values.confirmPassword, formik.errors, formik.touched.confirmPassword]);


    const password = formik.values.password;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);
    const hasMinLength = password.length >= 8;

    const renderIcon = (isValid: boolean) =>
        isValid ? <CircleCheckBig size={18} style={{ color: 'green' }} /> :
            <CircleX size={18} style={{ color: 'red' }} />;

    return (
        <div className='SetupPassword__Container mb-0'>
            <InputBox
                className='SetupPassword__Container__Password'
                type='password'
                label={CONSTANTS.PASSWORD_LABEL}
                value={formik.values.password}
                variant='filled'
                placeholder={CONSTANTS.PASSWORD_PLACEHOLDER}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='password'
                id='password'
                formikError={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
            />
            {formik.values.password.length && (!hasUpperCase || !hasNumber || !hasSpecialChar || !hasMinLength) ? (
                <div className="my-2">
                    <ul className="flex items-start flex-col gap-1">
                        <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                            <span className="transition-all duration-300 text-red-500 scale-90">
                                {renderIcon(hasUpperCase)}
                            </span>
                            Contains at least one uppercase letter
                        </li>
                        <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                            <span className="transition-all duration-300 text-red-500 scale-90">
                                {renderIcon(hasNumber)}
                            </span>
                            Contains at least one number
                        </li>
                        <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                            <span className="transition-all duration-300 text-red-500 scale-90">
                                {renderIcon(hasSpecialChar)}
                            </span>
                            Contains at least one special character (@$!%*?&)
                        </li>
                        <li className="flex items-center gap-2 text-xs font-medium text-gray-800">
                            <span className="transition-all duration-300 text-red-500 scale-90">
                                {renderIcon(hasMinLength)}
                            </span>
                            Minimum of 8 characters
                        </li>
                    </ul>
                </div>
            ) : null}

            <InputBox
                className='SetupPassword__Container__ConfirmPassword'
                type='password'
                label={CONSTANTS.CONFIRM_PASSWORD_LABEL}
                value={formik.values.confirmPassword}
                variant='filled'
                placeholder={CONSTANTS.CONFIRM_PASSWORD_PLACEHOLDER}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='confirmPassword'
                id='confirmPassword'
                formikError={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : ''}
            />
        </div>
    );
};

export default PasswordSetup;
