import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Select, Typography } from 'antd';
import InputBox from '../../../../components/InputBox';
import './AccountDetails.scss'
import CONSTANTS from './constants';
import moment from 'moment';
import dayjs from 'dayjs';
import { getCityApi, getCountryApi } from '../../../../services/locationService';
import { LoaderCircle } from 'lucide-react';

type ValidationState = {
    fullName: boolean
    userName: boolean
    phoneNumber: boolean
    dateOfBirth: boolean
    location: boolean
}

export interface CountryProps {
    countryId: number;
    countryName: string;
}

export interface CityProps {
        cityName: string,
        countryId: number,
        cityId: number
}
interface AccountDetailsProps {
    fullName: string
    userName: string
    phoneNumber: string
    dateOfBirth: string
    about: string
    address: {
        countryId: number | null;
        cityId: number | null;
    };
    validationErrors: ValidationState
    handleOnChange: (key: string, value: any) => void;
}

const { Title, Text } = Typography;
const { TextArea } = Input;

const AccountDetails = ({fullName = '', userName = '', phoneNumber = '', dateOfBirth = '', about = '', address = { countryId: null, cityId: null }, validationErrors, handleOnChange}: AccountDetailsProps) => {
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [cityLoading, setCityLoading]= useState<boolean>(false)
    const [countryLoading, setCountryLoading]= useState<boolean>(false)
    const [selectedCountry, setSelectedCountry] = useState<number | null>(null)
useEffect(() => {
    if (address?.countryId) {
        setSelectedCountry(address.countryId);
    }
}, [address?.countryId]);
    const getCountry = () => {
        setCountryLoading(true)
        getCountryApi().then((res: any) => {
            const countries = res.data.data.map((country: CountryProps) => ({
                value: country.countryId,
                label: country.countryName,
            }));
            setCountryList(countries);
        setCountryLoading(false)
        }).catch((err) => {
        setCountryLoading(false)
        })
    }

    useEffect(() => {
        getCountry()
    }, [])

    const getCity = (countryId: number) => {
        setCityLoading(true)
        getCityApi(countryId).then((res: any) => {
            const cities = res.data.data.map((city: CityProps) => ({
                value: city.cityId,
                label: city.cityName,
            }));
            setCityList(cities);
            setCityLoading(false)
        }).catch((err) => {
            setCityLoading(false)
        })
    }

    useEffect(() => {
        if (selectedCountry) {
            getCity(selectedCountry);
        }
    }, [selectedCountry]);
    const handleOnDobChange = (date: string, dateString: string | string[]) => {
        const selectedDate = Array.isArray(dateString) ? dateString[0] : dateString
        handleOnChange('dateOfBirth', selectedDate);
    }

    return (
        <div className='AccountDetails'>
            <div className='AccountDetails__FirstRow'>
                <InputBox width='235px' className='AccountDetails__FirstRow__FirstName' label={CONSTANTS.FULL_NAME_LABEL} value={fullName} variant='filled' placeholder={CONSTANTS.FULL_NAME_PLACEHOLDER} status={validationErrors?.fullName ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.FULL_NAME} onChange={(event) => handleOnChange('fullName', event?.target?.value)} />
                <InputBox width='235px' className='AccountDetails__FirstRow__UserName' label={CONSTANTS.USERNAME_LABEL} value={userName} variant='filled' placeholder={CONSTANTS.USERNAME_PLACEHOLDER} status={validationErrors?.userName ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.USER_NAME} onChange={(event) => handleOnChange('userName', event?.target?.value)} />
            </div>
            <div className='AccountDetails__SecondRow'>
                <InputBox className='AccountDetails__SecondRow__PhoneNumber' label={CONSTANTS.PHONE_NUMBER_LABEL} value={phoneNumber} variant='filled' placeholder={CONSTANTS.PHONE_NUMBER_PLACEHOLDER} status={validationErrors?.phoneNumber ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.PHONE_NUMBER} onChange={(event) => handleOnChange('phoneNumber', event?.target?.value)} />
                <div className='AccountDetails__SecondRow__DOB'>
                    <Title className='AccountDetails__SecondRow__DOB__Title' level={5}>{CONSTANTS.DOB_LABEL}</Title>
                    <DatePicker maxDate={dayjs()}  className='AccountDetails__SecondRow__DOB__DatePicker' status={validationErrors?.dateOfBirth ? 'error' : ''} onChange={handleOnDobChange} />
                    {validationErrors?.dateOfBirth &&  !dateOfBirth &&  <Text type='danger'>{CONSTANTS.ERROR_MESSAGES.DATE_OF_BIRTH}</Text>}
                </div>
            </div>
            <div className='AccountDetails__ThirdRow'>
                <Title className='AccountDetails__ThirdRow__Title' level={5}>About</Title>
                <TextArea 
                    className='AccountDetails__ThirdRow__TextArea'
                    value={about}
                    rows={3}
                    variant='filled'
                    placeholder='Tell us about yourself'
                    onChange={(event) => handleOnChange('about', event?.target?.value)}
                />
            </div>
            <div className='flex w-full gap-2 items-center'>
                <div className='w-full'>
            <Title className='AccountDetails__ThirdRow__Title' level={5}>Country</Title>
                    <Select
                        className="w-full"
                        variant="filled"
                        placeholder="Select a country"
                        value={countryLoading ? null : selectedCountry || undefined}
                        onChange={(value) => {
                            handleOnChange('address', { countryId: value, cityId: null });
                            setSelectedCountry(value);
                            setCityList([]); // Reset city list
                        }}
                        showSearch
                        filterOption={(input, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={countryList}
                        loading={countryLoading}
                        suffixIcon={countryLoading ? <LoaderCircle size={18} className='text-primary animate-spin'/> : undefined}
                    />
                </div>
                <div className='w-full'>
                    <Title className='AccountDetails__ThirdRow__Title' level={5}>City</Title>
                    <Select
                        loading={cityLoading}
                        className="w-full"
                        variant="filled"
                        placeholder="Select a city"
                        options={cityList}
                        disabled={!selectedCountry}
                        onChange={(value) => {
                            handleOnChange('address', { countryId: selectedCountry, cityId: value });
                        }}
                        showSearch
                        filterOption={(input, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        value={cityLoading ? null : address?.cityId || undefined}
                        suffixIcon={cityLoading ? <LoaderCircle size={18} className='text-primary animate-spin'/> : undefined}
                        
                    />
                </div>
            </div>
            {/*<div className='AccountDetails__FourthRow'>
                <InputBox className='AccountDetails__FourthRow__Location' label={CONSTANTS.LOCATION_LABEL} value={location} variant='filled' placeholder={CONSTANTS.LOCATION_PLACEHOLDER} status={validationErrors?.location ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.LOCATION} onChange={(event) => handleOnChange('location', event?.target?.value)} />
            </div>*/}
        </div>
    );
}

export default AccountDetails;