import React, { useEffect, useState } from 'react';
import { Upload, Typography, message } from 'antd';
import type { GetProp, UploadProps } from 'antd';
import './ProfilePicUploader.scss';
import CONSTANTS from './constants';

const { Title, Text } = Typography;
const { Dragger } = Upload;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface ProfilePicUploaderProps {
    isError: boolean
    setImageUrl: React.Dispatch<React.SetStateAction<string>>
}

const beforeUpload = (file: FileType) => {
  const isSupportedFormat = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
    if (!isSupportedFormat) {
      message.error('You can only upload JPG, JPEG, or PNG files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isSupportedFormat && isLt2M;
};

const ProfilePicUploader = ({isError, setImageUrl}: ProfilePicUploaderProps) => {
    const userInfo = JSON.parse(localStorage.getItem('userDetails') || '{}');

    const handleChange: UploadProps['onChange'] = (info) => {
        if (info?.file?.status === 'uploading') {
          return;
        }
        if (info?.file?.status === 'done' && info?.file?.response?.statusCode === 200) {
          const imageUrl = info?.file?.response?.data?.url;
          setImageUrl(imageUrl);
          message.success('Profile picture uploaded successfully!');
        }
    };

      
    return (
            <div className="UploadContainer">
                <Title className="UploadContainer__Title" level={4}>{CONSTANTS.UPLOADER_LABEL}</Title>
                <div className="UploadContainer__Upload">
                    <div className="UploadContainer__Upload__Label"><Text strong>{CONSTANTS.PROFILE_PICTURE}</Text></div>
                    <Dragger 
                        accept=".jpg,.jpeg,.png"
                        name='file'
                        multiple={false}
                        maxCount={1}
                        action="https://dev-api.mercurygigs.com/common/file/upload"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        headers={{'Authorization': userInfo?.accessToken || null}}
                        data={{type: 'profile'}}
                        showUploadList={{showRemoveIcon: false}}
                        className="UploadContainer__Upload__Input"
                    >
                        {CONSTANTS.DRAGGER_LABEL}
                    </Dragger>
                    {isError && <Text type='danger'>Something went wrong</Text>}
                </div>
            </div>
    );
};

export default ProfilePicUploader;