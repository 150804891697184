import React, { useEffect, useRef, useState } from 'react';
import CONSTANTS from './constants';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import Services from '../Registration/Client/Services';
import './ClientServices.scss';
import { getServices, saveInterestedServices } from '../../services/clientService';
import { Spin } from 'antd';

const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
};

const ClientServices = () => {

    const { TITLES, NEXT, ALREADY_HAVE_ACCOUNT, GOTO_DASHBOARD } = CONSTANTS;

    const navigate = useNavigate();

    const dataFetchedRef = useRef(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);

    const retrieveServices = async() => {
        const response = await getServices('categories');
        if(response?.data?.statusCode === 200) {
            setServices(response?.data?.data);
            setLoading(() => false);
        } else {
            setLoading(() => false);
            setServices(() => []);
        }
    }

    const submitServices = async() => {
        setIsSubmitting(() => true);
        const resp = await saveInterestedServices({servicesInterested: selectedServices});
        if(resp?.data?.statusCode === 201 || resp?.data?.statusCode === 200) {
            setIsSubmitting(() => false);
            navigate('/', {replace: true});
        } else {
            setIsSubmitting(() => false);
        }
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        setLoading(() => true);
        dataFetchedRef.current = true;
        retrieveServices();
    }, []);


    const content = <div style={contentStyle} />;

    if(loading) {
        return (
            <Spin tip="Loading">
                {content}
            </Spin>
        )
    }

    return (
        <>
        <div className='Registration__Card__Body__MainContent'>
            <div className='Registration__Card__Body__MainContent__SubHeader'>
                {TITLES.INTERESTED_SERVICES_TITLE}
            </div>
            <div className='Registration__Card__Body__MainContent__Body'>
                <Services servicesList={services} selectedServices={selectedServices} setSelectedServices={setSelectedServices}/>
            </div>
            <div className='Registration__Card__Body__MainContent__Footer'>
                <CustomButton className='Registration__Card__Body__MainContent__Footer__NextBtn' onClick={(event) => submitServices()} buttonText={NEXT} loading={isSubmitting} />
            </div>
        </div>
        <div className='Registration__Card__Body__Footer'>{ALREADY_HAVE_ACCOUNT} <Link to={'/login'} className='Registration__Card__Body__Footer__GotoDashboard'>{GOTO_DASHBOARD}</Link></div>
        </>
    );
};

export default ClientServices;