import React, { useEffect, useState } from "react"
import InputBox from "../../../../components/InputBox"
import { Input, Radio, Select, Typography } from "antd"
import CustomModal from "../../../../components/CustomModal"
import CustomButton from "../../../../components/CustomButton"
import dollar from "../../../../assets/dollar.svg"
import "./JobPostModalBody.scss"
import { getSkillsList, getToolsList } from "../../../../services/clientService"
import {
    getJobCreationErrorFields,
    tranformDropdownData,
    validateJobCreateDetails
} from "../../../../utils/globalUtils"
import { createJob } from "../../../../services/jobService"
import { useFormik } from "formik"
import * as Yup from "yup"
const { Title, Text } = Typography
const { TextArea } = Input

interface NewJobDetails {
    jobTitle: string
    jobDesc: string
    skills: string[] | []
    tools: string[] | []
    jobType: string
    minBudget: string
    maxBudget: string
    maxHours: string
    paymentRateType: string
    jobDuration: string
    jobDurationType: string
    minHourlyRate: string
    maxHourlyRate: string
    maxHoursPerWeek: string
}

interface IJobPostModalBodyProps {
    openJobPostModal: boolean
    setAddNewJob: React.Dispatch<React.SetStateAction<boolean>>
    onSuccess?: () => void
}

const defaultNewJobDetails = {
    jobTitle: "",
    jobDesc: "",
    skills: [],
    tools: [],
    jobType: "onetime",
    minBudget: "",
    maxBudget: "",
    maxHours: "",
    paymentRateType: "hourly",
    jobDuration: "",
    jobDurationType: "hours",
    minHourlyRate: "",
    maxHourlyRate: "",
    maxHoursPerWeek: ""
}

const defaultNewJobValidationErrror = {
    jobTitle: false,
    jobDesc: false,
    skills: false,
    tools: false,
    jobType: false,
    minBudget: false,
    maxBudget: false,
    maxHours: false,
    paymentRateType: false,
    jobDuration: false,
    jobDurationType: false,
    minHourlyRate: false,
    maxHourlyRate: false,
    maxHoursPerWeek: false
}

const JobPostModalBody = ({
    openJobPostModal,
    setAddNewJob,
    onSuccess
}: IJobPostModalBodyProps) => {
    const [newJobDetails, setNewJobDetails] =
        useState<NewJobDetails>(defaultNewJobDetails)
    const [skillsList, setSkillsList] = useState([])
    const [toolsList, setToolsList] = useState([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [errorFields, setErrorFields] = useState(defaultNewJobValidationErrror)

    const fetchSkillsList = async () => {
        const response = await getSkillsList()
        if (response?.data?.statusCode === 200) {
            const tranformedSkillsList = tranformDropdownData(
                response?.data?.data?.skills
            )
            setSkillsList(() => tranformedSkillsList)
        }
    }

    const fetchToolsList = async () => {
        const response = await getToolsList()
        if (response?.data?.statusCode === 200) {
            const tranformedToolsList = tranformDropdownData(
                response?.data?.data?.tools
            )
            setToolsList(tranformedToolsList)
        }
    }

    useEffect(() => {
        if (openJobPostModal) {
            fetchSkillsList()
            fetchToolsList()
        }
    }, [openJobPostModal])

    //const handleOnJobSave = async () => {
    //    setIsSubmitting(() => true)
    //    if (validateJobCreateDetails(newJobDetails)) {
    //        const response = await createJob({
    //            jobTitle: newJobDetails?.jobTitle,
    //            jobDesc: newJobDetails?.jobDesc,
    //            skills: newJobDetails?.skills,
    //            tools: newJobDetails?.tools,
    //            jobType: newJobDetails?.jobType,
    //            ...(newJobDetails?.jobType === "ongoing"
    //                ? {
    //                    paymentRateType: newJobDetails?.paymentRateType,
    //                    minHourlyRate: newJobDetails?.minHourlyRate,
    //                    maxHourlyRate: newJobDetails?.maxHourlyRate,
    //                    maxHoursPerWeek: newJobDetails?.maxHoursPerWeek
    //                }
    //                : {
    //                    minBudget: newJobDetails?.minBudget,
    //                    maxBudget: newJobDetails?.maxBudget,
    //                    jobDuration: newJobDetails?.jobDuration,
    //                    jobDurationType: newJobDetails?.jobDurationType
    //                })
    //        })
    //        if (
    //            response?.data?.statusCode === 200 ||
    //            response?.data?.statusCode === 201
    //        ) {
    //            setIsSubmitting(() => false)
    //            setNewJobDetails(() => defaultNewJobDetails)
    //            setAddNewJob(false)
    //            onSuccess?.()
    //        } else {
    //            setIsSubmitting(() => false)
    //        }
    //    } else {
    //        const updatedErrorFields = getJobCreationErrorFields(newJobDetails)
    //        setErrorFields({ ...errorFields, ...updatedErrorFields })
    //        setIsSubmitting(() => false)
    //    }
    //}

    const validationSchema = Yup.object({
        jobTitle: Yup.string().required("Please enter job title"),
        jobDesc: Yup.string().required("Please enter job description"),
        skills: Yup.array().min(1, "Please select skills"),
        tools: Yup.array().min(1, "Please select tools"),
        minBudget: Yup.number()
                .positive('Min budget must be a positive number')
                .when('jobType', {
                    is: 'onetime',
                    then: schema => schema.required('Please enter min budget'),
                    otherwise: schema => schema.notRequired()
                }),
            maxBudget: Yup.number()
                .positive('Max budget must be a positive number')
                .moreThan(Yup.ref('minBudget'), 'Max budget should be greater than min budget')
                .when('jobType', {
                    is: 'onetime',
                    then: schema => schema.required('Please enter max budget'),
                    otherwise: schema => schema.notRequired()
                }),
            jobDuration: Yup.number()
                .positive('Job duration must be a positive number')
                .when('jobType', {
                    is: 'onetime',
                    then: schema => schema.required('Please enter job duration'),
                    otherwise: schema => schema.notRequired()
                }),
            minHourlyRate: Yup.number()
                .positive('Min hourly rate must be a positive number')
                .when('jobType', {
                    is: 'ongoing',
                    then: schema => schema.required('Please enter min hourly rate'),
                    otherwise: schema => schema.notRequired()
                }),
            maxHourlyRate: Yup.number()
                .positive('Max hourly rate must be a positive number')
                .moreThan(Yup.ref('minHourlyRate'), 'Max hourly rate must be greater than min hourly rate')
                .when('jobType', {
                    is: 'ongoing',
                    then: schema => schema.required('Required field'),
                    otherwise: schema => schema.notRequired()
                }),
            maxHoursPerWeek: Yup.number()
                .positive('Max hours per week must be a positive number')
                .when('jobType', {
                    is: 'ongoing',
                    then: schema => schema.required('Please enter max hours per week'),
                    otherwise: schema => schema.notRequired()
                }),
            paymentRateType: Yup.string().when('jobType', {
                is: 'onetime',
                then: schema => schema.notRequired(),
                otherwise: schema => schema.required('Please enter payment rate type')
            })
    })

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema,
        initialValues: {
            jobTitle: "",
            jobDesc: "",
            skills: [],
            tools: [],
            jobType: "onetime",
            minBudget: "",
            maxBudget: "",
            maxHours: "",
            paymentRateType: "hourly",
            jobDuration: "",
            jobDurationType: "hours",
            minHourlyRate: "",
            maxHourlyRate: "",
            maxHoursPerWeek: ""
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                setIsSubmitting(true);

                const {
                    paymentRateType,
                    minHourlyRate,
                    maxHourlyRate,
                    maxHoursPerWeek,
                    minBudget,
                    maxBudget,
                    jobDuration,
                    jobDurationType,
                    ...commonValues
                } = values;

                const payload =
                    values.jobType === "onetime"
                        ? {
                            ...commonValues,
                            minBudget,
                            maxBudget,
                            jobDuration,
                            jobDurationType,
                        }
                        : {
                            ...commonValues,
                            paymentRateType,
                            minHourlyRate,
                            maxHourlyRate,
                            maxHoursPerWeek,
                        };

                const response = await createJob(payload);

                if (response?.data?.statusCode === 200 || response?.data?.statusCode === 201) {
                    resetForm();
                    setAddNewJob(false);
                    setIsSubmitting(true);
                    onSuccess?.();
                }
            } catch (error) {
                setIsSubmitting(false);
                console.error("Job creation failed:", error);
            } finally {
                setSubmitting(false);
                setIsSubmitting(false);
            }
        }
    })

    const modalBody = (
        <div className="AddNewJobModalBody">
            <div className="AddNewJobModalBody__JobTitle">
                <InputBox
                    name="jobTitle"
                    label="Job Title"
                    placeholder="Add a Title"
                    value={formik.values.jobTitle}
                    status={formik.touched.jobTitle && formik.errors.jobTitle ? "error" : ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formikError={
                        formik.touched.jobTitle && formik.errors.jobTitle
                            ? formik.errors.jobTitle
                            : ""
                    }
                />
            </div>
            <div className="AddNewJobModalBody__JobDetails">
                <Title className="AddNewJobModalBody__JobDetails__Title" level={5}>
                    Job details
                </Title>
                <TextArea
                    className="AddNewJobModalBody__JobDetails__TextArea"
                    value={formik.values.jobDesc}
                    rows={2}
                    variant="filled"
                    placeholder="A description helps freelances better understand the scope and requirements of your job. This is also a great place to include key deliverables, links or references."
                    onChange={formik.handleChange}
                    status={formik.touched.jobDesc && formik.errors.jobDesc ? "error" : ""}
                    onBlur={formik.handleBlur}
                    name='jobDesc'
                    id='jobDesc'
                />
                {errorFields?.jobDesc && (
                    <Text type="danger">{formik.touched.jobDesc && formik.errors.jobDesc
                        ? formik.errors.jobDesc
                        : ""}
                    </Text>
                )}
            </div>
            <div className="AddNewJobModalBody__Technologies">
                <div className="AddNewJobModalBody__Technologies__Skills">
                    <Title
                        className="AddNewJobModalBody__Technologies__Skills__Title"
                        level={5}
                    >
                        Skills
                    </Title>
                    <Select
                        mode="multiple"
                        placeholder="Please select"
                        value={formik.values.skills}
                        onChange={(value) => formik.setFieldValue('skills', value)}
                        onBlur={() => formik.setFieldTouched('skills', true)}
                        options={skillsList}
                        style={{ width: "100%" }}
                        status={formik.touched.skills && formik.errors.skills ? "error" : ""}
                    />
                    {formik.touched.skills && formik.errors.skills && (
                        <Text type="danger">{formik.errors.skills}</Text>
                    )}
                </div>
                <div className="AddNewJobModalBody__Technologies__Tools">
                    <Title
                        className="AddNewJobModalBody__Technologies__Tools__Title"
                        level={5}
                    >
                        Tools
                    </Title>
                    <Select
                        mode="multiple"
                        placeholder="Please select"
                        value={formik.values.tools}
                        onChange={(value) => formik.setFieldValue('tools', value)}
                        onBlur={() => formik.setFieldTouched('tools', true)}
                        options={toolsList}
                        style={{ width: "100%" }}
                        status={formik.touched.tools && formik.errors.tools ? "error" : ""}
                    />
                    {formik.touched.tools && formik.errors.tools && (
                        <Text type="danger">{formik.errors.tools}</Text>
                    )}
                </div>
            </div>
            <div className="AddNewJobModalBody__AdditionalInfo">
                <div className="AddNewJobModalBody__AdditionalInfo__JobType">
                    <Title level={5}>Type of Job</Title>
                    <Radio.Group
                        value={formik.values.jobType} 
                        className="AddNewJobModalBody__AdditionalInfo__JobType__RadioGroup"
                        onChange={(event) => formik.setFieldValue('jobType', event.target.value)}
                    >
                        <Radio
                            value="onetime"
                            className="AddNewJobModalBody__AdditionalInfo__JobType__RadioGroup__Option"
                        >
                            One Time
                        </Radio>
                        <Radio
                            value="ongoing"
                            className="AddNewJobModalBody__AdditionalInfo__JobType__RadioGroup__Option"
                        >
                            On-going
                        </Radio>
                    </Radio.Group>
                </div>
                {formik.values.jobType === "onetime" ? (
                    <>
                        <div className="AddNewJobModalBody__AdditionalInfo__OneTimeBudget">
                            <Title level={5}>Budget</Title>
                            <div className="AddNewJobModalBody__AdditionalInfo__OneTimeBudget__Inputs">
                                <InputBox
                                    type="number"
                                    prefix={<img src={dollar} alt={"dollar"} />}
                                    status={formik.touched.minBudget && formik.errors.minBudget ? "error" : ""}
                                    placeholder="Min. Budget"
                                    value={formik.values.minBudget}
                                    onBlur={formik.handleBlur}
                                    onNumberInputChange={(value) => {
                                        const minBudget = Number(value);
                                        formik.setFieldValue("minBudget", minBudget || ""); 
                                        formik.setFieldTouched("minBudget", true, false);
                                    }}
                                    formikError={
                                        formik.touched.minBudget && formik.errors.minBudget
                                            ? formik.errors.minBudget
                                            : ""
                                    }
                                    name='minBudget'
                                />
                                <InputBox
                                    type="number"
                                    prefix={<img src={dollar} alt={"dollar"} />}
                                    status={formik.touched.maxBudget && formik.errors.maxBudget ? "error" : ""}
                                    placeholder="Max. Budget"
                                    value={formik.values.maxBudget}
                                    onNumberInputChange={(value) => {
                                        const maxBudget = Number(value);
                                        formik.setFieldValue("maxBudget", maxBudget || ""); 
                                        formik.setFieldTouched("maxBudget", true, false);
                                    }}
                                    onBlur={formik.handleBlur}
                                    formikError={
                                        formik.touched.maxBudget && formik.errors.maxBudget
                                            ? formik.errors.maxBudget
                                            : ""
                                    }
                                    name='maxBudget'
                                />
                                {/*<InputBox type='number' prefix={<img src={dollar} alt={'dollar'}/>} status={errorFields?.minBudget ? 'error' : ''} errorMessage={'Please enter min budget'} placeholder='Min. Budget' value={newJobDetails?.minBudget} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, minBudget: value || ''})} />*/}
                                {/*<InputBox type='number' prefix={<img src={dollar} alt={'dollar'}/>} status={errorFields?.maxBudget ? 'error' : ''} errorMessage={'Please enter max budget'} placeholder='Max. Budget' value={newJobDetails?.maxBudget} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, maxBudget: value || ''})} />*/}
                            </div>
                        </div>
                        <div className="AddNewJobModalBody__AdditionalInfo__OneTimeDuration">
                            <Title level={5}>Job duration</Title>
                            <div className="AddNewJobModalBody__AdditionalInfo__OneTimeDuration__Inputs">
                                <InputBox
                                    type="number"
                                    value={formik.values.jobDuration}
                                    status={formik.touched.jobDuration && formik.errors.jobDuration ? "error" : ""}
                                    onNumberInputChange={(value) => {
                                        const jobDuration = Number(value);
                                        formik.setFieldValue("jobDuration", jobDuration || ""); 
                                        formik.setFieldTouched("jobDuration", true, false);
                                    }}
                                    onBlur={formik.handleBlur}
                                    formikError={
                                        formik.touched.jobDuration && formik.errors.jobDuration
                                            ? formik.errors.jobDuration
                                            : ""
                                    }
                                    name='jobDuration'
                                />
                                <Select
                                    placeholder="Duration type"
                                    value={formik.values.jobDurationType}
                                    onChange={(value) => formik.setFieldValue('jobDurationType', value)}
                                    onBlur={() => formik.setFieldTouched('jobDurationType', true)}
                                    options={[
                                        { value: "hours", label: "Hours" },
                                        { value: "days", label: "Days" },
                                        { value: "weeks", label: "Weeks" },
                                        { value: "months", label: "Months" }
                                    ]}
                                    style={{ width: "30%" }}
                                    status={formik.touched.jobDurationType && formik.errors.jobDurationType ? "error" : ""}
                                />
                                {formik.touched.jobDurationType && formik.errors.jobDurationType && (
                                    <Text type="danger">{formik.errors.jobDurationType}</Text>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="AddNewJobModalBody__AdditionalInfo__OnGoingPayout">
                            <Title level={5}>How do you want to pay for this job?</Title>
                            <Radio.Group
                                value={formik.values.paymentRateType} 
                                className="AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup"
                                onChange={(event) => formik.setFieldValue('paymentRateType', event.target.value)}
                            >
                                <Radio
                                    value="hourly"
                                    className="AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup__Option"
                                >
                                    Hourly
                                </Radio>
                                <Radio
                                    value="weekly"
                                    className="AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup__Option"
                                >
                                    Weekly
                                </Radio>
                                <Radio
                                    value="monthly"
                                    className="AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup__Option"
                                >
                                    Monthly
                                </Radio>
                            </Radio.Group>
                        </div>
                        <div className="AddNewJobModalBody__AdditionalInfo__OnGoingBudget">
                            <InputBox
                                className="AddNewJobModalBody__AdditionalInfo__OnGoingBudget__MinRate"
                                status={formik.touched.minHourlyRate && formik.errors.minHourlyRate ? "error" : ""}
                                label="Min. hourly rate"
                                type="number"
                                prefix={<img src={dollar} alt={"dollar"} />}
                                placeholder="Min. hourly rate"
                                value={formik.values.minHourlyRate}
                                onNumberInputChange={(value) => {
                                    const minHourlyRate = Number(value);
                                    formik.setFieldValue("minHourlyRate", minHourlyRate || ""); 
                                    formik.setFieldTouched("minHourlyRate", true, false);
                                }}
                                onBlur={formik.handleBlur}
                                formikError={
                                    formik.touched.minHourlyRate && formik.errors.minHourlyRate
                                        ? formik.errors.minHourlyRate
                                        : ""
                                }
                                name='minHourlyRate'
                            />
                            <InputBox
                                className="AddNewJobModalBody__AdditionalInfo__OnGoingBudget__MaxRate"
                                status={formik.touched.maxHourlyRate && formik.errors.maxHourlyRate ? "error" : ""}
                                label="Max. hourly rate"
                                type="number"
                                prefix={<img src={dollar} alt={"dollar"} />}
                                placeholder="Max. hourly rate"
                                value={formik.values.maxHourlyRate}
                                onNumberInputChange={(value) => {
                                    const maxHourlyRate = Number(value);
                                    formik.setFieldValue("maxHourlyRate", maxHourlyRate || ""); 
                                    formik.setFieldTouched("maxHourlyRate", true, false);
                                }}
                                onBlur={formik.handleBlur}
                                formikError={
                                    formik.touched.maxHourlyRate && formik.errors.maxHourlyRate
                                        ? formik.errors.maxHourlyRate
                                        : ""
                                }
                                name='maxHourlyRate'
                            />
                            {/*<InputBox className='AddNewJobModalBody__AdditionalInfo__OnGoingBudget__MaxRate' status={errorFields?.maxHourlyRate ? 'error' : ''} errorMessage={'Please enter max hourly rate'} label='Max. hourly rate' type='number' prefix={<img src={dollar} alt={'dollar'}/>} placeholder='Max. hourly rate' value={newJobDetails?.maxHourlyRate} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, maxHourlyRate: value || ''})} />*/}
                            <div className="AddNewJobModalBody__AdditionalInfo__OnGoingBudget__Hours">
                                <InputBox
                                    type="number"
                                    label={"Max. hours per week"}
                                    status={formik.touched.maxHoursPerWeek && formik.errors.maxHoursPerWeek ? "error" : ""}
                                    value={formik.values.maxHoursPerWeek}
                                    onNumberInputChange={(value) => {
                                        const maxHoursPerWeek = Number(value);
                                        formik.setFieldValue("maxHoursPerWeek", maxHoursPerWeek || ""); 
                                        formik.setFieldTouched("maxHoursPerWeek", true, false);
                                    }}
                                    onBlur={formik.handleBlur}
                                    formikError={
                                        formik.touched.maxHoursPerWeek && formik.errors.maxHoursPerWeek
                                            ? formik.errors.maxHoursPerWeek
                                            : ""
                                    }
                                    name='maxHoursPerWeek'
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )

    return (
        <CustomModal
            isOpen={openJobPostModal}
            onCancel={() => {setAddNewJob(false); formik.resetForm()}}
            closable={false}
            width={"900px"}
            footer={[
                <CustomButton
                    key="cancel"
                    onClick={() => {
                        setAddNewJob(false)
                        formik.resetForm()
                        setNewJobDetails(defaultNewJobDetails)
                    }}
                    buttonText="Cancel"
                />,
                <CustomButton
                    key="save"
                    onClick={() => formik.handleSubmit()}
                    buttonText="Save"
                    loading={isSubmitting}
                />
            ]}
        >
            {modalBody}
        </CustomModal>
    )
}

export default JobPostModalBody
