
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import freelancerSlice from "./slice/freelancerSlice";
import clientSlice from "./slice/clientSlice";
import agoraSlice from "./slice/agoraSlice"; // Import agora slice
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth", "agora"], // Only persist auth & agora slices
};

const rootReducer = combineReducers({
    auth: authSlice,
    freelancer: freelancerSlice,
    client: clientSlice,
    agora: agoraSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

// Infer types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
