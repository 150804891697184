const generatePortfolioArray = (url1: string, url2: string) => {
    const result = []
    if(url1.trim()) {
        result.push(url1);
    }

    if(url2.trim()) {
        result.push(url2);
    }

    return result;
}

const convertTo24HourFormat = (timeString: string) => {
    const [time, period] = timeString.split(' ');
    const [hour, minute] = time.split(':');
    let formattedHour = parseInt(hour);

    if (period === 'PM') {
        formattedHour += 12;
    }

    return `${formattedHour}:${minute}`;

}

export const getfreelancerDataPacket = (requestData: {
    fullName: string
    userName: string;
    phoneNumber: string
    dateOfBirth: string
    about: string
    address: {
        countryId: number | null;
        cityId: number | null;
    };
    //location: string
    professionalTitle: string
    serviceId: string
    openingHrs: string
    closingHrs: string
    portfolioUrl1: string
    portfolioUrl2: string
}) => {
    return {
        fullName: requestData?.fullName,
        userName: requestData?.userName,
        phone: requestData?.phoneNumber,
        dateOfBirth: requestData?.dateOfBirth,
        about: requestData?.about,
        address: {
            ...(requestData?.address?.countryId !== null && requestData?.address?.countryId !== undefined
                ? { countryId: requestData.address.countryId }
                : {}),
            ...(requestData?.address?.cityId !== null && requestData?.address?.cityId !== undefined
                ? { cityId: requestData.address.cityId }
                : {}),
        },
        professionalTitle: requestData?.professionalTitle,
        serviceId: requestData?.serviceId,
        workingHours: {
            openHour: convertTo24HourFormat(requestData?.openingHrs),
            closeHour: convertTo24HourFormat(requestData?.closingHrs)
        },
        portfolioUrl: generatePortfolioArray(requestData.portfolioUrl1, requestData.portfolioUrl2 ?? '')
    }
}

export const getClientDataPacket = (requestData: {
    fullName: string
    userName: string;
    phoneNumber: string
    dateOfBirth: string
    about: string
    address: {
        countryId: number | null;
        cityId: number | null;
    };
}) => {
    return {
        fullName: requestData?.fullName,
        userName: requestData?.userName,
        phone: requestData?.phoneNumber,
        dateOfBirth: requestData?.dateOfBirth,
        about: requestData?.about,
        address: {
            ...(requestData?.address?.countryId !== null && requestData?.address?.countryId !== undefined
                ? { countryId: requestData.address.countryId }
                : {}),
            ...(requestData?.address?.cityId !== null && requestData?.address?.cityId !== undefined
                ? { cityId: requestData.address.cityId }
                : {}),
        },
    }
}

export const tranformServicesData = (data: any) => {
    return data?.reduce((accumulator: any, item: any) => {
        item['value'] = item?.serviceId;
        if(item?.services?.length) {
            item.services = tranformServicesData(item?.services);
        }
        accumulator.push(item);
        return accumulator;
    }, [])
}

export const getOnboradingErrorFields = (data: {
    fullName: string,
    userName: string,
    phoneNumber: string,
    dateOfBirth: string,
}) => {
    const errorObject = {
        fullName: false,
        userName: false,
        phoneNumber: false,
        dateOfBirth: false,
    };
    if(!data?.fullName) {
        errorObject.fullName = true;
    }
    if(!data?.userName) {
        errorObject.userName = true;
    }
    if(!data?.phoneNumber) {
        errorObject.phoneNumber = true;
    }
    if(!data?.dateOfBirth) {
        errorObject.dateOfBirth = true;
    }
    return errorObject;
}

export const getAdditionalFreelancerErrorFields = (data: {
    professionalTitle: string,
    serviceId: string,
    openingHrs: string,
    closingHrs: string,
    portfolioUrl1?: string,
    portfolioUrl2?: string
}) => {
    const errorObject = {
        professionalTitle: false,
        serviceId: false,
        openingHrs: false,
        closingHrs: false,
        portfolioUrl1: false,
        portfolioUrl2: false,
    };
    if(!data?.professionalTitle) {
        errorObject.professionalTitle = true;
    }
    if(!data?.serviceId) {
        errorObject.serviceId = true;
    }
    if(!data?.openingHrs) {
        errorObject.openingHrs = true;
    }
    if(!data?.closingHrs) {
        errorObject.closingHrs = true;
    }
    //if(!data?.portfolioUrl1) {
    //    errorObject.portfolioUrl1 = true;
    //}
    //if(!data?.portfolioUrl2) {
    //    errorObject.portfolioUrl2 = false;
    //}
    return errorObject;
}

export const validOnboardingDetails = (data: {
    fullName: string,
    userName: string,
    phoneNumber: string,
    dateOfBirth: string,
}) => {
    return !!(data?.fullName && data?.userName && data?.phoneNumber && data?.dateOfBirth );
}

export const validFreelancerDetails = (data: {
    professionalTitle: string,
    serviceId: string,
    openingHrs: string,
    closingHrs: string,
    //portfolioUrl1: string,
    //portfolioUrl2: string,
}) => {
    return !!(data?.professionalTitle && data?.serviceId && data?.openingHrs && data?.closingHrs);
}

export const searchByKey = (keyName: string, value: string, list: any) => {
    const matchedResult =  list?.find((item: any) => item[keyName] === value);
    return matchedResult?.services;
}

export const tranformQuestionsData = (data: any, keyName: string) => {
    return data?.reduce((accumulator: any, item: any) => {
        item['value'] = item[keyName];
        if(item?.options?.length) {
            item.options = tranformQuestionsData(item?.options, 'optionId');
        }
        accumulator.push(item);
        return accumulator;
    }, [])
}

type AnswerType = {
    [key: string]: any
}

export const tranformAnswersPayload = (data: AnswerType) => {
    let result: AnswerType = {};
    const pairs = Object.entries(data);
    for(const [key, value] of pairs){
        result[key] = {
            answer: value
        }
    }
    return result;
}

type dataObject = {
    name: string
}

export const tranformDropdownData = (data: dataObject[]) => {
    return data?.reduce((accumulator: any, item: any) => {
        item['value'] = item?.name;
        item['label'] = item?.name
        accumulator.push(item);
        return accumulator;
    }, [])
}

export const validateJobCreateDetails = (data: any) => {
    const commonFields = data?.jobTitle && data?.jobDesc && data?.skills?.length && data?.tools?.length;
    if(data?.jobType === 'onetime') {
        return (commonFields && data?.minBudget && data?.maxBudget && data?.jobDuration && data?.jobDurationType )
    } else {
        return (commonFields && data?.minHourlyRate && data?.maxHourlyRate && data?.paymentRateType && data?.maxHoursPerWeek )
    }
}

export const getJobCreationErrorFields = (data: any) => {
    const errorObject = {
        jobTitle: false,
        jobDesc: false,
        skills: false,
        tools: false,
        jobType: false,
        minBudget: false,
        maxBudget: false,
        maxHours: false,
        paymentRateType: false,
        jobDuration: false,
        jobDurationType: false,
        minHourlyRate: false,
        maxHourlyRate: false,
        maxHoursPerWeek: false
    };
    if(!data?.jobTitle) {
        errorObject.jobTitle = true;
    }
    if(!data?.jobDesc) {
        errorObject.jobDesc = true;
    }
    if(!data?.skills?.length) {
        errorObject.skills = true;
    }
    if(!data?.tools?.length) {
        errorObject.tools = true;
    }
    if(data?.jobType === 'onetime' && !data?.minBudget) {
        errorObject.minBudget = true;
    }
    if(data?.jobType === 'onetime' && !data?.maxBudget) {
        errorObject.maxBudget = true;
    }
    if(data?.jobType === 'onetime' && !data?.jobDuration) {
        errorObject.jobDuration = true;
    }
    if(data?.jobType === 'ongoing' && !data?.minHourlyRate) {
        errorObject.minHourlyRate = true;
    }
    if(data?.jobType === 'ongoing' && !data?.maxHourlyRate) {
        errorObject.maxHourlyRate = true;
    }
    if(data?.jobType === 'ongoing' && !data?.maxHoursPerWeek) {
        errorObject.maxHoursPerWeek = true;
    }
    return errorObject;
}