import React, { useLayoutEffect, useRef, useState } from 'react';
import ProfileCard from '../../../components/ProfileCard';
import CustomButton from '../../../components/CustomButton';
import user from '../../../assets/user.png';
import chat from '../../../assets/chat.svg';
import notifications from '../../../assets/notifications.svg';
import './Profile.scss'
import EditProfile from './EditProfile';
import Jobs from './Jobs';
import JobPostModalBody from './JobPostModalBody';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getClientPostedJobs } from '../../../services/jobService';
import InfiniteScroll from 'react-infinite-scroller';
import { Drawer, Spin } from 'antd';
import JobDetails from '../../../components/JobDetails';
import { useNavigate } from 'react-router-dom';
import { setSelectedJob } from '../../../slice/freelancerSlice';
import JobOverview from '../../JobOverview';

// const pastJobs = [
//     {
//         jobId: '1',
//         clientName: 'John Smith',
//         posterImageUrl: user,
//         hiringType: 'individual',
//         jobTitle: 'Telemarketing Agent',
//         minBudget: '500',
//         maxBudget: '1000',
//         jobType: 'oneTime',
//         duration: '1 week',
//         postedDate: '10d ago',
//         skills: ['Web Designer', 'Web Developer', 'Webflow'],
//         jobDurationLabel: 'Sbc'
//     },
//     {
//         jobId: '2',
//         clientName: 'John Smith',
//         posterImageUrl: user,
//         hiringType: 'individual',
//         jobTitle: 'Telemarketing Agent',
//         minBudget: '500',
//         maxBudget: '1000',
//         jobType: 'oneTime',
//         duration: '1 week',
//         postedDate: '10d ago',
//         skills: ['Web Designer', 'Web Developer', 'Webflow'],
//         jobDurationLabel: 'Sbc'
//     }
// ]


const Profile = () => {
    const dataFetchedRef = useRef(false);
    const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
    const [addNewJob, setAddNewJob] = useState<boolean>(false);
    const [postedJobs, setPostedJobs] = useState<any[]>([]);
    const [lastJobKey, setLastJobKey] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const userInfo = useAppSelector(state => state?.auth?.loggedInUser);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
   
    const fetchClientJobs = async (lastJobId: string) => {
        try {
            const response = await getClientPostedJobs({ limit: 10, lastKey: lastJobId ?? '' });
            if (response?.data?.statusCode === 200) {
                const newJobs = response?.data?.data?.jobs;
                setPostedJobs(prevJobs => [...prevJobs, ...newJobs]);
                setLastJobKey(response?.data?.data?.lastKey || null);
                setHasMore(!!response?.data?.data?.lastKey);
            } else {
                setPostedJobs([]);
                setHasMore(false);
            }
        } catch {
            setHasMore(false);
        }
    }




    useLayoutEffect(() => {
        //if (dataFetchedRef.current) return;
        //dataFetchedRef.current = true;
        fetchClientJobs('');
    }, []);

    const handleOnJobClick = (job: any) => {
        dispatch(setSelectedJob({ job }));
        //   setShowOverviewScreen(() => true);
        setOpenDrawer(() => true);
    }

    const onDrawerClose = () => {
        setOpenDrawer(false);
    };

    return (
        <>
            <div className='Profile'>
                <div className='Profile__Header'>
                    <div className='Profile__Header__Title'>Profile</div>
                    <div className='Profile__Header__Actions'>
                        <CustomButton buttonText='Upgrade' onClick={() => {}} />
                        <img style={{ cursor: 'pointer' }} src={chat} alt='chat' onClick={() => {navigate('/chat')}} />
                        <img src={notifications} alt='notifications' />
                    </div>
                </div>
                <div className='Profile__Body'>
                    {isEditProfile ?
                        <div className='Profile__Body__EditScreen'><EditProfile imageUrl={userInfo?.profileImageUrl} firstName={userInfo?.firstName} lastName={userInfo?.lastName} setIsEditProfile={setIsEditProfile} /></div>
                        : (
                            <>
                                <div className='Profile__Body__ProfileCard'><ProfileCard role={userInfo?.role} userName={`${userInfo?.firstName} ${userInfo?.lastName}`} imageUrl={userInfo?.profileImageUrl} phoneNumber={userInfo?.phone} dob={userInfo?.dateOfBirth} address={userInfo?.address} about={userInfo?.about} setIsEditProfile={setIsEditProfile} /></div>
                                {userInfo?.role === 'client' && (
                                    <div className='Profile__Body__JobSection'>
                                        <Jobs pastJobs={postedJobs} setAddNewJob={setAddNewJob} />
                                        <div className='JobsCard__PastJobs max-h-[calc(100dvh-423px)] overflow-auto border-t-0 rounded-t-none py-2'>
                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={() => { if (lastJobKey) { fetchClientJobs(lastJobKey ?? '') } }}
                                                hasMore={hasMore}
                                                loader={<div className='w-full flex justify-center my-2'><Spin /></div>}
                                                useWindow={false}
                                            >
                                                {postedJobs?.map((job) => (
                                                    <JobDetails
                                                        key={job?.jobId}
                                                        jobId={job?.jobId}
                                                        userRole={userInfo?.role}
                                                        clientName={`${job?.client?.firstName} ${job?.client?.lastName}`}
                                                        posterImageUrl={job?.client?.profileImageUrl}
                                                        jobTitle={job?.jobTitle}
                                                        minBudget={job?.jobType === 'onetime' ? job?.minBudget : job?.minHourlyRate}
                                                        maxBudget={job?.jobType === 'onetime' ? job?.maxBudget : job?.maxHourlyRate}
                                                        jobType={job?.jobType}
                                                        postedDate={job?.createdAt}
                                                        jobDurationLabel={job?.jobDurationLabel}
                                                        skills={job?.skills}
                                                        onClick={() => handleOnJobClick(job)}
                                                    />
                                                ))}
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                </div>
            </div>
            <JobPostModalBody openJobPostModal={addNewJob} setAddNewJob={setAddNewJob} onSuccess={() => {setPostedJobs([]); fetchClientJobs('')}}/>
            <Drawer
                closable={false}
                onClose={onDrawerClose}
                open={openDrawer}
                size={'large'}
            >
                 <JobOverview onApply={() => {}} />
            </Drawer>
        </>
    );
}

export default Profile;